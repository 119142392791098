export const GLRSC1 = () => {
  return {
    url: '/product/glory-scrub',
    title: 'กลอรี่สครับ',
    brand: 'GLORY',
    sku: 'GLRSC1',
    totalSale: 20221,
    price: 290,
    offerPrice: 390,
    images: ['/product/GLRSC1.jpg'],
    product: 'กลอรี่สครับ 1 หลอด',
    shortDescription: 'สครับขจัดเซลล์ผิว ช่วยให้ผิวขาวกระจ่างใส',
    description: (
      <div class="mb-2 text-baseColor">
        <p>
          Glory Growy Scrub ผสมผสาน 5 สารสกัดที่ช่วยบำรุงผิวอย่าง คอลลาเจน,
          มะเขือเทศสด, สวีทอัลมอนด์ออย,นมสด, เกลือธรรมชาติเม็ดละเอียดที่สุด เปิด
          ผลัด เติม เพิ่มออร่าให้กับผิว พร้อมด้วยกลิ่นน้ำหอม Hi-End
          ที่ไม่ก่อให้เกิดการแพ้ ช่วยเพิ่มกลิ่นหอมน่าหลงใหล
          ให้ติดผิวยาวนานตลอดทั้งวัน
        </p>
        <ul class="list-disc mt-4 ml-10 text-baseColor">
          <li>ผิวขาวใส มีออร่าใน 10 นาที</li>
          <li>เติมความฟู มีน้ำมีนวลเปล่งปลั่งของผิว</li>
          <li>หลังใช้รู้สึกผิวเด้ง นุ่มลื่น ชุ่่มชื้นโดยไม่ต้องทาโลชั่น</li>
        </ul>
      </div>
    ),
    options: null,
    guarantee: {
      title: 'คุณภาพจากกลอรี่',
      description: (
        <div>
          เพราะความสวยสร้างได้
          กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
          จากภายในสู่ภายนอก
        </div>
      ),
    },
    ingredients: [
      {
        image: '/ingredient/salt.png',
        title: 'Gental Salt',
        description: (
          <div>
            เม็ดเกลือที่ได้ละเอียด เป็นเกลืออณูเล็ก เนื้อกลมมน
            ซึ่งเวลาขัดจะไม่บาดผิวหรือระคายเคืองเหมือนเกลือชนิดอื่นหรือวัตถุประเภทเม็ดบีทส์
          </div>
        ),
      },
      {
        image: '/ingredient/hc.png',
        title: 'Hydrolyzed Collagen',
        description: (
          <div>
            คอลลาเจนเสริม ที่ช่วยเสริมความแข็งแรงให้กับคอลลาเจนในชั้นผิว
            ช่วยให้เซลล์ผิวมีความยืดหยุ่น อุ้มน้ำ และผิวดูเต่งตึงขึ้น
          </div>
        ),
      },
      {
        image: '/ingredient/almon.png',
        title: 'Prunus Amygdalus Dulcis Oil (น้ำมันอัลมอนต์)',
        description: (
          <div>
            เป็นน้ำมันที่อุดมไปด้วยวิตามินและแร่ธาตุมากมายหลากหลายชนิด เช่น
            Folic Alpha tocopherol (Vitamin E) Zinc
            ช่วยในเรื่องรักษาอาการผิดปกติของผิวหนัง มี Vitamin A B1 B2 B6 D และ
            Glycoside ที่ช่วยฟื้นฟูบำรุงผิวหนัง และยังมีกรดไขมันจำเป็นอย่าง
            Linoleic (Omega-6) ถึง 30% ซึ่งช่วยลดการสูญเสียน้ำภายใต้ผิวหนัง
          </div>
        ),
      },
      {
        image: '/ingredient/milk.png',
        title: 'Hydrolyzed Milk Protien (โปรตีนน้ำนม)',
        description: (
          <div>
            ที่อุดมไปด้วยกรดอะมิโนจำเป็นและโอลิโกเป็ปไทด์เคซีน
            ช่วยกระตุ้นการสร้างสารประกอบโปรตีนที่สำคัญเติมความชุ่มชื้นผิว
            เสริมความยืดหยุ่นผิวและเพิ่มความนุ่มนวลให้ผิวผิว
          </div>
        ),
      },
      {
        image: '/ingredient/tomato.png',
        title: 'Solanum Lycopersicum Fruit Extract (สารสกัดมะเขือเทศ)',
        description: (
          <div>
            สาร Lycopene ที่พบในสารสกัดมะเขือเทศ มีสรรพคุณบำรุงผิว
            อุดมด้วยต้านอนุมูลอิสระ ช่วยปรับสภาพผิวให้ชุ่มชื่น เก็บกักน้ำ
            เรียบเนียน ลดเลือนริ้วรอย ( Anti-Oxidant )
          </div>
        ),
      },
      {
        image: '/ingredient/vc-3.png',
        title: 'Ascorbic Acid Power (Vitamin C)',
        description: (
          <div>
            ทำหน้าที่ช่วยกระตุ้นการผลิตคอลลาเจนที่จะช่วยต่อต้านอนุมูลอิสระและคืนความเฟิร์มกระชับให้ผิว
          </div>
        ),
      },
    ],
    ingredientSummary: (
      <div>
        Sodium Chloride, Water, Sodium Laureth Sulfate, Cocamide DEA,
        Cocamidopropyl Betaine, Glycerin, Fragrance, Niacinamide, Prunus
        Amygdalus Dulcis oil, Allantoin, CI15985, Hydrolyzed Collagen,
        Hydrolyzed Milk Protein, Propylene Glycol, CI 19140, Zea Myas (Corn)
        Starch, Hydrolyzed Corn Starch, Hydrolyzed Corn Starch Octenylsuccinate,
        Solanum Lycopersicum Fruit Extract, Solanum Hydrated Silica,
        Lycopersicum Fruit Extract, CI 14720, Bulyrospermum Parkii (Shea Butter)
        Extract, Cocamidopropyl Dimethylamine, CI 16255, Phenylethyl Resorcinol,
        Lactic Acid, Denzyl Alcohol, Palmitoyl Tripeptide-5, Hydrated Silica,
        Dehydroacetic Acid, Sorbic Acid, Benzaic Acid
      </div>
    ),
    fdaId: '10-1-6300032598',
    weight: '180 กรัม',
    faqs: [
      {
        question: 'วิธีสครับผิวให้เห็นผล',
        answer: (
          <div>
            สครับผิวบริเวณแขน ขา ก่อนอาบน้ำเป็นประจำ
            นวดวนบนผิวที่เปียกจนทั่วแล้วล้างออก สามารถใช้ได้บ่อยโดยไม่ระคายเคือง
            แนะนำสครับผิววันเว้นวันเพื่อผิวนุ่ม ฟู กระจ่างใส
          </div>
        ),
      },
      {
        question: 'สครับช่วยให้ผิวใสได้อย่างไร',
        answer: (
          <div>
            กลอรี่สครับช่วยขจัดเซลล์ผิวที่ตายแล้วให้หลุดออก
            และกระตุ้นการสร้างเซลล์ผิวใหม่
            เมื่อเซลล์ผิวเก่าหลุดออกจะช่วยให้ผิวกระจ่างใสขึ้นภายใน 10 นาที
          </div>
        ),
      },
      {
        question: 'กลอรี่ปลอดภัยไหม มีอย.หรือไม่',
        answer: (
          <div>
            <div>
              สินค้าทุกชิ้นของกลอรี่ ผลิตโดยโรงงานมาตรฐาน ปลอดภัย และมีอย.
            </div>
            <div class="mt-2">
              เลขที่อย.: 10-1-20960-5-0093 (คอลลาเจน)
              <br />
              เลขที่อย.: 10-1-20960-5-0112 (โทเมโท่วิตซี)
              <br />
              เลขที่อย.: 10-1-63000-3-2598 (สครับ)
              <br />
              เลขที่อย.: 11-1-64000-0-2539 (บอดี้เซรั่ม)
              <br />
            </div>
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'รีวิวกลอรี่สครับ',
      subtitle:
        'สครับเม็ดกลมไม่บาดผิว มีส่วนผสมของ AHA ช่วยกระตุ้นการผลัดเซลล์ผิว',
      url: 'https://www.youtube.com/watch?v=j-64rpvzR9M',
    },
    disclaimer: (
      <div>
        1. ใช้สำหรับภายนอกเท่านั้น
        <br />
        2. หากเกิดการระคายเคือง ควรหยุดใช้และปรึกษาแพทย์หรือเภสัชกร
      </div>
    ),
    reviews: null,
    productsIncluded: null,
    relateProducts: [
      'RTCHY1',
      'RTCVC1',
      'RTCSB1',
      'RTCWF1',
      'PRECL1',
      'GLRBS1',
    ],
    upsellProducts: ['RTCHY1', 'RTCVC1', 'RTCSB1'],
  };
};
