export const RTCSET3 = () => {
  return {
    url: '/product/set-3',
    title: 'ชุดเคลียร์สิว หน้ามัน',
    brand: 'RATCHA',
    sku: 'RTCSET3',
    totalSale: 130976,
    price: 600,
    offerPrice: 850,
    images: ['/product/ratcha-set-c-1.jpg'],
    product: 'ชุดเคลียร์สิว หน้ามัน: เซรั่มวิตซี 1 + วิตซีวิปโฟม 1',
    shortDescription: null,
    description: (
      <div class="mb-2 text-baseColor">
        <p>ชุดเคลียร์สิว หน้ามัน ประกอบไปด้วย</p>
        <ul class="list-disc mt-4 ml-10 text-baseColor">
          <li>เซรั่มวิตซี 1 ขวด</li>
          <li>วิตซีวิปโฟม 1 หลอด</li>
        </ul>
      </div>
    ),
    options: null,
    guarantee: {
      title: 'คุณภาพจากรัชชา',
      description: (
        <div>
          ผลิตภัณฑ์คุณภาพจากรัชชา ได้มาตรฐานอย. พร้อมผ่านการรับรองคุณภาพ GMP
          ผลิตโดยโรงงานมาตรฐาน
        </div>
      ),
    },
    ingredients: null,
    ingredientSummary: null,
    fdaId: null,
    weight: null,
    faqs: [
      {
        question: 'วิธีใช้ให้เห็นผลลัพธ์',
        answer: (
          <div class="grid md:grid-cols-2">
            <div class="mb-4">
              <span class="font-bold">ตอนเช้า</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>ล้างหน้าให้สะอาดด้วยวิตซีวิปโฟม</li>
                <li>ใช้ถุงตีวิป เพื่อช่วยผลัดเซลล์ผิว</li>
                <li>หยดเซรั่มวิตซี 2-3 หยด แล้วทาให้ทั่วใบหน้า</li>
                <li>รอ 1-2 นาที เพื่อรอให้เนื้อเซรั่มซึมลงผิว</li>
              </ul>
            </div>
            <div>
              <span class="font-bold">ตอนกลางคืน</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>ล้างหน้าให้สะอาดด้วยวิตซีวิปโฟม</li>
                <li>ใช้ถุงตีวิป เพื่อช่วยผลัดเซลล์ผิว</li>
                <li>หยดเซรั่มวิตซี 2-3 หยด แล้วทาให้ทั่วใบหน้า</li>
                <li>รอ 1-2 นาที เพื่อรอให้เนื้อเซรั่มซึมลงผิว</li>
              </ul>
            </div>
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'ผิวนุ่ม ชุ่ม ฟู เด้ง',
      subtitle:
        'ด้วยอนุภาค Oilgo Hyaluronic Acid ซึมลึกถึงชั้นใต้ผิว คงความชุ่มชื้นได้ยาวนาน',
      url: 'https://www.youtube.com/watch?v=JWxO2nzSiZM',
    },
    disclaimer: null,
    reviews: null,
    productsIncluded: ['RTCVC1', 'RTCWF1'],
    relateProducts: [
      'RTCSET1',
      'RTCSET12',
      'RTCSET13',
      'RTCSET14',
      'RTCSET2',
      'RTCSET3',
      'RTCSET4',
      'RTCSET5',
      'RTCSET6',
      'RTCSET7',
      'RTCSET8',
      'RTCSET9',
      'RTCSET10',
      'RTCSET11',
    ],
    upsellProducts: ['RTCHY1', 'RTCSB1', 'GLRBS1'],
  };
};
