export const RTCSET8 = () => ({
  url: '/product/set-8',
  title: 'ชุดผิวสวย กระจ่างใส',
  brand: 'RATCHA',
  sku: 'RTCSET8',
  totalSale: 42190,
  price: 2850,
  offerPrice: 3910,
  images: ['/product/rtcset8-1.jpg'],
  product:
    'ชุดผิวสวย กระจ่างใส: เซรั่มไฮยา 1 + เซรั่่มวิตซี 1 + วิตซี ยูวี โพรเทคชั่น 1 + วิตซีวิปโฟม 1 + กลอรี่คอลลาเจน 1 + กลอรี่โทเมโท่วิตซี 1 + กลอรี่สครับ 1 + กลอรี่บอดี้เซรั่ม 1',
  shortDescription: null,
  description: (
    <div class="mb-2 text-baseColor">
      <p>ชุดผิวสวย กระจ่างใส ประกอบไปด้วย</p>
      <ul class="list-disc mt-4 ml-10 text-baseColor">
        <li>เซรั่มไฮยารัชชา 1 ขวด</li>
        <li>เซรั่มวิตซี 1 ขวด</li>
        <li>วิตซี ยูวี โพรเทคชั่น 1 หลอด</li>
        <li>วิตซีวิปโฟม 1 หลอด</li>
        <li>กลอรี่คอลลาเจน 1 ซอง</li>
        <li>กลอรี่โทเมโท่วิตซี 1 ซอง</li>
        <li>กลอรี่สครับ 1 หลอด</li>
        <li>กลอรี่บอดี้เซรั่ม 1 หลอด</li>
      </ul>
    </div>
  ),
  options: null,
  guarantee: {
    title: 'คุณภาพจากรัชชา',
    description: (
      <div>
        ผลิตภัณฑ์คุณภาพจากรัชชา ได้มาตรฐานอย. พร้อมผ่านการรับรองคุณภาพ GMP
        ผลิตโดยโรงงานมาตรฐาน
      </div>
    ),
  },
  ingredients: null,
  ingredientSummary: null,
  fdaId: null,
  weight: null,
  faqs: [
    {
      question: 'วิธีใช้ให้เห็นผลลัพธ์',
      answer: (
        <div class="grid md:grid-cols-2">
          <div class="mb-4">
            <span class="font-bold">ตอนเช้า</span>
            <ul class="list-decimal mt-2 ml-6">
              <li>ทานกลอรี่คอลลาเจนและโทเมโท่วิตซีอย่างละ 2 แคปซูล</li>
              <li>ล้างหน้าให้สะอาดด้วยวิตซีวิปโฟม</li>
              <li>ใช้ถุงตีวิป เพื่อช่วยผลัดเซลล์ผิว</li>
              <li>หยดเซรั่มไฮยา 2-3 หยด แล้วทาให้ทั่วใบหน้า</li>
              <li>รอ 1-2 นาที เพื่อรอให้เนื้อเซรั่มซึมลงผิว</li>
              <li>จากนั้นจึงทาเซรั่มวิตซี</li>
              <li>ทาวิตซี ยูวี โพรเทคชั่น เพื่อปกป้องผิวจากแสงแดด </li>
              <li>ลูบไล้คอลลาเจนบอดี้เซรั่มทั่วร่างกาย</li>
            </ul>
          </div>
          <div>
            <span class="font-bold">ตอนกลางคืน</span>
            <ul class="list-decimal mt-2 ml-6">
              <li>สครับผิวด้วยกลอรี่สครับบริเวณแขน ขา ข้อศอก และข้อพับ</li>
              <li>ล้างตัวและล้างหน้าให้สะอาดด้วยวิตซีวิปโฟม</li>
              <li>ใช้ถุงตีวิป เพื่อช่วยผลัดเซลล์ผิว</li>
              <li>หยดเซรั่มไฮยา 2-3 หยด แล้วทาให้ทั่วใบหน้า</li>
              <li>รอ 1-2 นาที เพื่อรอให้เนื้อเซรั่มซึมลงผิว</li>
              <li>จากนั้นจึงทาเซรั่มวิตซี</li>
              <li>ลูบไล้คอลลาเจนบอดี้เซรั่มทั่วร่างกาย</li>
              <li>
                ทานกลอรี่คอลลาเจนและโทเมโท่วิตซีอย่างละ 2 แคปซูลก่อนเข้านอน
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ],
  videoContent: {
    title: 'ผิวนุ่ม ชุ่ม ฟู เด้ง',
    subtitle:
      'ด้วยอนุภาค Oilgo Hyaluronic Acid ซึมลึกถึงชั้นใต้ผิว คงความชุ่มชื้นได้ยาวนาน',
    url: 'https://www.youtube.com/watch?v=JWxO2nzSiZM',
  },
  disclaimer: null,
  reviews: null,
  productsIncluded: [
    'RTCHY1',
    'RTCVC1',
    'RTCSB1',
    'RTCWF1',
    'GLRCL1',
    'GLRVC1',
    'GLRBS1',
    'GLRSC1',
  ],
  relateProducts: [
    'RTCSET9',
    'RTCSET10',
    'RTCSET11',
    'RTCSET5',
    'RTCSET6',
    'RTCSET7',
    'RTCSET1',
    'RTCSET12',
    'RTCSET13',
    'RTCSET14',
    'RTCSET2',
    'RTCSET3',
    'RTCSET4',
  ],
  upsellProducts: ['PRECL1', 'RNTFX1'],
});
