export const affiliate = () => {
  return {
    howToPromote: (
      <div class="text-baseColor">
        คุณสามารถโปรโมทสินค้าของกลอรี่เพื่อสร้างรายได้ โดยรีวิวสินค้าลง
        Facebook, Instagram, Tiktok, Line, Youtube
        หรือแนะนำเพื่อนให้มาซื้อสินค้า โดยคุณจะได้รับส่วนแบ่ง 10%
        ของทุกยอดขายที่สั่งซื้อผ่าน Url ที่มีรหัสของคุณ
        <p class="mt-2 font-bold text-black">ตัวอย่าง Url:</p>
      </div>
    ),
    landingPageSkus: ['ARTSR1', 'ARTDC1', 'ARTMS1', 'ARTSET1'],
    exampleOfPromote: (
      <ul class="list-disc ml-6 text-baseColor">
        <li>
          โพสต์รูป Before กับ After ของคุณพร้อมกับสินค้าลง Facebook, IG, Line
          Timeline
        </li>
        <li>
          รีวิวสั้นๆ เกี่ยวกับผลลัพธ์หลังจากใช้สินค้า 1, 3, 5, 7, 15 และ 30 วัน
          พร้อมแปะลิ้งค์สั่งซื้อ
        </li>
        <li>
          โพสต์เหตุผลว่าทำไมต้องใช้ผลิตภัณฑ์นี้ เจอมากจากที่ไหน
          สั่งซื้อได้ที่ไหน
        </li>
        <li>
          คอมเม้นท์รีวิวผลลัพธ์ในคลิป Youtube ที่รีวิวสินค้านี้
          พร้อมแปะลิ้งค์สั่งซื้อของคุณ
        </li>
        <li>โพสต์คลิปสั้นๆ ลง IG Story, Facebook Story</li>
      </ul>
    ),
    photos: [
      {
        folder: 'รูปภาพสินค้าทั้งหมด',
        url:
          'https://drive.google.com/drive/u/0/folders/1AVpZywwkahvqQES71OCZRssisYKZC9hn',
      },
    ],
    termsOfPayment: (
      <div class="text-baseColor">
        จ่ายเงินทุกวันที่ 5 ของเดือนถัดไป เมื่อยอดรายได้สะสมเกิน 200 บาท
        และกรุณาติดต่อแอดมินทางไลน์เพื่อแจ้งเลขที่บัญชีธนาคารสำหรับโอนเงิน
      </div>
    ),
  };
};
