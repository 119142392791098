import { Link } from 'preact-router/match';

export const RNTFX1 = () => {
  return {
    url: '/product/renatar-fiber-x',
    title: 'Renatar Fiber X',
    brand: 'RENATAR',
    sku: 'RNTFX1',
    totalSale: 74358,
    price: 520,
    offerPrice: 650,
    images: ['/product/RNTFX1-bs1.jpg'],
    product: 'Renatar Fiber X 1 กล่อง',
    shortDescription:
      'นวัตกรรมดีท็อกซ์ระดับเซลล์ กระตุ้นการขับถ่าย ล้างสารพิษตกค้างในลำไส้',
    description: (
      <div class="mb-2 text-baseColor">
        <p>
          ผลิตภัณฑ์เสริมอาหารเรนาต้าไฟเบอร์เอ็กซ์ Genomic Detox
          ดีท็อกซ์ระดับเซลล์เจ้าแรกของประเทศไทย นวัตกรรมจากงานวิจัย โดย AVS
          Innovation ช่วยกระตุ้นการขับถ่าย ทำความสะอาดลำไส้ เพิ่มภูมิคุ้มกัน
          และชะลอความแก่ชรา
        </p>
        <ul class="list-disc my-4 ml-10 text-baseColor">
          <li>
            Prebiotic ช่วยเพิ่มจุลินทรีย์ดีในลำไส้ กระตุ้นการขับถ่าย
            ลดอาการท้องผูก
          </li>
          <li>
            Celltuin นวัตกรรมจากงานวิจัย ช่วยกระตุ้นการสร้างโปรตีน Sirtuin
            ทำให้ร่างกายแข็งแรง ไม่อ่อนเพลีย ย้อนอายุเซลล์ ชะลอความแก่ชะรา
          </li>
          <li>ล้างสารพิษตกค้างในร่างกาย ลดการเกิดสิว ทำผิวใส ดูมีออร่า</li>
          <li>กำจัดไขมันส่วนเกินที่สะสมในลำไส้</li>
          <li>เพิ่มประสิทธิภาพในการดูดซึมของสารอาหาร</li>
          <li>จุดเริ่มต้นของการลดความอ้วน</li>
          <li>ลดระดับคอเลสเตอรอล</li>
          <li>เพิ่มการดูดซึมแคลเซียม</li>
          <li>เสริมสร้างระบบภูมิคุ้มกัน</li>
        </ul>
        <p>
          เพื่อผลลัพธ์ที่ดีขึ้นควรทานติดต่อกันอย่างน้อย 21 วัน และทานคู่กับ{' '}
          <Link
            href="/product/auraplus"
            class="text-primary hover:text-primaryLight font-semibold"
          >
            Renatar Aura Plus
          </Link>{' '}
          และ{' '}
          <Link
            href="/bb"
            class="text-primary hover:text-primaryLight font-semibold"
          >
            Renatar BB+
          </Link>
        </p>
      </div>
    ),
    options: [
      {
        sku: 'RNTFX1',
        title: (
          <div>
            1 กล่อง <del>฿650</del>
          </div>
        ),
        price: 520,
      },
      {
        sku: 'RNTFX3',
        title: (
          <div>
            3 กล่อง <del>฿1950</del>
          </div>
        ),
        price: 1540,
      },
    ],
    guarantee: {
      title: 'คุณภาพจากเรนาต้า',
      description: (
        <div>
          ผลิตภัณฑ์จากเรนาต้าทุกตัวได้รับมาตรฐานระดับสากลจาก GMP Codex
          สินค้าทุกชิ้นผลิตจากโรงงานได้มาตรฐานรับรองจาก Intertex
          ผ่านการรับรองคุณภาพจาก HACCP และ HALAL
        </div>
      ),
    },
    ingredients: [
      {
        image: '/ingredient/inulin.png',
        title: 'Inulin (อินนูลิน)',
        description: (
          <div>
            พรีไบโอติก (Prebiotic)
            ที่มีลักษณะเป็นเส้นใยอาหารประเภทที่ละลายได้ในน้ำ (Soluble Fiber)
            ซึ่งร่างกายไม่สามารถย่อยได้ในระบบทางเดินอาหารและไม่ให้พลังงาน
            แต่ถูกย่อยได้ด้วยแบคทีเรียที่อยู่ในลำไส้ใหญ่
          </div>
        ),
      },
      {
        image: '/ingredient/fos.png',
        title: 'Fructooligosaccharide Powder (FOS)',
        description: (
          <div>
            พรีไบโอติก (Prebiotic) ที่มีลักษณะเป็นเส้นใยอาหาร
            เป็นอาหารของแบคทีเรียที่ดีในลำไส้ ช่วยลดจุลินทรีย์ที่ก่อโรค
            (Pathogenic Organism) และเพิ่มภูมิคุ้มกันในร่างกาย
          </div>
        ),
      },
      {
        image: '/ingredient/chitosan.png',
        title: 'Chitosen (ไคโตซาน)',
        description: (
          <div>
            ไคโตซานช่วยลดคอเลสเตอรอล และไขมันในเส้นเลือด
            โดยไคโตซานไปจับกับคอเลสเตอรอล
            ทำให้ร่างกายดูดซึมคอเลสเตอรอลและไขมันได้น้อยลง
          </div>
        ),
      },
      {
        image: '/ingredient/psyliumhusk.png',
        title: 'Psyllium Husk Powder (ไซเลี่ยมฮัสค์)',
        description: (
          <div>
            ทำหน้าที่เหมือนขนแปรงนุ่มๆ ช่วยทำความสะอาด
            ดึงสิ่งสกปรกที่หมักหมมในลำไส้ ให้ออกมากับอุจจาระ
            และล้างลำไส้ให้สะอาด ใยอาหารในไซเลี่ยมฮัสค์
            มีคุณสมบัติในการดักจับไขมันในระบบทางเดินอาหาร
            ช่วยลดปริมาณไขมันที่เข้าสู่ร่างกาย
          </div>
        ),
      },
      {
        image: '/ingredient/gos.png',
        title: 'Galactooligosaccharide (กาแลคโตโอลิโกแซคคาไรด์)',
        description: (
          <div>
            พรีไบโอติก (Prebiotic)
            ที่มีองค์ประกอบใกล้เคียงกับน้ำนมแม่ภายหลังจากการคลอดบุตร
            ช่วยเพิ่มจำนวนแบคทีเรียที่มีประโยชน์ในกลุ่มกรดแลคติกและปิฟิโดแบคทีเรีย
            ช่วยปรับปรุงระบบขับถ่ายให้สมดุล ลดอาการท้องผูก
          </div>
        ),
      },
      {
        image: '/ingredient/pineapple.png',
        title: 'Pineapple Fiber (ใยอาหารจากสัปปะรด)',
        description: (
          <div>
            ใยอาหารประเภทละลายน้ำ มีคุณสมบัติเป็น Prebiotic อาหารของแบคทีเรียดี
            ช่วยดูดซึมแป้งและน้ำตาลช้าลง
          </div>
        ),
      },
      {
        image: '/ingredient/artichoke.png',
        title: 'Artichoke Extract (สารสกัดจากอาร์ทิโชก)',
        description: (
          <div>
            ช่วยย่อยอาหาร ลดไขมัน ลดคอลเลสเตอรอล
            และช่วยเสริมสร้างการทำงานของถุงน้ำดี
          </div>
        ),
      },
      {
        image: '/ingredient/apple.png',
        title: 'Apple (ผงแอปเปิ้ล)',
        description: <div>ต้านอนุมูลอิสระ ลดไขมันและน้ำตาลในเลือด</div>,
      },
      {
        image: '/ingredient/blackberry.png',
        title: 'Blackberry (แบล็คเบอร์รี่)',
        description: (
          <div>
            ต้านอนุมูลอิสระ ลดความเสี่ยงการเกิดมะเร็งและเส้นเลือดอุดตันในสมอง
          </div>
        ),
      },
      {
        image: '/ingredient/cranberry.png',
        title: 'Cranberry (แครนเบอร์รี่)',
        description: (
          <div>
            ต้านอนุมูลอิสระ เสริมภูมิคุ้มกัน ผิวพรรณดี
            และช่วยเเก้โรคกระเพราะปัสสาวะอักเสบ
          </div>
        ),
      },
      {
        image: '/ingredient/raspberry.png',
        title: 'Raspberry (ราสพ์เบอร์รี่)',
        description: (
          <div>
            เป็นสารต้านอนุมูลอิสระ ลดริ้วรอยก่อนวัย และ ป้องกันการเกิดโรคมะเร็ง
          </div>
        ),
      },
      {
        image: '/ingredient/melon.png',
        title: 'Melon (เมล่อน)',
        description: (
          <div>
            เป็นสารต้านอนุมูลอิสระ ช่วยลดความเครียด
            ฟื้นฟูผิวพรรณแข็งแรงไม่หมองคล้ำ
          </div>
        ),
      },
      {
        image: '/ingredient/orange.png',
        title: 'Orange (ส้ม)',
        description: <div>เป็นสารต้านอนุมูลอิสระ ช่วยให้ผิวพรรณกระจ่างใส</div>,
      },
      {
        image: '/ingredient/garcinia.png',
        title: 'Garcinia Cambodia (สารสกัดส้มแขก)',
        description: (
          <div>ดักจับแป้งและไขมัน ลดความอยากอาหร และช่วยเรื่องการเผาพลาญ</div>
        ),
      },
      {
        image: '/ingredient/alfalfa.png',
        title: 'Alfalfa (อัลฟัลฟ่า)',
        description: <div>ช่วยรักษาโรคกระเพาะ และการจุกเสียด</div>,
      },
      {
        image: '/ingredient/grape.png',
        title: 'Grape Skin Extract (สารสกัดเปลือกองุ่น)',
        description: (
          <div>
            ในสารสกัดจากเปลือกองุ่นจะมี Resveratol
            ซึ่งมีคุณสมบัติเป็นสารต้านอนุมูลอิสระที่มีประสิทธิภาพดีเยี่ยม
            ช่วยชะลอความชราขึ้นได้ถึงร้อยละ 30-40
          </div>
        ),
      },
      {
        image: '/ingredient/celltuin.png',
        title: 'Celltuin (เซลล์ทูอิน)',
        description: (
          <div>
            สุดยอดนวัตกรรมการย้อนอายุเซลล์ Detox
            ต่อต้านการอักเสบและอนุมูลอิสระภายในเซลล์ ให้เซลล์กลับมาอ่อนเยาว์
            แข็งแรง
          </div>
        ),
      },
      {
        image: '/ingredient/vitamin.png',
        title: 'Vitamin Premixed (วิตามินรวม)',
        description: (
          <div>
            ต้านอนุมูลอิสระ ซ่อมแซมและเพิ่มเติมส่วนที่ขาดหายของเซลล์ในร่างกาย
          </div>
        ),
      },
    ],
    ingredientSummary: (
      <div>
        <ul>
          <li>Inulin / อินนูลิน 2,500 มก.</li>
          <li>
            Fructooligosaccharide Powder / ผงฟรุคโตโอลิโกแซคคาไรด์ 2,500 มก.
          </li>
          <li>Chitosan (Citoglucan) / ไคโตซาน 2,000 มก.</li>
          <li>Psyllium Husk Powder / ผงไซเลียมฮัสก์ 1,500 มก.</li>
          <li>Galactooligosaccharide / กาแลคโตโอลิโกแซคคาไรด์ 1,000 มก.</li>
          <li>Pineapple Fiber / ใยอาหารจากสัปปะรด 1,000 มก.</li>
          <li>Apple Powder / ผงแอปเปิ้ล 500 มก.</li>
          <li>Artichoke Extract / สารสกัดจากอาร์ทิโชก 500 มก.</li>
          <li>Wheat Grass Powder / ผงข้าวสาลี 500 มก.</li>
          <li>Garcinia Extract / สารสกัดจากส้มแขก 500 มก.</li>
          <li>Raspberry Powder / ผงราสพ์เบอร์รี่ 400 มก.</li>
          <li>Melon Powder / ผงเมล่อน 300 มก.</li>
          <li>Orange Powder / ผงส้ม 300 มก.</li>
          <li>Alfalfa Extract / สารสกัดจากอัลฟัลฟ่า 250 มก.</li>
          <li>Blackberry Juice Powder / ผงน้ำแบล็คเบอร์รี่ 200 มก.</li>
          <li>Cranberry Juice Powder / ผงน้ำแครนเบอร์รี่ 200 มก.</li>
          <li>Grape Skin Extract / สารสกัดจากเปลือกองุ่น 100 มก.</li>
          <li>Vitamin Premix / วิตามินรวม 50 มก.</li>
        </ul>
      </div>
    ),
    fdaId: '10-1-10858-5-0185',
    weight: '15 กรัม x 7 ซอง',
    faqs: [
      {
        question: '1 กล่องมีกี่ซอง',
        answer: <div>เรนาต้าไฟเบอร์เอ็กซ์ 1 กล่องมี 7 ซอง ทานได้ 7 วัน</div>,
      },
      {
        question: 'ทานอย่างไรถึงจะเห็นผลลัพธ์',
        answer: (
          <div>
            ชงกับน้ำเปล่า แล้วทานก่อนนอนพร้อมดื่มน้ำตามเยอะๆ
            โดยเรนาต้าไฟเบอร์จะใช้เวลาทำงาน 6-8 ชั่วโมง ก่อนจะกระตุ้นการขับถ่าย
          </div>
        ),
      },
      {
        question: 'ควรทานติดต่อกันกี่วัน',
        answer: (
          <div>
            <div>ควรทานต่อเนื่องอย่างน้อย 21 วัน เพื่อดีท็อกซ์ร่างกาย</div>
            <div class="mt-2">
              ในกรณีที่ต้องการลดน้ำหนัก ควรทานต่อเนื่อง 1-2 เดือนขึ้นไป
              เพื่อให้การลดน้ำหนักต่อเนื่องและเห็นผลดี
            </div>
            <div class="mt-2">
              เพื่อสุขภาพที่ดีแนะนำให้ดีท็อกซ์ร่างกายทุกๆ 2 เดือน
              หรือทานเป็นประจำทุกวัน
            </div>
          </div>
        ),
      },
      {
        question: 'ทำไมทานแล้วยังไม่ถ่าย',
        answer: (
          <div>
            ช่วง 1-4 วันแรกบางคนอาจจะไม่ถ่ายออกมาเลย เป็นเพราะ
            <ul class="list-decimal ml-6 my-2">
              <li>กินน้ำน้อย</li>
              <li>ลำไส้ไม่ปกติ มีเมือก ไขมันเกาะะผนังมาก</li>
            </ul>
            ดังนั้นควรทานติดต่อกันทุกวันและดื่มน้ำให้เยอะ
            โดยช่วงการปรับสมดุลลำไส้ ฟื้นฟูให้ร่างกายกลับถ่ายเป็นปกติอาจใช้เวลา
            4-7 วัน
          </div>
        ),
      },
      {
        question: 'ทำไมทานแล้วถ่ายท้องบ่อยมาก',
        answer: (
          <div>
            มาจาก 2 สาเหตุคือ
            <ul class="list-decimal ml-6 my-2">
              <li>
                เป็นคนถ่ายง่ายอยู่แล้ว
                อาจลดเหลีอทานครั้งละครึ่งซองและดื่มน้ำตามเยอะๆ
              </li>
              <li>
                แบคทีเรียดีในลำไส้มีน้อย มีสารพิษตกค้างเยอะ
                พรีไบโอติกส์ในเรนาต้าจะค่อยๆ ปรับสมดุลให้กลับมาขับถ่ายเป็นปกติ
                โดยใช้เวลา 4-7 วัน
              </li>
            </ul>
          </div>
        ),
      },
      {
        question: 'ทานแล้วจะปวดบิดหรือไม่',
        answer: (
          <div>
            <div>
              ไม่ เพราะเรนาต้าไฟเบอร์เอ็กซ์ไม่ได้ผสมยาถ่าย
              ดังนั้นจึงไม่กระตุ้นให้ลำไส้บีบตัวอย่างรุนแรงจนปวดบิด
              ไม่ทำให้ลำไส้หย่อนยาน
            </div>
            <div class="mt-2">
              คนที่ทานยาถ่ายมากเกินไป จะทำให้ลำไส้ใหญ่หย่อนยาน
              ไม่สามารถบีบตัวได้เอง เมื่อหยุดทานยาถ่ายก็จะไม่สามารถถ่ายเองได้
            </div>
          </div>
        ),
      },
      {
        question: 'ปวดท้องถ่ายแต่ถ่ายไม่ออกช่วยได้ไหม',
        answer: (
          <div>
            ช่วยได้ เพราะเรนาต้าไฟเบอร์เอ็กซ์จะช่วยทำให้อุจจาระนิ่มขึ้น
            เคลื่อนตัวได้ง่ายขึ้น
          </div>
        ),
      },
      {
        question: 'ถ้ากินยาด้วยควรทานไฟเบอร์ตอนไหน',
        answer: (
          <div>
            ถ้าต้องกินยาหรืออาหารเสริม ควรทานเรนาต้าไฟเบอร์ห่างกัน 2-3 ชั่วโมง
          </div>
        ),
      },
      {
        question: 'ช่วยเรื่องลดน้ำหนักได้ไหม',
        answer: (
          <div>
            ช่วย เพราะถ้าคราบไขมันในลำไส้หลุดออก น้ำหนักตัวจะลดลงประมาณ 1-3
            กิโลกรัม
          </div>
        ),
      },
      {
        question: 'เป็นเบาหวานทานได้หรือไม่',
        answer: (
          <div>
            ทานได้ เพราะเรนาต้าไฟเบอร์เอ็กซ์ไม่มีส่วนผสมของน้ำตาลกลูโคส
            ไม่กระทบต่อระดับฮอร์โมนอินซูลิน ไม่เพิ่มระดับน้ำตาลในเลือด
            ผู้ป่วยโรคเบาหวานสามารถทานได้
          </div>
        ),
      },
      {
        question: 'กินเจทานได้หรือไม่',
        answer: <div>ทานได้ เพราะส่วนผสมทุกอย่างสกัดจากพืช</div>,
      },
      {
        question: 'นอกจากดีท็อกแล้วยังช่วยอะไรอีก',
        answer: (
          <div>
            เรนาต้าไฟเบอร์เอ็กซ์ นอกจากช่วยล้างลำไส้ให้สะอาด
            ดูดซึมสารอาหารได้ดีขึ้นแล้ว ยังช่วยเรื่องดังต่อไปนี้
            <ul class="list-decimal ml-6 my-2">
              <li>ดักจับแป้งและไขมัน ช่วยควบคุมน้ำหนัก</li>
              <li>
                Phytonutients มีสารต้านอนุมูลอิสระ เพิ่มภูมิคุ้มกัน
                และบำรุงผิวพรรณ
              </li>
              <li>
                นวัตกรรม Celltuin ทำให้เซลล์แข็งแรง ย้อนอายุเซลล์ สุขภาพแข็งแรง
                ดูเด็กลง
              </li>
            </ul>
          </div>
        ),
      },
      {
        question: 'เด็กทานได้ไหม',
        answer: (
          <div>
            เด็กอายุ 7 ขวบขึ้นไปทานได้ แต่ให้ทานแค่ครึ่งซอง
            เพื่อให้ได้รับสารอาหารในปริมาณที่พอดีต่อร่างกาย
          </div>
        ),
      },
      {
        question: 'ใครบ้างที่ไม่ควรทาน',
        answer: (
          <div>
            <ul class="list-disc ml-6">
              <li>คุณแม่กำลังตั้งครรภ์ คุณแม่ให้นมบุตร</li>
              <li>เด็กทารก เด็กเล็กอายุต่ำกว่า 7 ขวบ</li>
              <li>
                ผู้ป่วยโรคไต ผู้ป่วยเตรียมผ่าตัด และผู้ป่วยหนักตามแพทย์สั่ง
              </li>
              <li>ผู้ที่แพ้ส่วนผสมตามรายละเอียดข้างกล่อง</li>
            </ul>
          </div>
        ),
      },
      {
        question: 'เป็นภูมิแพ้ทานได้ไหม',
        answer: (
          <div>
            ทานได้และควรทานมาก เพราะมีส่วนผสมที่ช่วยเพิ่มภูมิคุ้มกัน
            และช่วยต้านอนุมูลอิสระ เช่น Celltuin
          </div>
        ),
      },
      {
        question: 'ทานพร้อมเครื่องดื่มไหนได้บ้าง',
        answer: (
          <div>ทานกับน้ำเปล่าดีที่สุด ไม่ควรชงกับนม น้ำร้อน และแอลกอฮอล์</div>
        ),
      },
      {
        question: 'เมื่อถ่ายปกติแล้วหยุดทานได้ไหม',
        answer: (
          <div>
            หยุดทานได้ ไม่มีผลข้างเคียงตามมา
            แต่ถ้าพฤติกรรมการกินอาหารยังเหมือนเดิม ทานอาหารที่ไม่มีกากใย
            ทานของมันๆ และเนื้อสัตว์มากเกินไป อาจทำให้กลับมาท้องผูกอีกได้
            ดังนั้นเพื่อสุขภาพที่ดีควรทานไฟเบอร์เอ็กซ์เป็นประจำทุกวัน
            หรือติดต่อกัน 21 วัน ทุกๆ 2 เดือน
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'เปิดตัว Renatar Fiber X',
      subtitle: 'นวัตกรรมดีท็อกซ์ระดับเซลล์',
      url: 'https://www.youtube.com/watch?v=DSGXR6Ud77Q',
    },
    disclaimer: (
      <div>
        1. เด็ก สตรีมีครรภ์ และสตรีที่ให้นมบุตรไม่ควรรับประทาน
        <br />
        2. สำหรับผู้ที่ใช้ผลิตภัณฑ์เสริมอาหารอื่นๆ ที่มีไขมันเป็นส่วนประกอบหลัก
        ควรรับประทานก่อนหรือหลังผลิตภัณฑ์นี้อย่างน้อย 2 ชั่วโมง
        <br />
        3. ควรระวังในผู้ที่มีน้ำหนักตัวต่ำกว่าเกณฑ์มาตรฐาน
        <br />
        4.
        เพื่อป้องกันภาวะอุดตันของลำไส้ที่อาจเกิดจากการบริโภคผลิตภัณฑ์ใยอาหารชนิดแห้ง
        ควรรับประทานพร้อมน้ำ 1-2 แก้ว
      </div>
    ),
    reviews: [
      {
        image: '/renatar/fiber/review-fiber-4.jpg',
        review: (
          <div>
            เป็นคนถ่ายลำบากมาก ตั้งแต่จำความได้ประมาณ 7-10 วันถ่าย
            บางครั้งถึงขั้นช่วยล้วง หลังจากทานแล้ว ถ่ายทุกวันค่ะ ตรงเวลา วันละ 2
            ครั้ง
          </div>
        ),
        name: 'คุณส้ม ขวัญรัก',
        product: 'RNTFX1',
      },
      {
        image: '/renatar/fiber/review-fiber-5.jpg',
        review: (
          <div>
            เป็นคนถ่ายปกติ แต่มีพุง นั่งติดเก้าอี้ไม่ค่อยออกกำลังกาย
            หลังจากกินไฟเบอร์ น้ำหนักลดไป 2 โล จาก 53 เหลีอ 51 พุงยุบลง
          </div>
        ),
        name: 'คุณยุ้ย สุวภัทร',
        product: 'RNTFX1',
      },
      {
        image: '/renatar/fiber/review-fiber-8.jpg',
        review: (
          <div>
            ปกติเป็นคนท้องผูกมาก ชอบทานของทอด มัน หวาน เลยมีพุง และไขมันสะสมด้วย{' '}
          </div>
        ),
        name: 'คุณส้ม ขวัญรัก',
        product: 'RNTFX1',
      },
      {
        image: '/renatar/fiber/review-fiber-7.jpg',
        review: (
          <div>
            ปกติไม่ค่อยดื่มน้ำ กินผัก ผลไม้น้อยมาก และทำให้เป็นคนถ่ายยาก ปกติ
            2-3 วันถ่ายที หลังจากทานเรนาต้าไฟเบอร์ พุงย้วยๆ ที่หน้าท้อง ลดลงถึง
            2 นิ้วเลยค่ะ
          </div>
        ),
        name: 'คุณอิ่ม สุภาวดี',
        product: 'RNTFX1',
      },
      {
        image: '/renatar/fiber/review-fiber-1.jpg',
        review: (
          <div>
            เป็นคนถ่ายคล่องอยู่แล้ว แต่พุงยังใหญ่มาก หน้าท้องหนา ชอบกินแป้ง
            ของทอดมัน พอหลังทาน พุงยุบลง เยอะมาก กางเกงที่เคยใส่หลวม อิ่มนานขึ้น
            สบายท้อง ไม่อึดอัด
          </div>
        ),
        name: 'คุณกรชวัล',
        product: 'RNTFX1',
      },
      {
        image: '/renatar/fiber/review-fiber-2.jpg',
        review: (
          <div>
            สภาพคนท้องคือพอจะลดหุ่น ลงยากมากที่สุดใน 3 โลก
            แต่ไฟเบอร์เอาอยู่จริงๆ จากน้ำหนัก 49 ตอนนี้เหลือ 47.5 kg
          </div>
        ),
        name: 'คุณนุ้ย กฤติภา',
        product: 'RNTFX1',
      },
      {
        image: '/renatar/fiber/review-fiber-3.jpg',
        review: (
          <div>
            เป็นคนแม่ลูกสอง ก่อนทาน มีปัญหาคือ มีพุงน้อยๆ หลังคลอดที่ลดยากมาก
            หลังจากทานไป พุงยุบลง ใส่เสื้อผ้าสบายขึ้น ไม่อึดอัด
          </div>
        ),
        name: 'คุณฝ้าย ฟ้าวลี',
        product: 'RNTFX1',
      },
      {
        image: '/renatar/fiber/review-fiber-6.jpg',
        review: (
          <div>
            ก่อนทานไฟเบอร์ น้ำหนัก 56 kg รอบเอว 82 cm หลังทานไฟเบอร์
            น้ำหนักลดเหลือ 53.5 kg รอบเอวลดเหลือ 76 cm ค่ะ
          </div>
        ),
        name: 'คุณพร ศิรีพร',
        product: 'RNTFX1',
      },
      {
        image: '/renatar/fiber/review-fiber-9.jpg',
        review: (
          <div>
            ป่วยเป็นหมอนรองกระดูกทับเส้นประสาท นอนติดเตียงมาได้ 4 เดือน
            หลังจากทานไฟเบอร์ 1 เดือน
            หุ่นกลับมาผอมยิ่งกว่าสมัยตอนเข้าฟิตเนสซะอีก
          </div>
        ),
        name: 'คุณเต้ ปรัชญ์',
        product: 'RNTFX1',
      },
      {
        image: '/renatar/fiber/review-fiber-10.jpg',
        review: (
          <div>
            ท้องผูกถ่ายมาก 6-7 วันถ่ายที แต่พอทาน ถ่ายคล่องขึ้น ถ่ายทุกวัน
            ถ่ายตรงเวลา น้ำหนักเริ่มลดลง พุงยุบ สบายตัว ไม่แน่นท้อง จาก 61 กก
            เหลือ 55.2 กก. (แต่ควบคุมอาหาร ออกกำลังกายด้วย)
          </div>
        ),
        name: 'คุณตั๊ก ภาณุมาศ',
        product: 'RNTFX1',
      },
    ],
    productsIncluded: null,
    relateProducts: [
      'RTCHY1',
      'RTCVC1',
      'RTCSB1',
      'RTCWF1',
      'PRECL1',
      'GLRSC1',
      'GLRBS1',
    ],
    upsellProducts: ['RTCHY1', 'RTCVC1', 'RTCSB1'],
  };
};
