export const agent = () => {
  return {
    title: 'เปิดรับตัวแทนจำหน่ายรัชชาแบรนด์',
    images: [
      '/ratcha/agent-04.jpg',
      '/ratcha/agent-07.jpg',
      '/ratcha/agent-03.jpg',
      '/ratcha/agent-02.jpg',
      '/ratcha/agent-06.jpg',
    ],
    content: (
      <div>
        <p class="mt-2">
          อยากมีรายได้เสริมเดือนละ 20,000-30,000 บาท นอกเหนือจากงานประจำ
        </p>
        <p>
          <b>โอกาสที่ดีที่สุดของคุณมาถึงแล้ว !!</b>
        </p>
        <ul class="mt-4 ml-6 list-disc text-baseColor">
          <li>สินค้าคุณภาพจากรัชชาแบรนด์</li>
          <li>เริ่มต้นเปิดบิลขั้นต่ำ 10 กล่อง ลงทุน 3,200 บาท</li>
          <li>ขายปลีกกล่องละ 450 บาท กำไร 1,300-1,430 บาท</li>
          <li>มีสื่อการขายสนับสนุน พร้อมทีมงานคอยซัพพอร์ต</li>
          <li>ไม่มีประสบการณ์ก็ขายได้</li>
          <li>ขายง่าย ลูกค้ากลับมาซื้อซ้ำ</li>
          <li>ไม่บังคับยอด</li>
        </ul>
        <p class="mt-2">
          หากคุณพร้อมเริ่มต้นที่จะเรียนรู้การทำธุรกิจ
          จากนักธุรกิจที่ประสบความสำเร็จแบบ <b> ฟรี ๆ</b>
        </p>
        <p class="mt-2">
          ด้วยการ<u>เปิดบิลตัวแทนจำหน่าย</u>{' '}
          คุณจะมีโอกาสสร้างรายได้สูงถึงหลักหมื่น หลักแสน และหลักล้านบาท
        </p>
        <p class="mt-2">
          <b> อย่าพลาด !</b> โอกาสที่สำคัญที่สุดในชีวิตไป
          ตัดสินใจสร้างธุรกิจกับรัชชาแบรนด์
          แอดไลน์เพื่อสมัครตัวแทนจำหน่ายตอนนี้ได้เลยค่ะ
        </p>
      </div>
    ),
  };
};
