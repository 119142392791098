export const RTCCR3VC2 = () => {
  return {
    url: '/product/double-vitc-5',
    title: 'ชุดวิตซี x5',
    brand: 'RATCHA',
    sku: 'RTCCR3VC2',
    totalSale: 185020,
    price: 997,
    offerPrice: 1387,
    images: ['/product/RTCCR3VC2.jpg'],
    product: 'ชุดวิตซี x5: เซรั่มวิตซี 2 + VC Whitening Cream 3',
    shortDescription: null,
    description: (
      <div class="mb-2 text-baseColor">
        <p>ชุดวิตซี x5 ประกอบไปด้วย</p>
        <ul class="list-disc mt-4 ml-10 text-baseColor">
          <li>เซรั่มวิตซี 2 ขวด</li>
          <li>VC Whitening Cream 3 ซอง</li>
        </ul>
      </div>
    ),
    options: null,
    guarantee: {
      title: 'คุณภาพจากรัชชา',
      description: (
        <div>
          ผลิตภัณฑ์คุณภาพจากรัชชา ได้มาตรฐานอย. พร้อมผ่านการรับรองคุณภาพ GMP
          ผลิตโดยโรงงานมาตรฐาน
        </div>
      ),
    },
    ingredients: null,
    ingredientSummary: null,
    fdaId: null,
    weight: null,
    faqs: [
      {
        question: 'วิธีใช้ให้เห็นผลลัพธ์',
        answer: (
          <div class="grid md:grid-cols-2">
            <div class="mb-4">
              <span class="font-bold">ตอนเช้า</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>ล้างหน้าให้สะอาด</li>
                <li>หยดเซรั่มวิตซี 2-3 หยด แล้วทาให้ทั่วใบหน้า</li>
                <li>รอ 1-2 นาที เพื่อรอให้เนื้อเซรั่มซึมลงผิว</li>
                <li>จากนั้นจึงทาครีมวิตซีไวท์เทนนิ่ง</li>
              </ul>
            </div>
            <div>
              <span class="font-bold">ตอนกลางคืน</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>ล้างหน้าให้สะอาด</li>
                <li>หยดเซรั่มวิตซี 2-3 หยด แล้วทาให้ทั่วใบหน้า</li>
                <li>รอ 1-2 นาที เพื่อรอให้เนื้อเซรั่มซึมลงผิว</li>
                <li>จากนั้นจึงทาครีมวิตซีไวท์เทนนิ่ง</li>
              </ul>
            </div>
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'ปราบสิวขั้นเทพ ผิวหน้ากระจ่างใส',
      subtitle: 'สารสกัด Amitose GOA เพิ่มความขาวใสแบบคูณ 3',
      url: 'https://www.youtube.com/watch?v=5XiUWFjCshQ',
    },
    disclaimer: null,
    reviews: null,
    productsIncluded: ['RTCCR3', 'RTCVC2'],
    relateProducts: [
      'RTCHY1',
      'RTCWF1',
      'RTCSB1',
      'RTCSET12',
      'RTCSET13',
      'RTCSET14',
      'RTCSET2',
      'RTCSET3',
      'RTCSET4',
      'RTCSET5',
      'RTCSET6',
      'RTCSET7',
      'RTCSET8',
      'RTCSET9',
      'RTCSET10',
      'RTCSET11',
    ],
    upsellProducts: ['RTCHY1', 'RTCSB1', 'GLRBS1'],
  };
};
