import { Link } from 'preact-router/match';

export const review = () => {
  return {
    communityReview: [
      {
        image: '/ratcha/jeban-review-1.1.jpg',
        title: '[Jeban] เติมบูสเตอร์ผิวฉ่ำน้ำด้วย RATCHA HYA BOOSTER SERUM',
        url: 'https://www.jeban.com/topic/294973?fbclid',
      },
      {
        image: '/ratcha/jeban-review-1.2.jpg',
        title:
          '[Jeban] รีวิว+ลองของใหม่ : เซรั่มเพิ่มความชุ่มชื่น ผิวนุ่มฟูกับ RATCHA HYA BOOSTER SERUM',
        url: 'https://www.jeban.com/topic/290549',
      },
      {
        image: '/ratcha/jeban-review-1.3.jpg',
        title:
          '[Jeban] เซรั่มไฮยารัชชา & เซรั่มวิตซี คู่หูงานผิว ไอเทมใช้ดีบอกต่อ!!!',
        url: 'https://www.jeban.com/topic/317030',
      },
      {
        image: '/ratcha/jeban-review-1.4.jpg',
        title: '[Jeban] ไฮยารัชชากับเซรั่มวิตซี ใช้คู่กันหน้าฉ่ำไม่ไหว',
        url: 'https://www.jeban.com/topic/317891',
      },
      {
        image: '/ratcha/youtube-review-1.jpg',
        title:
          '[Eyeta] เซรั่ม Ratcha Hya พี่น้องฉัตรว่าดี! จริง? หรือ แกงกัน!!',
        url: 'https://www.youtube.com/watch?v=JWxO2nzSiZM',
      },
      {
        image: '/ratcha/youtube-review-2.jpg',
        title:
          '[James Badbitch] "รีวิวตามคำขอ" เซรั่มไฮยาน้องฉัตร หน้าฉ่ำ หน้าขาวใส ชุ่มชื่น หน้าขาว',
        url: 'https://www.youtube.com/watch?v=D-KXH4pz5qo',
      },
      {
        image: '/ratcha/youtube-review-3.jpg',
        title:
          '[SG Channel] Ratcha Hya Booster Serum ผสานคุณค่าความอ่อนโยน กักเก็บความชุ่มชื้น ',
        url: 'https://www.youtube.com/watch?v=ufYB6ZuHw8o',
      },
      {
        image: '/ratcha/youtube-review-4.jpg',
        title:
          '[Minimayy Blog] VC Vit C น้องฉัตร ดีกว่าที่คิด คาดหวังผลได้จริง! ',
        url: 'https://www.youtube.com/watch?v=DQP-gvN6DSE&t=199s',
      },
      {
        image: '/ratcha/youtube-review-5.jpg',
        title:
          '[Piper tell ไปเปอร์] คนเป็นสิวต้องดู! รีวิว VC VIT C ในตำนาน แบบครบเซ็ท ',
        url: 'https://www.youtube.com/watch?v=M4lFV1LVHmM',
      },
      {
        image: '/ratcha/youtube-review-6.jpg',
        title:
          '[Superthankky] Vit c น้องฉัตร VS Merci คุณหมอ ในตำนาน! ถ้าต้องเลือกเพียง 1 เดียว ! ไม่อวย ตรงที่สุด!  ',
        url: 'https://www.youtube.com/watch?v=1JyUvQW40OE',
      },
      {
        image: '/ratcha/youtube-review-7.jpg',
        title:
          '[RAN_COSMATIC_TH] รีวิว VC vit C bio Face Serum เซรั่มวิตามินซี บำรุงผิวหน้า โดย น้องฉัตร ',
        url: 'https://www.youtube.com/watch?v=qd6YwpezR6U',
      },
      {
        image: '/ratcha/youtube-review-8.jpg',
        title:
          '[Piyachat Wankaew] รีวิวผู้ใช้งานจริง เซรั่มน้องฉัตร เซรั่ม รัชชา ไฮยา บูสเตอร์ Ratcha Hya Booster Serum',
        url: 'https://www.youtube.com/watch?v=ukw6gqEzLPI',
      },
      {
        image: '/ratcha/youtube-review-9.jpg',
        title:
          '[Nongchat Art Makeup Academy] HYA Booster Serum เปล่งประกายความออร่า ให้หน้าของคุณ',
        url: 'https://www.youtube.com/watch?v=iy0x6NQ_fMQ',
      },
      {
        image: '/ratcha/youtube-review-10.jpg',
        title:
          '[CAPNUTT] โฟมโครตนุ่ม หลังล้างหน้าเสร็จหน้า พุ่ง สว่าง สะอาดไปอีก VC VIT C FOAM',
        url: 'https://www.youtube.com/watch?v=IMdU_dOhcec',
      },
      {
        image: '/ratcha/youtube-review-11.jpg',
        title:
          '[ThepSkincrew] รีวิววิปโฟมนุ้มนุ่ม ล้างปุ๊บกระจ่างใสปั๊บ VIT C WHIP FOAM',
        url: 'https://www.youtube.com/watch?v=THz0m1p9bgc',
      },
      {
        image: '/ratcha/youtube-review-12-1.jpg',
        title: '[WONDER BOY] Review กันแดด...ใช้แสงบำรุงผิว',
        url: 'https://www.youtube.com/watch?v=GuVIJc_uCS4',
      },
    ],
    customerReview: [
      {
        image: '/ratcha/review-1.jpg',
        review: (
          <div>
            โดนใจมากเซรั่มที่เน้นพวกให้ความชุ่มชื้นกับผิว เพราะเป็นคนที่ผิวแห้ง
            ผิวขาดน้ำ พอได้ลองแล้วรู้สึกว้าว ตั้งแต่เนื้อสัมผัสที่เหมือนน้ำ
            ทาลงผิวแล้วให้ความชุ่มชื้น ทาแล้วไม่มัน ซึมไว ใช้ไปเรื่อยๆ หน้าฟู
            เครื่องสำอางติดทนขึ้นด้วย
          </div>
        ),
        name: 'คุณไข่',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-2.jpg',
        review: (
          <div>
            ใครผิวแห้งขาดน้ำ อยากแนะนำตัวนี้เลย เค้าเติมความชุ่มชื้นให้ผิวดีมาก
            ผิวอิ่มน้ำขึ้น เนื้อเซรั่มเค้าบางเบา ฟิลเหมือนน้ำเปล่า
            ไม่เหนอะหนะผิว ทางเราใช้ทั้งเช้าทั้งเย็นเลย รู้สีกว่าผิวใสขึ้นด้วย
          </div>
        ),
        name: 'คุณเฟิร์ส',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-3.jpg',
        review: (
          <div>
            ตัวนี้จะช่วยในเรื่องให้ความชุ่มชื้น ดูอิ่มน้ำ ผิวหน้าสว่างกระจ่างใส
            ดูมีออร่า ลดเลือนริ้วรอยให้จางลง
            และที่สำคัญเขายังสามารถกักเก็บน้ำบนใบหน้าของเราไม่ให้แห้งตึงอีกด้วย
            ใช้ทั้งเช้าและเย็นคือดีย์มากๆ แล้วยิ่งแช่เย็นคือสดชื่นอ่ะ
          </div>
        ),
        name: 'คุณส้ม',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-4.jpg',
        review: (
          <div>
            เป็นเซรั่มที่ยกให้เรื่องอิ่มฟู ฉ่ำน้ำ
            ตัวนี้นอกจากผิวจะชุุ่มชื้นแล้วผิวหน้าค่อยๆ ใสขึ้นอีกด้วย
            แล้วทาตอนเช้าสังเกตได้เลยว่า วันนั้นเมคอัพติดทนมาก หน้าเด้ง
            และมีสารสกัดจากว่านหางจระเข้ช่วยปลอบประโลมผิวแสบร้อนระคายเคืองได้ด้วยนะ
          </div>
        ),
        name: 'คุณอิ้งค์',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-5.jpg',
        review: (
          <div>
            มาป้ายยาค่ะ เป็นปลื้มมากๆๆๆ คือชอบฟีลตอนใช้มาก มันชุ่มชื้นหน้านุ่มๆ
            และเค้าไปดูสารสกัดคือสิบสิบ ให้ความชุ่มชื้นมากกว่า Collagen Peptide
            อีก และคือปอพึ่งบ่นเรื่องหน้าแก่ น้องเค้าช่วยเรื่องริ้วรอยค่ะแม่
            เธอมาได้ทันเวลาพอดี
          </div>
        ),
        name: 'คุณปอ',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-10.jpg',
        review: (
          <div>
            ทุกคน!!! เซรั่มไฮยาขวดฟ้าตัวนี้คือดีมากๆ เคยเห็นพี่ในมอรีวิวว่า
            ช่วยให้ผิวไม่แห้งเลยไปซื้อตาม เพราะช่วงนี้หน้าแห้ง
            แถมนอนดึกหน้าโทรมสุดๆ พอลองใช้คือดีจริงๆ หน้าฟู ชุ่มชื้นและฉ่ำมาก
            ไม่เหนียวแถมซึมไวด้วย ต้องไปลอง
          </div>
        ),
        name: 'คุณซินดี้',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-11.jpg',
        review: (
          <div>
            หมดไปครึ่งขวดแล้วกับ Ratcha Hya ประทับใจมากกกก ผิวละเอียดขึ้นเรื่อยๆ
            รูขุมขนกระชับมากขึ้น ตัวนี้แทบจะไม่มีกลิ่นเลย เป็นน้ำใสๆ
            อ่อนโยนและซึมผิวได้ดี ไม่เหนียวเหนอะหนะ ไม่รบกวนสกินแคร์ตัวอื่น
            ผิวดูอิ่มน้ำ + กระจ่างใส
          </div>
        ),
        name: 'คุณกุ้ง',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-8-1.jpg',
        review: (
          <div>
            สกินแคร์เพื่อผิวอิ่มน้ำ Ratcha Hya ตัวนี้ช่วยผิวแห้งกร้านได้ดีมาก
            ทำให้ผิวฟู ชุ่มชื้น ตัวเนื้อคล้ายน้ำเปล่าเลย
            มีความหนืดเล็กน้อยเท่านั้น ซึมไวมาก ไม่เหนอะผิว ไม่หนักหน้า
            แถมแพคเกจคือชนะเริ่ดไปเลย
          </div>
        ),
        name: 'คุณเบคอน',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-6.jpg',
        review: (
          <div>
            เป็นเซรั่มที่ใช้แล้วชอบเลย เนื้อเซรั่มใสๆ ซึมง่าย
            ช่วยเติมความชุ่มชื้น ตื่นมาผิวหน้าไบร์ท ฟูฉ่ำ เหมือนคนนอนเต็มตื่น
            คือชอบหน้าตัวเองมาก มันผ่อง ใสบอกไม่ถูก นี่ใช้ทั้งเช้าทั้งก่อนนอนเลย
            ชอบจริง!
          </div>
        ),
        name: 'คุณการ์ฟิว',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-9.jpg',
        review: (
          <div>
            มาช้าแต่มานะ ซุ่มใช้น้อง Ratcha Hya มาครึ่งขวดแล้ววว ดีงามอยู่เด้อ
            ว่าไม่ได้!! ตัวนี้เน้นความชุ่มชื้นนะ นี่ชอบใช้หลังลงน้ำตบ
            และก่อนลงพวกเซรั่มอื่นๆ ตาม หน้าโดยรวมคือเรียบเนียนขึ้น
            ดูสุขภาพผิวดีอะ ต้องลอง
          </div>
        ),
        name: 'คุณบูม',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-12.jpg',
        review: (
          <div>
            เซรั่ม Ratcha Hya เป็นเซรั่มที่ทำให้หน้าอิ่มฟูขึ้นมาก
            ให้ความชุ่มชื้น เหมาะกับคนผิวหน้าแห้ง ขาดน้ำ เนื้อใสๆ ไม่เหนียว
            ซึมเข้าผิวง่าย มีส่วนผสมของว่านหางจระเข้ด้วย และช่วยเรื่องให้คสอ.
            ติดทนขึ้นด้วยนะคะ
          </div>
        ),
        name: 'คุณหมวย',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-7.jpg',
        review: (
          <div>
            ผิวแพ้ง่ายมารวมกันทางนี้ ขอแนะนำ Ratcha Hya ตัวนี้หลักๆ
            เลยสกัดจากว่านหางจระเข้ ช่วยสมานผิว
            พร้อมเติมความชุ่มชื้นให้ผิวแล้วก็ยังสกัดจาก Allantoin
            ที่จะมาช่วยให้ผิวกระจ่างใสอีกด้วย เนื้อเซรั่มซึมไว
            ไม่เหนอะหนะอยู่บนผิว เหมาะกับอากาศบ้านเรา
          </div>
        ),
        name: 'คุณแพรว',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-25.jpg',
        name: 'หน้าดูชุ่มชื้นขึ้น ไม่เหนอะหนะ',
      },
      {
        image: '/ratcha/review-26.jpg',
        name: 'เนื้อเซรั่มซึมไวมาก ไม่เหนอะหน้า',
      },
      {
        image: '/ratcha/review-27.jpg',
        name: 'ผิวดูเรียบเนียนขึ้น ชุ่มชื้นไม่แห้งกร้าน',
      },
      {
        image: '/ratcha/review-28.jpg',
        name: 'ริ้วรอยลดลง ผิวหน้าเนียนนุ่มขึ้นมาก',
      },
      {
        image: '/ratcha/review-29.jpg',
        name: 'ผิวดูอิ่มน้ำ และดูนุ่มฟูขึ้้น',
      },
      {
        image: '/ratcha/review-30.jpg',
        name: 'ช่วยให้ผิวอิ่มน้ำ ผิวฟูขึ้น และคงความชุ่มชื้นให้กับผิว',
      },
      {
        image: '/ratcha/review-31.jpg',
        name: 'ซึมไว ใช้แล้วผิวฟูและอิ่มน้ำมาก',
      },
      {
        image: '/ratcha/review-32.jpg',
        name: 'เนื้อเซรั่มเกลี่ยง่าย ซึมไว',
      },
      {
        image: '/ratcha/review-33.jpg',
        name: 'กระชับรูขุมขน ลดเลือนริ้วรอย',
      },
      {
        image: '/ratcha/review-34.jpg',
        name: 'ผิวเด้งฟู อิ่มน้ำ ริ้วรอยลดลง',
      },
      {
        image: '/ratcha/review-35.jpg',
        name: 'หน้าเด็กลง ผิวหน้าดูเรียบเนียนขึ้น',
      },
      {
        image: '/ratcha/review-36.jpg',
        name: 'สิวยุบ รูขุมขนกระชับ',
      },
      {
        image: '/ratcha/review-17.jpg',
        name: 'ผิวอิ่มน้ำ ดูชุ่มชื้น ซึมง่ายมาก',
      },
      {
        image: '/ratcha/review-18.jpg',
        name: 'ให้ความชุ่มชื้น ปรับผิวให้กระจ่างใส',
      },
      {
        image: '/ratcha/review-19.jpg',
        name: 'แพ้ครีมมา ตอนนี้หน้าดึขึ้นมากๆ',
      },
      {
        image: '/ratcha/review-20.jpg',
        name: 'หน้าชุ่มชื้นขึ้น รอยสิวดูจางลง',
      },
      {
        image: '/ratcha/review-21.jpg',
        name: 'รอยสิวจางลง ผิวหน้ากระจ่างใส',
      },
      {
        image: '/ratcha/review-22.jpg',
        name: 'ช่วยเติมน้ำให้ผิว เนื้อบางเบาซึมไว',
      },
      {
        image: '/ratcha/review-23.jpg',
        name: 'เติมน้ำให้ผิว ตื่นมาหน้าฟูเด้ง',
      },
      {
        image: '/ratcha/review-24.jpg',
        name: 'เซรั่มที่ทุกคนไว้วางใจ',
      },
      {
        image: '/ratcha/review-13.jpg',
        name: 'รอยสิวจางลง รูขุมขนดูเล็กลง',
      },
      {
        image: '/ratcha/review-14.jpg',
        name: 'ซึมไว ไม่รู้สึกถึงความหน้าแห้ง',
      },
      {
        image: '/ratcha/review-15.jpg',
        name: 'ผิวหน้าเนียนนุ่ม สิวผดเล็กๆ ค่อยๆ หายไป',
      },
      {
        image: '/ratcha/review-16.jpg',
        name: 'ลดใต้ตาคล้ำ ลดเลือนฝ้ากระ',
      },
      {
        image: '/ratcha/review-37.jpg',
        name: 'ลดสิว ผิวกระจ่างใส',
      },
      {
        image: '/ratcha/review-38.jpg',
        name: 'รอยสิวดูจางลง ปรับสีผิวให้สม่ำเสมอ',
      },
      {
        image: '/ratcha/review-39.jpg',
        name: 'ลดรอยดำรอยแดงที่เกิดจากสิว',
      },
      {
        image: '/ratcha/review-40.jpg',
        name: 'ผิวหน้ากระจ่างใส ไม่โทรม',
      },
      {
        image: '/ratcha/review-41.jpg',
        name: 'ลดรอย ปรับผิวให้เรียบเนียน ',
      },
      {
        image: '/ratcha/review-42.jpg',
        name: 'ลดฝ้ากระ จุดด่างดำ',
      },
      {
        image: '/ratcha/review-43.jpg',
        name: 'ลดรอยที่เกิดจากสิว ผิวดูสม่ำเสมอ',
      },
      {
        image: '/ratcha/review-44.jpg',
        name: 'จุดด่างดำจางลง ผิวหน้ากระจ่างใส',
      },
      {
        image: '/ratcha/review-vc-4.1.jpg',
        review: (
          <div>
            ใช้จนหมดขวดล่ะ กำลังจะเริ่มขวด 2 สิวอักเสบแห้งเร็ว
            ลดรอยดำรอยแดงได้ดี โดยเฉพาะรอยใหม่ๆ นี่หายเร็วเว่อร์ เนื้อเหลวใส
            ไม่มีสี ไม่มีกลิ่น ซึมไว รู้สึกว่าใช้แล้วแต่งหน้าติดทนขึ้นด้วยค่ะ
            ดีงามม
          </div>
        ),
        name: 'คุณวี',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-3.1.jpg',
        review: (
          <div>
            เซรั่มวิตซีตัวดัง ลองแล้วชอบมากก บางเบามากๆ ไม่เหนียวเลย
            เหมือนทาน้ำเปล่าที่ผสมสารบำรุง ใช้แล้วไม่แสบหน้าเหมือนวิตซีบางตัว
            หน้าใสขึ้น รอยยังไม่เห็นผลมาก แต่ซื้อต่อแน่นอน!
          </div>
        ),
        name: 'คุณเอิน',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-2.1.jpg',
        review: (
          <div>
            ใครมีรอยสิว รอยแดง รอยดำต่างๆ ช่วงนี้ แนะนำเลยๆ ลดรอยดีมาก
            หน้าใสขึ้นด้วย ละเนื้อสัมผัสของเซรั่ม ซึมง่ายมาก
            แต่ยังให้ความรู้สึกผิวชุ่มชื้น ชอบๆ
          </div>
        ),
        name: 'คุณป่าน',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-1.1.jpg',
        review: (
          <div>
            เซรั่มกู้หน้าของจริง เป็นสิว เป็นรอยแดงรอยดำเอาอยู่หมด
            ใช้มาพักนึงละสิวแห้งไวมาก แถมรอยสิวก็ค่อยๆ จางลง ทาติดต่อกันทุกวัน
            หน้าใสไม่โทรมเลย เนื้อซึมง่ายไม่เหนอะหน้า
            แต่งหน้าทับก็ไม่เป็นเป็นคราบด้วย ดีจริงไปตำ
          </div>
        ),
        name: 'คุณมา',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-8.1.jpg',
        review: (
          <div>
            ช่วยให้ผิว Bright ขึ้น รอยดำรอยแดงลดลงเร็วกว่าปกติ หัวเป็น Dropper
            กะปริมาณเวลาใช้ง่าย ไม่เลอะเทอะ ไม่เปลือง เนื้อเซรั่มเหลวๆ
            ไม่หนักหน้า ลงก่อนแต่งหน้าได้ หนึบหน่อยๆ ใช้เป็นไพร์เมอร์ได้เลย
            เมคอัพติดหน้า
          </div>
        ),
        name: 'คุณผิง',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-6.1.jpg',
        review: (
          <div>
            ตัวนี้ดีน้า ใช้มาหมดไปขวดแล้วกำลังใช้ขวดที่สอง เราเป็นรอยดำแดงจากสิว
            พอใช้มาสักพักรอยจางลงจริงๆ รอยใหม่จะจางไวกว่านะ
            ส่วนรอยเก่าคงต้องใช้เวลาหน่อย เนื้อไม่หนาไม่ทำให้หน้ามัน
            ทาก่อนแต่งหน้าก็แต่งง่ายขึ้นด้วย ราคาไม่แพง แนะนำๆ
          </div>
        ),
        name: 'คุณหยก',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-7.1.jpg',
        review: (
          <div>
            รีวิวไปแล้วหลายรอบมาก เพราะใช้ตลอดจริงๆ
            ตอนนี้เขามีแพ็คเกจกล่องใหม่ด้วยนะ นี่ก็่ขวดที่ 5-6 แล้ว ลดรอยสิว
            ผิวใสได้ดีมาก รูขุมขนกระชับ ผิวฟูเด้งมาก
          </div>
        ),
        name: 'คุณเฟิน',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-5.1.jpg',
        review: (
          <div>
            นอนน้อยหน้าพังเป็นสิว สิวอุดตัน รูขุมขนไม่กระชับ ฝ้ากระ
            อยากหน้าใสขึ้น ขวดเดียวนี่เอาอยู่จริงๆ ใช้จนสมัครเป็นตัวแทนขาย
            พอขายก็ดันใช้จนหมดไม่ได้ขาย55555
          </div>
        ),
        name: 'คุณเฟิน',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-15.jpg',
        name: 'คุณเฟรม',
      },
      {
        image: '/ratcha/review-vc-16.jpg',
        name: 'คุณจ๊ะจ๋า',
      },
      {
        image: '/ratcha/review-vc-11.jpg',
        name: 'คุณจอย',
      },
      {
        image: '/ratcha/review-vc-12.jpg',
        name: 'คุณโบนัส',
      },
      {
        image: '/ratcha/review-vc-13.jpg',
        name: 'คุณปุ๋ย',
      },
      {
        image: '/ratcha/review-vc-14.jpg',
        name: 'คุณยุ',
      },
      {
        image: '/ratcha/review-vc-10.jpg',
        name: 'คุณเนม',
      },
      {
        image: '/ratcha/review-vc-9.jpg',
        name: 'ใช้ดีจนเป็นตัวแทน',
      },
      {
        image: '/ratcha/review-uv-1.1.jpg',
        review: (
          <div>
            เลือกประเทศเกิดไม่ได้ แต่แพรเลือกครีมกันแดดได้ "ครีมกันแดดรัชชา"
            ตอบโจทย์อากาศเมืองไทย ทาแล้วไม่เหนียว ไม่รุงรังหน้า ซึมไว
            สบายผิวสุดๆ
          </div>
        ),
        name: 'คุณแพร',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-2.1.jpg',
        review: (
          <div>
            คุ้มเกินคุ้ม ทาท้าแดดด้วยกันแดดวิตซียูวีโพเทคชั่น บีบจนคอหลอดหัก
            ตัดซะเลย ค้างหลอดใช้ได้ต่อ 2 วัน
          </div>
        ),
        name: 'คุณปูน้อย',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-3.1.jpg',
        review: (
          <div>
            กันแดดวิตซีน้องฉัตร บำรุงไปพร้อมกับการป้องกันผิวจากแสงแดด และมลพิษ
            ทำไมนาทีนี้ ทุกเสียงต่างชื่นชม ทุกคนต่างเทใจให้กันแดดพลัสวิตามินซี
            ก็เพราะมันเริ่ดในทุกจุด เนียน บางเบา ปกปิด ให้ความขาวกระจ่างใส
            ละลายลงผิว
          </div>
        ),
        name: 'คุณโจ',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-4.1.jpg',
        review: (
          <div>
            ใช้ลงพื้นก่อนแต่งหน้า ช่วยให้เครื่องสำอางค์ติดทน แต่งหน้าลุคไหนๆ
            ก็สวยเป๊ะ
          </div>
        ),
        name: 'คุณเบลล์',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-5.1.jpg',
        review: (
          <div>
            ไม่กลัวแก่ ไม่แคร์แดด ช่วยให้ริ้วรอยก่อนวัยดูจางลง
            ให้ผิวมีความยืดหยุ่น เด้งฟู สดใส ผิวหน้าดูกระจ่างใส ไบรท์ ออร่า
            แลดูเด็กลง
          </div>
        ),
        name: 'คุณสวย',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-6.1.jpg',
        review: (
          <div>
            กันแดดของรัชชาตัวนี้ มีส่วนผสมของ Vit C ด้วย ช่วยป้องกันแสงแดด
            SPF40pa+++ เนื้อกันแดดบางเบา ซึมเร็ว
            ไม่มีสีทาแล้วหน้าไม่วอก(ชอบตรงนี้) ไม่เหนอะผิวดี หลังทาหน้าแอบผ่องๆ
            ฉ่ำๆ ขึ้นนิดนึงด้วย
          </div>
        ),
        name: 'คุณหมวย',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-7.1.jpg',
        review: (
          <div>
            เป็นกันแดดที่บางเบา ทาแล้วกลืนไปกับผิว หลังทาแล้วผิวดูชุ่มชื้นหน่อยๆ
            เหมาะแก่การลงรองพื้น ค่อนข้างคุมมัน
            หลังใช้รู้สึกเห็นผลเรื่องไม่อุดตันผิว รอยสิวดูจางไวนิดหนึ่ง
          </div>
        ),
        name: 'คุณเจน',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-8.1.jpg',
        review: (
          <div>
            กรี๊ด! ทำไมชั้นพึ่งมาเจอ ชอบมากกันแดดอันนี้ เนื้อเจลบางๆ ไม่มัน
            สิวไม่ขึ้นเลย รอยสิวจางลงอยู่นะ ไม่ผสมรองพื้น แต่รู้สึกผิวใส วาวๆ
            ขึ้นอ่ะ ชอบตรงนี้555
          </div>
        ),
        name: 'HowtoPerfect',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-whip-1.1.jpg',
        review: (
          <div>
            เราใช้ไปประมาณอาทิตย์กว่า หน้าเริ่มใสขึ้น ขาวขึ้นจริงจัง
            ล้างแล้วหน้าไม่เอี๊ยด ล้างสะอาดมาก ตัวนี้ใช้นิดเดียวก็ตีโฟมได้เริ่ด
          </div>
        ),
        name: 'คุณชาลี่',
        product: 'RTCWF1',
      },
      {
        image: '/ratcha/review-whip-2.1.jpg',
        review: (
          <div>
            ผิวหน้าสะอาดได้ในขั้นตอนเดียว เพราะการทำความสะอาดหน้าเป็นสิ่งสำคัญ
            ยับยั้งต้นตอของปัญหาสิว ผิวมัน หน้าหมอง ไม่บาดผิว คืนความชุ่มชื่น
            บำรุงผิวด้วยนวัตกรรมจากญี่ปุ่น GO-VC ไร้สารตกค้าง
          </div>
        ),
        name: 'คุณเบลล์',
        product: 'RTCWF1',
      },
      {
        image: '/ratcha/review-whip-3.1.jpg',
        review: (
          <div>
            เครียดเฟสเล่นไม่ได้ ไอจีก้อเสือกเจ๊งอีก ล้างหน้าด้วย VC WhipFoam รอ
            หน้าสะอาดผิวนุ้มนุ่ม
          </div>
        ),
        name: 'คุณปุ้ย',
        product: 'RTCWF1',
      },
      {
        image: '/ratcha/review-whip-4.1.jpg',
        review: (
          <div>
            เนื้อวิปโฟมเค้าฟองละเอียด นุ่มมากก ละมุน เนื้อโฟมฟูๆ
            ช่วยทำสะอาดผิวหน้าได้อย่างอ่อนโยน เบาสบายผิว
            และช่วยลดการเกิดสิวด้วยค่ะ
          </div>
        ),
        name: 'Make Up Review',
        product: 'RTCWF1',
      },
      {
        image: '/ratcha/review-whip-5.1.jpg',
        review: (
          <div>
            เนื้อโฟมเป็นวิปสีขาว นุ่มมาก เวลาโดนน้ำจะเปลี่ยนเป็นเนื้อวิป
            ฟองโฟมนุ่มฟู มีกลิ่นหอมอ่อนๆ อารมณ์วิตามินซีเน้นๆ ล้างหน้าได้แบบฟินๆ
            ล้างออกง่าย ทำความสะอาดเครื่องสำอางได้อย่างหมดจด
          </div>
        ),
        name: 'Cosmemet',
        product: 'RTCWF1',
      },
      {
        image: '/ratcha/review-whip-6.1.jpg',
        review: (
          <div>
            โอ้ยยยย ถ้าจะเทียบกันขนาดนี้ หมดคำพูดละ Lamer กับ Vc WhipFoam
            คุณภาพไม่ต่างกันเลยจ้า
          </div>
        ),
        name: 'คุณเดือน',
        product: 'RTCWF1',
      },
      {
        image: '/ratcha/review-whip-8.1.jpg',
        review: (
          <div>วิปโฟมล้างหน้าวิตามินซี รอยสิวจางไว คนอยากผิวใสต้องไปตำ!</div>
        ),
        name: 'คุณเจน',
        product: 'RTCWF1',
      },
      {
        image: '/ratcha/review-whip-7.jpg',
        review: <div>สะอาดหมดจด อ่อนโยนแม้ผิวแพ้ง่าย</div>,
        name: 'Review',
        product: 'RTCWF1',
      },
    ],
  };
};
