export const general = () => {
  return {
    appVersion: '3.0.0',
    appName: 'Ratcha',
    siteUrl: 'ratchabrand.com',
    siteBrand: 'RatchaBrand',
    logo: '/ratcha/ratcha-logo.jpg',
    gtmId: 'GTM-TZ6B6KB',
    lineUrl: 'https://line.me/R/ti/p/%40800mzwqs',
    lineChannel: null,
    messengerUrl: null,
    pwaText: 'ติดตั้งแอปเพื่อรับส่วนลดและโปรโมชั่นพิเศษจากรัชชา',
    shopName: 'Ratcha',
    company: 'บริษัท เดอ ละมุน จำกัด',
    address:
      '299/115 ซอยกาญจนาภิเษก 005/1 แขวงหลักสอง เขตบางแค กรุงเทพมหานคร 10160',
    tel: '088-980-8497',
    telUrl: 'tel:+66889808497',
    email: 'cs@delamun.com',
    paymentBankAccount: 'บจก. เดอ ละมุน',
    paymentBankName: 'ธนาคารกสิกรไทย',
    paymentBankId: '071-3-48481-4',
    shippingDateMin: 2,
    shippingDateMax: 5,
    codFees: 50,
    shippingFees: 0,
    facebookAppId: '1525108917781704',
    oneSignalId: '26b37d5f-78ee-46c6-9763-99557b8ba54f',
    oneSignalSafariId:
      'web.onesignal.auto.6a2e4cfc-4f7f-4e0a-b787-2d0bd3e78806',
    asSeenOn: [
      '/general/jeban-logo-grayscale.webp',
      '/general/sanook-logo-grayscale.jpg',
      '/general/dekd-logo-grayscale.jpg',
      '/general/trueid-logo-grayscale.jpg',
      '/general/medthai-logo-grayscale.jpg',
      '/general/healthy-dd-logo-grayscale.jpg',
    ],
    disclaimer: (
      <div>
        "รัชชา" เป็นเครื่องสำอางไม่ใช่ยารักษาโรค
        ดังนั้นจึงไม่มีผลป้องกันหรือรักษาโรคใดๆ
        เมื่อใช้รัชชาจะเริ่มเห็นผลลัพธ์ใน 2-4 สัปดาห์แรก
        และจะสังเกตถึงการเปลี่ยนแปลงได้ชัดภายใน 2-3 เดือนหลังจากใช้
        โดยผลลัพธ์ที่ได้ขึ้นอยู่กับแต่ละบุคคล รัชชาใช้สำหรับภายนอกเท่านั้น
        หากเกิดการระคายเคืองควรหยุดใช้และปรึกษาแพทย์หรือเภสัชกร
      </div>
    ),
    promoteAgent: {
      title:
        'แค่โพสต์คลิปรีวิว 30 วินาทีใน TikTok ก็สร้างรายได้ 1.4 ล้านบาท ภายใน 3 เดือน',
      description:
        'จากคุณแม่ลูกสองที่โพสต์คลิปรีวิวเซรั่มรัชชาใน TikTok เล่น ๆ จนสร้างรายได้ 1.4 ล้านบาท ภายใน 3 เดือน ด้วยการเป็นตัวแทนจำหน่ายเซรั่มรัชชา จินทำได้คุณก็ทำได้ !',
      url: 'https://serumstory.com/agent-ratcha-tiktok-30s/',
      image: '/serumstory/ratcha-serum/ratcha-jin-shipped.jpg',
    },
  };
};
