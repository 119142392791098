export const about = () => {
  return {
    title: 'เกี่ยวกับรัชชา',
    intro: {
      title: 'สินค้าคุณภาพจากรัชชา',
      image: '/ratcha/about-ratcha.jpg',
      content: (
        <p>
          แบรนด์คุณภาพจากรัชชา การันตีโดยน้องฉัตร
          เมคอัพสไตลิสต์ชื่อดังของเมืองไทย ใช้เอง ใช้ดี ถึงกล้าแนะนำ
        </p>
      ),
    },
    video: {
      title: 'โมเลกุลเล็ก กักเก็บน้ำได้ยาวนานถึง 3 วัน',
      url: 'https://www.youtube.com/watch?v=iy0x6NQ_fMQ',
    },
    product: {
      title: 'สินค้าแบรนด์รัชชา',
      image: '/product/ratcha-set-e.jpg',
      description: <p>ผลิตภัณฑ์จากแบรนด์รัชชาประกอบด้วย</p>,
      items: [
        {
          title: 'Ratcha Hya Booster Serum',
          url: '/product/ratcha',
        },
        {
          title: 'Vit c Bio Face Serum',
          url: '/product/vit-c',
        },
        {
          title: 'Vit C UV Protection',
          url: '/product/vc-uv',
        },
        {
          title: 'Vit C WhipFoam',
          url: '/product/whipfoam',
        },
      ],
    },
    media: {
      title: 'รัชชาในสื่อต่างๆ',
      items: [
        {
          image: '/ratcha/youtube-review-1.jpg',
          url: 'https://www.youtube.com/watch?v=JWxO2nzSiZM',
          title:
            '[Eyeta] เซรั่ม Ratcha Hya พี่น้องฉัตรว่าดี! จริง? หรือ แกงกัน!!',
        },
        {
          image: '/ratcha/youtube-review-2.jpg',
          url: 'https://www.youtube.com/watch?v=D-KXH4pz5qo',
          title:
            '[James Badbitch] "รีวิวตามคำขอ" เซรั่มไฮยาน้องฉัตร หน้าฉ่ำ หน้าขาวใส ชุ่มชื่น หน้าขาว',
        },
        {
          image: '/ratcha/youtube-review-4.jpg',
          url: 'https://www.youtube.com/watch?v=DQP-gvN6DSE&t=199s',
          title:
            '[Minimayy Blog] VC Vit C น้องฉัตร ดีกว่าที่คิด คาดหวังผลได้จริง!',
        },
      ],
    },
  };
};
