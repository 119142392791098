export const RTCSET11 = () => ({
  url: '/product/set-11',
  title: 'ชุดผิวใส มีออร่า 2 เดือน',
  brand: 'RATCHA',
  sku: 'RTCSET11',
  totalSale: 3975,
  price: 8440,
  offerPrice: 11290,
  images: ['/product/rtcset11.jpg'],
  product:
    'ชุดผิวใส มีออร่า 2 เดือน: เซรั่มไฮยา 5 + เซรั่่มวิตซี 5 + วิตซี ยูวี โพรเทคชั่น 1 + วิตซีวิปโฟม 1 +ไฟเบอร์ 3 + พรีนคอลลาเจน 6',
  shortDescription: null,
  description: (
    <div class="mb-2 text-baseColor">
      <p>ชุดผิวใส มีออร่า 2 เดือน ประกอบไปด้วย</p>
      <ul class="list-disc mt-4 ml-10 text-baseColor">
        <li>เซรั่มไฮยารัชชา 5 ขวด</li>
        <li>เซรั่มวิตซี 5 ขวด</li>
        <li>วิตซี ยูวี โพรเทคชั่น 1 หลอด</li>
        <li>วิตซีวิปโฟม 1 หลอด</li>
        <li>เรนาต้าไฟเบอร์ 3 กล่อง</li>
        <li>พรีนคอลลาเจน 6 กล่อง</li>
      </ul>
    </div>
  ),
  options: null,
  guarantee: {
    title: 'คุณภาพจากรัชชา',
    description: (
      <div>
        ผลิตภัณฑ์คุณภาพจากรัชชา ได้มาตรฐานอย. พร้อมผ่านการรับรองคุณภาพ GMP
        ผลิตโดยโรงงานมาตรฐาน
      </div>
    ),
  },
  ingredients: null,
  ingredientSummary: null,
  fdaId: null,
  weight: null,
  faqs: [
    {
      question: 'วิธีใช้ให้เห็นผลลัพธ์',
      answer: (
        <div class="grid md:grid-cols-2">
          <div class="mb-4">
            <span class="font-bold">ตอนเช้า</span>
            <ul class="list-decimal mt-2 ml-6">
              <li>ล้างหน้าให้สะอาดด้วยวิตซีวิปโฟม</li>
              <li>ใช้ถุงตีวิป เพื่อช่วยผลัดเซลล์ผิว</li>
              <li>หยดเซรั่มไฮยา 2-3 หยด แล้วทาให้ทั่วใบหน้า</li>
              <li>รอ 1-2 นาที เพื่อรอให้เนื้อเซรั่มซึมลงผิว</li>
              <li>จากนั้นจึงทาเซรั่มวิตซี</li>
              <li>และตามด้วยวิตซี ยูวี โพรเทคชั่น เพื่อปกป้องผิวจากแสงแดด</li>
              <li>ชงพรีนคอลลาเจนกับน้ำสะอาดแล้วดื่มก่อนอาหารเช้า</li>
            </ul>
          </div>
          <div>
            <span class="font-bold">ตอนกลางคืน</span>
            <ul class="list-decimal mt-2 ml-6">
              <li>ล้างหน้าให้สะอาดด้วยวิตซีวิปโฟม</li>
              <li>ใช้ถุงตีวิป เพื่อช่วยผลัดเซลล์ผิว</li>
              <li>หยดเซรั่มไฮยา 2-3 หยด แล้วทาให้ทั่วใบหน้า</li>
              <li>รอ 1-2 นาที เพื่อรอให้เนื้อเซรั่มซึมลงผิว</li>
              <li>จากนั้นจึงทาเซรั่มวิตซี</li>
              <li>ชงไฟเบอร์กับน้ำสะอาดแล้วดื่มก่อนเข้านอน</li>
            </ul>
          </div>
        </div>
      ),
    },
  ],
  videoContent: {
    title: 'ผิวนุ่ม ชุ่ม ฟู เด้ง',
    subtitle:
      'ด้วยอนุภาค Oilgo Hyaluronic Acid ซึมลึกถึงชั้นใต้ผิว คงความชุ่มชื้นได้ยาวนาน',
    url: 'https://www.youtube.com/watch?v=JWxO2nzSiZM',
  },
  disclaimer: null,
  reviews: null,
  productsIncluded: [
    'RTCHY5',
    'RTCVC5',
    'RTCSB1',
    'RTCWF1',
    'RNTFX3',
    'PRECL6',
  ],
  relateProducts: [
    'RTCSET6',
    'RTCSET7',
    'RTCSET8',
    'RTCSET9',
    'RTCSET10',
    'RTCSET1',
    'RTCSET12',
    'RTCSET13',
    'RTCSET14',
    'RTCSET2',
    'RTCSET3',
    'RTCSET4',
    'RTCSET5',
  ],
  upsellProducts: ['GLRCL1', 'GLRBS1'],
});
