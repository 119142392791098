import { Link } from 'preact-router/match';

export const GLRCL1 = () => {
  return {
    url: '/product/collagen',
    title: 'กลอรี่คอลลาเจน',
    brand: 'GLORY',
    sku: 'GLRCL1',
    totalSale: 132751,
    price: 420,
    offerPrice: 590,
    images: ['/product/GLRCL1.jpg'],
    product: 'กลอรี่คอลลาเจน 1 ซอง',
    shortDescription: 'คอลลาเจนบำรุงผิวใส มีออร่า เนียนนุ่ม',
    description: (
      <div class="mb-2 text-baseColor">
        <p>
          Glory Collagen คอลลาเจนไดเปปไทด์จากปลาทะเลน้ำลึก ช่วยลดสิว ลดรอยแดง
          บำรุงผิวใส และนุ่มลื่น
        </p>
        <p class="mt-2">
          และเพื่อผลลัพธ์ที่ดีขึ้น ควรทานเป็นประจำอย่างต่อเนื่อง
          ควบคู่กับผลิตภัณฑ์{' '}
          <Link
            href="/product/tomato-vit-c"
            class="font-bold text-primary hover:text-primaryLight"
          >
            Tomato Vit C
          </Link>
          {', '}
          <Link
            href="/product/scrub"
            class="font-bold text-primary hover:text-primaryLight"
          >
            Scrub
          </Link>
          {', '}และ{' '}
          <Link
            href="/product/body-serum"
            class="font-bold text-primary hover:text-primaryLight"
          >
            Body Serum
          </Link>
        </p>
        <ul class="list-disc mt-4 ml-10 text-baseColor">
          <li>คอลลาเจนไดเปปไทด์บริสุทธิ์ 454 มก. / แคปซูล</li>
          <li>ผิวกระจ่างใส มีออร่า</li>
          <li>ป้องกันสิว ผิวแข็งแรง</li>
          <li>ผิวเรียบเนียน นุ่มลื่น น่าสัมผัส</li>
          <li>ลดเลือนริ้วรอย</li>
          <li>รูขุมขนกระชับ ผิวชุ่มชื้น อิ่มน้ำ</li>
          <li>เล็บ ผม กระดูกแข็งแรง</li>
        </ul>
      </div>
    ),
    options: null,
    guarantee: {
      title: 'คุณภาพจากกลอรี่',
      description: (
        <div>
          เพราะความสวยสร้างได้
          กลอรี่ผลิตภัณฑ์คุณภาพที่ออกแบบมาเพื่อให้คุณมีผิวสวยใส ไร้สิว
          จากภายในสู่ภายนอก
        </div>
      ),
    },
    ingredients: [
      {
        image: '/ingredient/collagen.png',
        title: 'Collagen Di-Peptide (คอลลาเจนไดเปปไทด์)',
        description: (
          <div>
            เป็นคอลลาเจนมีขนาดโมเลกุลเฉลี่ยเล็กมากทำให้ไม่จำเป็นต้องถูกย่อยที่กระเพาะอาหาร
            แต่จะถูกลำเลียงและดูดซึมที่ลำไส้เล็กเข้าสู่กระแสเลือด และด้วย
            Technology Target Boosting
            ซึ่งเป็นเทคโนโลยีเฉพาะที่ทำให้คอลลาเจนไดเปปไทด์เข้าสู่เซลล์ผิวหนังได้โดยตรง
            ทำให้สามารถซ่อมแซมและกระตุ้นการสร้างเซลล์ผิวได้อย่างรวดเร็ว
          </div>
        ),
      },
    ],
    ingredientSummary: (
      <div>
        <ul>
          <li>Fish Collagen Di-Peptide / คอลลาเจนไดเปปไทด์จากปลา 454 มก</li>
        </ul>
      </div>
    ),
    fdaId: '10-1-2096050093',
    weight: '33 กรัม. 60 แคปซูล',
    faqs: [
      {
        question: 'คอลลาเจน 1 ซองมีกี่แคปซูล',
        answer: <div>1 ซองมี 60 แคปซูล ทานได้ 30 วัน</div>,
      },
      {
        question: 'วิธีทานคอลลาเจนให้เห็นผล',
        answer: (
          <div>
            <div>
              ทานติดต่อกันทุกวัน วันละ 2 แคปซูล หลังตื่นนอนหรือก่อนอาหาร 30 นาที
            </div>
            <div class="mt-2">
              และเพื่อผลลัพธ์ที่ดีขึ้น ควรทานกลอรี่คอลลาเจนคู่กับโทเมโท่วิตซี
            </div>
          </div>
        ),
      },
      {
        question: 'กลอรี่ปลอดภัยไหม มีอย.หรือไม่',
        answer: (
          <div>
            <div>
              สินค้าทุกชิ้นของกลอรี่ ผลิตโดยโรงงานมาตรฐาน ปลอดภัย และมีอย.
            </div>
            <div class="mt-2">
              เลขที่อย.: 10-1-20960-5-0093 (คอลลาเจน)
              <br />
              เลขที่อย.: 10-1-20960-5-0112 (โทเมโท่วิตซี)
              <br />
              เลขที่อย.: 10-1-63000-3-2598 (สครับ)
              <br />
              เลขที่อย.: 11-1-64000-0-2539 (บอดี้เซรั่ม)
              <br />
            </div>
          </div>
        ),
      },
      {
        question: 'คนแพ้อาหารทะเลทานได้ไหม',
        answer: (
          <div>
            <div>ถ้าแพ้อาหารทะเลแต่ไม่ได้แพ้ปลาทะเล สามารถทานได้</div>
          </div>
        ),
      },
      {
        question: 'กลอรี่ช่วยเรื่องอะไรบ้าง',
        answer: (
          <div>
            <div>
              กลอรี่คอลลาเจนช่วยเรื่องสิว รอยแดง ช่วยให้ผิวใส และนุ่มลื่นขึ้น
            </div>
          </div>
        ),
      },
      {
        question: 'ทำไมต้องทานคอลลาเจนคู่กับวิตามินซี',
        answer: (
          <div>
            เพราะวิตามินซีช่วยให้คอลลาเจนดูดซึมในร่างกายได้ดีขึ้น
            ดังนั้นควรทานกลอรี่คอลลาเจน คู่กับโทเมโท่วิตซี
          </div>
        ),
      },
      {
        question: 'ต้องทานติดต่อกันกี่วัน',
        answer: (
          <div>
            แนะนำให้ทานกลอรี่คอลลาเจนคู่กับโทเมโท่วิตซีเป็นประจำทุกวัน
            หรืออย่างน้อยติดต่อกัน 2-3 เดือนขึ้นไป ถึงจะเห็นผลลัพธ์ชัดเจน
          </div>
        ),
      },
      {
        question: '1 แคปซูล มีกี่แคลลอรี่',
        answer: <div>Glory Collagen 1 แคปซูล = 3.179 แคลลอรี่</div>,
      },
    ],
    videoContent: {
      title: 'สิวหาย ออร่ากระจาย',
      subtitle:
        'หมดปัญหาสิว ผิวโกลว์สุด เคล็ดลับทำให้หน้าเนียน ผิวดี ด้วย Glory Collagen',
      url: 'https://www.youtube.com/watch?v=ZPLebbzNN0Y',
    },
    disclaimer: (
      <div>
        1. ใช้สำหรับภายนอกเท่านั้น
        <br />
        2. หากเกิดการระคายเคือง ควรหยุดใช้และปรึกษาแพทย์หรือเภสัชกร
      </div>
    ),
    reviews: [
      {
        image: '/glorycollagen/rv-twitter-1.jpg',
        review: (
          <div>
            จัดคอลลาเจนของ Glory มาอีกแบรนด์ ตัวนี้เป็นคอลลาเจน Di-Peptide นะจ๊ะ
            หลังจากที่ลองไปอาทิตย์นึง
            คือรู้สึกได้ว่าผิวมันดูใสและนุ่มขึ้นกว่าเดิมอ่ะ เป็นดี!
            แนะนำว่าให้ทานคู่กับวิตซีด้วย ช่วยให้เห็นผลไวขึ้นนะ
          </div>
        ),
        name: 'คุณออย',
        product: 'GLRCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-2.jpg',
        review: (
          <div>
            สองสามอาทิตย์ก่อนออกข้างนอกบ่อย ทำให้โดนแดดตลอด
            ผิวคล้ำไปช่วงนึงเลยแหละ แต่เริ่มกิะคอลลาเจนแบรนด์ Glory มาสักพัก
            คนก็เริ่มทักว่าทำไมผิวใส ใสขึ้นจริงๆ
            มุกทานตัวนี้ตอนเช้าเม็ดเดียวเองนะ เป็นแบรนด์ที่กินแล้วเห็นผลไวมาก
            เลิฟเลย
          </div>
        ),
        name: 'คุณมุก',
        product: 'GLRCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-3.jpg',
        review: (
          <div>
            เมื่อก่อนเริมผิวคล้ำมากแหละเพราะไม่ค่อยดูแลตัวเอง
            อย่างที่บอกช่วงนี้หันมาดูแลตัวเองโดยเฉพาะเรื่องผิว
            ผิวเราไม่ได้ขาวแต่ใสขึ้น ได้เท่านี้ก็คือดีใจสุดแล้ว
            เจอใครก็บอกว่าผิวใสขึ้น ปลื้มมม แนะนำคอลลาเจนตัวนี้เลยงับ "GLORY"
          </div>
        ),
        name: 'คุณจ๊ะ',
        product: 'GLRCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-4.jpg',
        review: (
          <div>
            อยากผิวสวยแต่ไม่ชอบกินน้ำมะเขือเทศ ต้องลอง Glory Tomato Vit C กินไป
            1 สัปดาห์ วันละ 2 เม็ด ตอนเช้า รู้สึกได้เลยว่าผิวใสขึ้นอ่ะ
            แถมยังช่วยเสริมสร้างภูมิคุ้มกันให้เราในช่วงนี้ด้วย
          </div>
        ),
        name: 'คุณตัง',
        product: 'GLRCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-5.jpg',
        review: (
          <div>
            ปกติไม่ค่อยกินอาหารเสริม แต่อยากลอง อยากผิวสวยๆ
            แต่กินมะเขือเทศไม่ได้เลย ทั้งแบบลูก แบบน้ำดอยคำ
            อันนี้กินง่ายดีของแบรนด์ Glory เป็นมะเขือเทศอัดแคปซูล
            ได้กินวิตามินซี กินวันละ 1 แคป ผิวกระจ่างใส ขาวขึ้นนะ
          </div>
        ),
        name: 'คุณขนมผิง',
        product: 'GLRCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-6.jpg',
        review: (
          <div>
            ตอนแรกซื้อมาลอง เห็น packaging สวยดี แต่เห้ย!! เจอตัวนี้อาทิตย์เดียว
            ผิวใสจนเพื่อนทัก ทำกิจกรรมกลางแจ้งก็ไม่กลัวเลย
          </div>
        ),
        name: 'คุณบรัช',
        product: 'GLRCL1',
      },
      {
        image: '/glorycollagen/rv-twitter-9.jpg',
        review: (
          <div>
            เซ็ทคู่ดูแลผิวค้าบ Tomato Vit C + Collagen Dipeptide ใครอยากผิวสวย
            ขาวใส อยากให้ลอง อย่างละ 2 เม็ดทุกเช้า คือผิวดีมาก เลิฟเลย
          </div>
        ),
        name: 'คุณออนนี',
        product: 'GLRCL1',
      },
    ],
    productsIncluded: null,
    relateProducts: ['RTCHY1', 'RTCVC1', 'RTCSB1', 'RTCWF1', 'PRECL1'],
    upsellProducts: ['RTCHY1', 'RTCVC1', 'RTCWF'],
  };
};
