export const RTCSB1 = () => {
  return {
    url: '/product/vc-uv',
    title: 'วิตซี ยูวี โพรเทคชั่น',
    brand: 'RATCHA',
    sku: 'RTCSB1',
    totalSale: 42709,
    price: 380,
    offerPrice: 500,
    images: ['/product/ratcha-uv-1.jpg'],
    product: 'วิตซี ยูวี โพรเทคชั่น 1 หลอด',
    shortDescription: 'ผลิตภัณฑ์เนื้อบางเบา ปกป้องผิวจากแสงแดด',
    description: (
      <div class="mb-2 text-baseColor">
        <p>ผลิตภัณฑ์ปกป้องผิวจากแสงแดด บำรุงผิวให้กระจ่างใส</p>
        <p class="mt-2">
          เนื้อครีมบางเบา ซึมไว ไม่เหนียวเหนอะหนะ และ VC UV Protection
          ให้มากกว่าการปกป้องผิวจากแสงแดด
          ด้วยส่วนผสมจากสารสกัดที่จะช่วยบำรุงผิวพรรณ มีอนุพันธ์ (GOA)
          วิตามินซีอนุพันธ์ที่มีความเสถียรสูงที่สุด นำเข้าจากประเทศญี่ปุ่น
        </p>
        <ul class="list-disc mt-4 ml-10 text-baseColor">
          <li>ดูแลและปกป้องผิวจากแสงแดด</li>
          <li>
            มีคุณประโยชน์ในการช่วยฆ่าเชื้อแบคทีเรีย ที่เกิดขึ้นได้จากเหงื่อ
            หรือมลพิษต่าง ๆ
          </li>
          <li>ลดอาการแพ้ ผดผื่นคัน การอุดตัน และการเกิดสิว</li>
          <li>ช่วยยับยั้งการสร้างเม็ดสีผิว ฝ้า กระ แลดูจางลง</li>
        </ul>
      </div>
    ),
    options: null,
    guarantee: {
      title: 'คุณภาพจากรัชชา',
      description: (
        <div>
          ผลิตภัณฑ์คุณภาพจากรัชชา ได้มาตรฐานอย. พร้อมผ่านการรับรองคุณภาพ GMP
          ผลิตโดยโรงงานมาตรฐาน
        </div>
      ),
    },
    ingredients: [
      {
        image: '/ingredient/rcvc.png',
        title: 'Caprylyl 2-Glyceryl Ascorbate',
        description: (
          <div>
            เป็นวิตามินซีนวัตกรรมใหม่
            โดยมีคุณสมบัติที่พิเศษกว่าวิตามินซีชนิดอื่นๆ มีความเสถียรสูง
            ไม่เปลี่ยนสีเมื่อสัมผัสอากาศหรือแสง
          </div>
        ),
      },
      {
        image: '/ingredient/rcyeast.png',
        title: 'Yeast Amino Acids ',
        description: (
          <div>
            โปรตีนสกัดจากยีส สายพันธุ์พิเศษ ที่ถูกพัฒนาขึ้น
            เพื่อให้โปรตีนที่เหมาะสมกับการให้ความกระจ่างใสแก่ผิว
          </div>
        ),
      },
      {
        image: '/ingredient/vchbtf.png',
        title: 'Hydrolyzed Verbascum Thapsus Flower',
        description: (
          <div>
            เป็นนวัตกรรมที่ได้รับการคิดค้นเพื่อการปกป้องผิว
            โดยมีคุณสมบัติช่วยดูดแสงช่วงความยาวคลื่นประมาณ 400-800 nm
            หรือที่รู้จักกันว่า visible Light
            ซึ่งเราไม่สามารถมองเห็นได้ด้วยตาเปล่าและมีอยู่รายรอบตัว
            แล้วเปลี่ยนเป็นแสงสีฟ้าที่มีความยาวคลื่น 420-460 nm
            สะท้อนตัดกับสีผิวของร่างกาย ทำให้ผิวเกิดเปล่งประกายออร่า
            ขาวกระจ่างใสทันที เมื่อผิวสัมผัสกับแสงแดด
            นอกจากนี้ยังช่วยทำลายเชื้อแบคทีเรียในชั้นผิว
            ซึ่งเป็นสาเหตุของการเกิดสิวอักเสบได้อีกด้วย
          </div>
        ),
      },
      {
        image: '/ingredient/vccitric.png',
        title: 'Citric Acid',
        description: (
          <div>
            มีส่วนช่วยในการลอกผิวหนังที่มีความหมองคล้ำ หรือรอยดำรอยแดงจากสิว
            ทำให้รอยดูจางลง
          </div>
        ),
      },
      {
        image: '/ingredient/vcmorinda.png',
        title: 'Morinda Citrifolia Extract',
        description: (
          <div>
            มีฤทธิ์ช่วยบำรุงผิวให้มีความสมดุล มีสารต้านอนุมูลอิสระสูง
            ช่วยสมานแผล
          </div>
        ),
      },
    ],
    ingredientSummary: (
      <div>
        Aqua, Ethylhexyl Methoxy Cinnamate, Octocrylene, Polyglyceryl-3
        Methyglucose Distearate, Butyl Methoxy Dibenzoylmethane, Chlorphenesin,
        Bisabolol, Glycerylascorbate, Butylene Glycol, Yeast Amino Acids,
        Hydrolyzed Verbascum Thapsus Flower, Citric Acid, Morinda
        Citrifoliaextract, Sodium Benzoate
      </div>
    ),
    fdaId: '10-1-6100024751',
    weight: '10 มล.',
    faqs: [
      {
        question: 'วิธีใช้วิตซี ยูวี โพรเทคชั่นให้เห็นผล',
        answer: (
          <div>
            <div>
              ลูบไล้ VC UV Protection SPF40 PA+++ ทั่วบริเวณใบหน้า และลำคอ
              ก่อนออกจากบ้านทุกเช้า หรือก่อนออกไปเจอแสงแดด
            </div>
            <div class="mt-2">
              และเพื่อผลลัพธ์ที่ดีขึ้น ควรใช้เป็นประจำอย่างต่อเนื่อง
              ควบคู่กับผลิตภัณฑ์ วิตซีวิปโฟม และเซรั่มไฮยารัชชา
            </div>
          </div>
        ),
      },
      {
        question: 'ผลิตภัณฑ์รัชชาปลอดภัยไหม มีอย.หรือไม่',
        answer: (
          <div>
            <div>
              สินค้าทุกชิ้นของรัชชาปลอดภัย ได้มาตรฐานอย.พร้อมผ่านการรับรองคุณภาพ
              GMP ผลิตโดยโรงงานมาตรฐาน
            </div>
            <div class="mt-2">
              เลขที่ใบรับจดแจ้ง: 10-1-6200030338 (เซรั่มไฮยารัชชา)
              <br />
              เลขที่ใบรับจดแจ้ง: 10-1-6100024751 (เซรั่มวิตซี)
              <br />
              เลขที่ใบรับจดแจ้ง: 10-1-6010063568 (วิตซี ยูวี โพรเทคชั่น)
              <br />
              เลขที่ใบรับจดแจ้ง: 10-1-6100061313 (วิตซีวิปโฟม)
            </div>
          </div>
        ),
      },
      {
        question: 'เป็นสิวอยู่ใช้ได้ไหม ',
        answer: (
          <div>
            เป็นสิวก็สามารถใช้ได้ค่ะ เพราะมีส่วนผสมของ Amitose GOA
            มีคุณสมบัติในการยับยั้งการเจริญเติบโตของเชื้อแบคทีเรียต่างๆ
            ที่เป็นสาเหตุของการเกิดสิว จะช่วยให้สิวค่อยๆ ยุบและแห้งไป
            และป้องกันไม่ให้สิวใหม่เกิดขึ้นด้วยค่ะ
          </div>
        ),
      },
      {
        question: 'ผิวแพ้ง่าย ใช้ได้ไหม ',
        answer: (
          <div>
            ผิวแพ้ง่ายสามารถใช้ได้เลยค่ะ เพราะผลิตภัณฑ์ของเรา ไม่แต่งสี
            ไม่แต่งกลิ่น ลดความระคายเคือง และยังมีส่วนผสมของ Allantoin
            ช่วยในการปกป้องผิวหนังป้องกันอาการแพ้ และช่วยให้ผิวมีสุขภาพดี
          </div>
        ),
      },
      {
        question: 'วิตซี ยูวี โพรเทคชั่น ช่วยป้องกันฝ้า กระ ได้อย่างไร',
        answer: (
          <div>
            ด้วยสารสกัดจากดอกมัลเลน (Mullein Flower) นวัตกรรมจากประเทศอิตาลี
            ทำหน้าที่เสมือน LED Light Therapy ช่วยดูดกลืนแสงช่วง Visible Light
            แล้วคลายพลังงานช่วงแสงสีฟ้า สะท้อนตัดกับสีผิว
            จึงทำให้ผิวหน้ากระจ่างใสขึ้นทันที และช่วยยับยั้งการสร้างเม็ดสี ฝ้า
            กระ แลดูจางลง
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'ดูดซับวิตามิน บำรุงผิวจากแสงแดด',
      subtitle:
        'มากกว่าการปกป้องผิว ด้วยกันแดดนวัตกรรมใหม่ วิตซี ยูวี โพรเทคชั่น ',
      url: 'https://www.youtube.com/watch?v=GuVIJc_uCS4',
    },
    disclaimer: (
      <div>
        1.
        การใช้เครื่องสำอางที่มีสารป้องกันแสงแดดเป็นเพียงวิธีหนึ่งที่จะช่วยลดความเสี่ยงจากอันตรายจากแสงแดด
        <br />
        2. อ่านวิธีใช้ให้ละเอียดและปฏิบัติตามอย่างเคร่งครัด
        <br />
        3. หากใช้แล้วมีความผิดปกติใดๆ เกิดขึ้นต้องหยุดใช้
        และปรึกษาแพทย์หรือเภสัชกร
      </div>
    ),
    reviews: [
      {
        image: '/ratcha/review-uv-1.1.jpg',
        review: (
          <div>
            เลือกประเทศเกิดไม่ได้ แต่แพรเลือกครีมกันแดดได้ "ครีมกันแดดรัชชา"
            ตอบโจทย์อากาศเมืองไทย ทาแล้วไม่เหนียว ไม่รุงรังหน้า ซึมไว
            สบายผิวสุดๆ
          </div>
        ),
        name: 'คุณแพร',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-2.1.jpg',
        review: (
          <div>
            คุ้มเกินคุ้ม ทาท้าแดดด้วยกันแดดวิตซียูวีโพเทคชั่น บีบจนคอหลอดหัก
            ตัดซะเลย ค้างหลอดใช้ได้ต่อ 2 วัน
          </div>
        ),
        name: 'คุณปูน้อย',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-3.1.jpg',
        review: (
          <div>
            กันแดดวิตซีน้องฉัตร บำรุงไปพร้อมกับการป้องกันผิวจากแสงแดด และมลพิษ
            ทำไมนาทีนี้ ทุกเสียงต่างชื่นชม ทุกคนต่างเทใจให้กันแดดพลัสวิตามินซี
            ก็เพราะมันเริ่ดในทุกจุด เนียน บางเบา ปกปิด ให้ความขาวกระจ่างใส
            ละลายลงผิว
          </div>
        ),
        name: 'คุณโจ',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-4.1.jpg',
        review: (
          <div>
            ใช้ลงพื้นก่อนแต่งหน้า ช่วยให้เครื่องสำอางค์ติดทน แต่งหน้าลุคไหนๆ
            ก็สวยเป๊ะ
          </div>
        ),
        name: 'คุณเบลล์',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-5.1.jpg',
        review: (
          <div>
            ไม่กลัวแก่ ไม่แคร์แดด ช่วยให้ริ้วรอยก่อนวัยดูจางลง
            ให้ผิวมีความยืดหยุ่น เด้งฟู สดใส ผิวหน้าดูกระจ่างใส ไบรท์ ออร่า
            แลดูเด็กลง
          </div>
        ),
        name: 'คุณสวย',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-6.1.jpg',
        review: (
          <div>
            กันแดดของรัชชาตัวนี้ มีส่วนผสมของ Vit C ด้วย ช่วยป้องกันแสงแดด
            SPF40pa+++ เนื้อกันแดดบางเบา ซึมเร็ว
            ไม่มีสีทาแล้วหน้าไม่วอก(ชอบตรงนี้) ไม่เหนอะผิวดี หลังทาหน้าแอบผ่องๆ
            ฉ่ำๆ ขึ้นนิดนึงด้วย
          </div>
        ),
        name: 'คุณหมวย',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-7.1.jpg',
        review: (
          <div>
            เป็นกันแดดที่บางเบา ทาแล้วกลืนไปกับผิว หลังทาแล้วผิวดูชุ่มชื้นหน่อยๆ
            เหมาะแก่การลงรองพื้น ค่อนข้างคุมมัน
            หลังใช้รู้สึกเห็นผลเรื่องไม่อุดตันผิว รอยสิวดูจางไวนิดหนึ่ง
          </div>
        ),
        name: 'คุณเจน',
        product: 'RTCSB1',
      },
      {
        image: '/ratcha/review-uv-8.1.jpg',
        review: (
          <div>
            กรี๊ด! ทำไมชั้นพึ่งมาเจอ ชอบมากกันแดดอันนี้ เนื้อเจลบางๆ ไม่มัน
            สิวไม่ขึ้นเลย รอยสิวจางลงอยู่นะ ไม่ผสมรองพื้น แต่รู้สึกผิวใส วาวๆ
            ขึ้นอ่ะ ชอบตรงนี้555
          </div>
        ),
        name: 'HowtoPerfect',
        product: 'RTCSB1',
      },
    ],
    relateProducts: [
      'RTCSET1',
      'RTCSET12',
      'RTCSET13',
      'RTCSET14',
      'RTCSET2',
      'RTCSET3',
      'RTCSET4',
      'RTCSET5',
      'RTCSET6',
      'RTCSET7',
      'RTCSET8',
      'RTCSET9',
      'RTCSET10',
      'RTCSET11',
    ],
    upsellProducts: ['RTCHY1', 'RTCVC1', 'RTCCR1'],
  };
};
