export const PRECL1 = () => {
  return {
    url: '/product/prene-collagen',
    title: 'พรีนคอลลาเจน',
    brand: 'PRENE',
    sku: 'PRECL1',
    totalSale: 11082,
    price: 490,
    offerPrice: 690,
    images: [
      '/product/PRECL1-3.jpg',
      '/product/prene-collagen-1.jpg',
      '/product/prene-collagen-2.jpg',
      '/product/prene-collagen-3.jpg',
      '/product/prene-collagen-4.jpg',
      '/product/prene-collagen-5.jpg',
      '/product/prene-collagen-6.jpg',
      '/product/prene-collagen-7.jpg',
      '/product/prene-collagen-8.jpg',
      '/product/prene-collagen-9.jpg',
    ],
    product: 'พรีนคอลลาเจน 1 กล่อง',
    shortDescription: 'อาหารเสริมบำรุงผิวคอลลาเจน',
    description: (
      <div class="mb-2 text-baseColor">
        <p>
          พรีนคอลลาเจน ผลิตภัณฑ์เสริมอาหารบำรุงผิว สวยจากภายในสู่ภายนอก
          ด้วยสารสกัดในปริมาณ Active Dose ช่วยให้ผิวนุ่มลื่น ฟู
          เต่งตึงจนคุณสัมผัสได้
        </p>
        <ul class="list-disc mt-4 ml-10 text-baseColor">
          <li>คอลลาเจนไดเปปไทด์ 10,000 มก.</li>
          <li>สารสกัดจากทับทิม 700 มก.</li>
          <li>สารสกัดจากส้มสีเลือด 300 มก.</li>
          <li>ผิวใสอิ่มน้ำ เด้งฟู</li>
          <li>ปรับสีผิวให้สม่ำเสมอ</li>
          <li>บำรุงข้อ กระดูก ให้แข็งแรง</li>
          <li>บำรุงเล็บ ผม ให้แข็งแรง</li>
          <li>ช่วยลดต้นเหตุของการเกิดสิว</li>
          <li>หน้าเด็ก ดูอ่อนกว่าวัย</li>
          <li>อร่อย ไม่คาว ละลายน้ำง่าย</li>
          <li>ไม่มีน้ำตาล ไม่มีแป้ง ไม่อ้วน</li>
        </ul>
      </div>
    ),
    options: [
      {
        sku: 'PRECL1',
        title: (
          <div>
            1 กล่อง <del>฿690</del>
          </div>
        ),
        price: 490,
      },
      {
        sku: 'PRECL3',
        title: (
          <div>
            3 กล่อง <del>฿2,070</del>
          </div>
        ),
        price: 1400,
      },
      {
        sku: 'PRECL6',
        title: (
          <div>
            6 กล่อง <del>฿4,140</del>
          </div>
        ),
        price: 2800,
      },
    ],
    guarantee: {
      title: 'คอลลาเจนไดเปปไทด์',
      description: (
        <div>
          <div>
            เมื่อคุณอายุ 25 ปีขึ้นไป ร่างกายจะผลิตคอลลาเจนได้ลดลงปีละ 1.5%
            ทำให้ผิวพรรณคุณหย่อนคล้อย มีริ้วรอย แก่ง่าย ใน 1
            วันร่างการต้องการคอลลาเจนประมาณ 5,000 - 10,000 มก.
            ซึ่งอาหารที่คุณรับประทานทั่วไปอาจมีปริมาณคอลลาเจนไม่เพียงพอต่อความร่างกาย
            จึงทำให้แก่เร็ว ปวดข้อและกระดูก
          </div>
          <div class="mt-2">
            ดังนั้นการรับประทานผลิตภัณฑ์เสริมอาหารที่มีส่วนผสมของคอลลาเจน 10,000
            มก. จึงช่วยให้ร่างกายของคุณมีปริมาณคอลลาเจนที่เพียงพอ
            ช่วยให้ผิวเต่งตึง ริ้วรอยลดลง และดูอ่อนกว่าวัย
          </div>
        </div>
      ),
    },
    ingredients: [
      {
        image: '/ingredient/pcal.png',
        title: 'Collagen Dipeptide (คอลลาเจนไดเปปไทด์จากปลา)',
        description: (
          <div>
            เป็นคอลลาเจนมีขนาดโมเลกุลเฉลี่ยเล็กมากทำให้ไม่จำเป็นต้องถูกย่อยที่กระเพาะอาหาร
            แต่จะถูกลำเลียงและดูดซึมที่ลำไส้เล็กเข้าสู่กระแสเลือด และด้วย
            Technology Target Boosting
            ซึ่งเป็นเทคโนโลยีเฉพาะที่ทำให้คอลลาเจนไดเปปไทด์เข้าสู่เซลล์ผิวหนังได้โดยตรง
            ทำให้สามารถซ่อมแซมและกระตุ้นการสร้างเซลล์ผิวได้อย่างรวดเร็ว
          </div>
        ),
      },
      {
        image: '/ingredient/pomegranate.png',
        title: 'Pomegranate Extract (สารสกัดจากทับทิม)',
        description: (
          <div>
            สารสกัดทับทิม เป็น สารต้านอนุมูลอิสระที่ดีมากต่อผิวพรรณ
            มีประสิทธิภาพในการลดเลือนริ้วรอย จุดด่างดำ
            พร้อมทั้งช่วยยับยั้งการสร้างเม็ดสีผิว ช่วยให้ผิวแลดูกระจ่างใส
          </div>
        ),
      },
      {
        image: '/ingredient/melon.png',
        title: 'Melon (ผงเมลอน)',
        description: (
          <div>
            เป็นตัวทำลายอนุมูลอิสระที่เกิดจากการเผาผลาญภายในเซลล์ ป้องกันทั้ง
            UVA และ UVB จากแสงแดดที่ก่อให้เกิดริ้วรอยและผิวคล้ำเสีย แห้งกร้าน
            เสริมสร้างและฟื้นฟูผิวพรรณให้เปล่งปลั่ง เพิ่มความชุ่มชื้น
            ฟื้นฟูผิวให้กระจ่างใส ได้ดีกว่ากลูต้า ถึง 10 เท่า
            ฟื้นฟูผิวจากปัญหาหลุมสิวให้ตื้นขึ้น ลดรอยดำจากสิว
          </div>
        ),
      },
      {
        image: '/ingredient/peach.png',
        title: 'Peach (ผงพีช)',
        description: (
          <div>
            อุดมไปด้วยวิตามินเอ วิตามินซี นอกจากนี้ยังอุดมด้วยสาร เซราไมด์
            จึงช่วยชะลอวัย มีคุณสมบัติช่วยกระชับกล้ามเนื้อของใบหน้า
            ช่วยป้องกันการหย่อยคล้อยของกล้ามเนื้อ พร้อมทั้งช่วยบำรุงผิวไปในตัว
            ให้ผิวขาวเนียน ชุ่มชื้น ไม่แห้งกร้าน
          </div>
        ),
      },
      {
        image: '/ingredient/bloodorange.png',
        title: 'Blood Orange (ผงส้มสีเลือด)',
        description: (
          <div>
            เป็นสารสกัดสุดยอดแห่งการบำรุงผิวพรรณด้านความขาว
            โดยมีผลการวิจัยสารสกัดส้มแดงทั้งด้านการช่วยลดผิวหมองคล้ำสามารถป้องกันผิวจากรังสี
            UVB ด้านการช่วยลดผดผื่นจากการแพ้แดดและด้านการต่อต้านอนุมูลอิสระ
          </div>
        ),
      },
      {
        image: '/ingredient/emblica.png',
        title: 'Emblica Extract (สารสกัดมะขามป้อม)',
        description: (
          <div>
            อุดมไปด้วย วิตามินซี จากธรรมชาติ ต้านอนุมูลอิสระ และ
            ชะลอการเกิดริ้วรอยก่อนวัย จึงช่วยปกป้องผิวจากการเสื่อมสลาย
            ช่วยชะลอการเกิดริ้วรอยก่อนวัย และ บำรุงผิวพรรณให้ขาว สว่าง
            กระจ่างใสขึ้น
          </div>
        ),
      },
      {
        image: '/ingredient/tomato.png',
        title: 'Tomato (สารสกัดจากมะเขือเทศ)',
        description: (
          <div>
            ช่วยบํารุงผิวพรรณให้ชุ่มชื้น กระจ่างใสอมชมพู
            ช่วยให้ผิวทนต่อการถูกทําลายของแสงแดด ลดการเกิดสิว ต้านอนุมูลอิสระ
            ชะลอความชรา
          </div>
        ),
      },
      {
        image: '/ingredient/ascorbic.png',
        title: 'Ascorbic Acid (Vitamin C)',
        description: (
          <div>
            ทำหน้าที่ช่วยกระตุ้นการผลิตคอลลาเจนที่จะช่วยต่อต้านอนุมูลอิสระและคืนความเฟิร์มกระชับให้ผิว
          </div>
        ),
      },
      {
        image: '/ingredient/q10.png',
        title: 'Coenzyme Q10',
        description: (
          <div>
            โคเอนไซม์คิวเทนเป็นสารต้านอนุมูลอิสระมีความสำคัญอย่างมากต่อการสร้างพลังงาน
            ชะลอการเสื่อมของเซลล์ผิวหนังจากแสงแดด (Photo aging) ลดการเกิดริ้วรอย
          </div>
        ),
      },
      {
        image: '/ingredient/zinc.png',
        title: 'Zinc Glucanate',
        description: (
          <div>
            ช่วยเสริมภูมิคุ้มกัน มีบทบาทในการทำงานร่วมกับบเอนไซม์กว่า 300 ชนิด
            ช่วยในการสร้างดีเอ็นเอ (DNA) สารพันธุกรรมของเซลล์
            และจำเป็นสำหรับการแบ่งเซลล์และการเจริญเติบโต
            เร่งให้แผลทั้งภายในและภายนอกหายเร็วขึ้น
          </div>
        ),
      },
      {
        image: '/ingredient/vitamine.png',
        title: 'Vitamin E Acetate',
        description: (
          <div>
            ลดปัญหาผิวจากการถูกทำร้ายจากอนุมูลอิสระได้ เช่น ผิวไหม้แดด, ริ้วรอย,
            การสร้างเม็ดสีผิวมากผิดปกติ นอกจากนี้ยังสามารถลดเลือนริ้วรอย
            และให้ความชุ่มชื้นผิวได้ดี
          </div>
        ),
      },
      {
        image: '/ingredient/pinebark.png',
        title: 'Pine Bark Extract (สารสกัดเปลือกสน)',
        description: (
          <div>
            ช่วยลดอัตราการเกิดอนุมูลอิสระตัวการหลักการทำลายเซลล์ผิว
            ประกอบกับช่วยในการสร้างเซลล์ผิวใหม่
            และแก้ปัญหาสุขภาพผิวได้อย่างมีประสิทธิภาพ ฝ้า กระ จุดด่างดำ
            ริ้วรอยก่อนวัยอันควร
          </div>
        ),
      },
    ],
    ingredientSummary: (
      <div>
        <ul>
          <li>Fish Collagen Dipeptide / คอลลาเจนไดเปปไทด์จากปลา 10,000 มก.</li>
          <li>Pomegranate Extract / สารสกัดจากทับทิม 700 มก.</li>
          <li>Melon Powder / ผงเมลอน 500 มก.</li>
          <li>Peach Powder / ผงพีช 500 มก.</li>
          <li>Blood Orange Powder / ผงส้มสีเลือด 300 มก.</li>
          <li>Emblica Extract / สารสกัดจากมะขามป้อม 300 มก.</li>
          <li>Ascorbic Acid (Vitamin C) / กรดแอสคอร์บิก (วิตามินซี) 60 มก.</li>
          <li>Coenzyme Q10 / โคเอ็นไซม์คิวเท็น 50 มก.</li>
          <li>Zinc Gluconate / ซิงค์ กลูโคเนต 50 มก.</li>
          <li>Vitamin E Acetate / วิตามินอี อะซิเตท 30 มก.</li>
          <li>Grape Seed Extract / สารสกัดจากเมล็ดองุ่น 30 มก.</li>
          <li>Pine Bark Extract / สารสกัดจากเปลือกสน 30 มก.</li>
        </ul>
      </div>
    ),
    fdaId: '13-1-00457-5-0088',
    weight: '15 กรัม x 10 ซอง',
    faqs: [
      {
        question: 'วิธีทานคอลลาเจนให้เห็นผล',
        answer: (
          <div>
            ฉีกซองคอลลาเจนผสมกับน้ำเปล่าชงให้เข้ากัน
            ดื่มตอนเช้าก่อนอาหารเป็นประจำทุกวัน วันละ 1 ซอง
            เพื่อผลลัพธ์ที่ดีขึ้นควรทานคอลลาเจนติดต่อกันอย่างน้อย 1-3 เดือน
            คู่กับการบำรุงผิวด้วยเซรั่มออร่าทัยและเดย์ครีมออร่าทัย
          </div>
        ),
      },
      {
        question: 'ทานคอลลาเจนเป็นประจำอันตรายไหม',
        answer: (
          <div>
            ไม่อันตราย
            เพราะคอลลาเจนเป็นโปรตีนชนิดหนึ่งที่ช่วยทำให้ผิวพรรณนุ่มลื่น เต่งตึง
            ริ้วรอยลดลง ร่างกายต้องการคอลลาเจนวันละ 5,000 - 10,000 มก.
            ดังนั้นคุณสามารถทานคอลลาเจนได้ทุกวัน
            โดยไม่ต้องกังวลถึงผลข้างเคียงหรืออันตรายใดๆ
          </div>
        ),
      },
      {
        question: 'คอลลาเจนช่วยให้ผิวขาวหรือไม่',
        answer: (
          <div>
            คอลลาเจนไม่ได้ช่วยให้ผิวขาว แต่คอลลาเจนช่วยให้ผิวนุ่มลื่น เต่งตึง
            ริ้วรอยลดลง
            และในพรีนคอลลาเจนจะมีสารสกัดที่ช่วยบำรุงผิวให้ขาวกระจ่างใส
            สีผิวเรียบเนียนสม่ำเสมอ เช่น สารสกัดจากทับทิม ผงส้มสีเลือด
            สารสกัดจากมะเขือเทศ และวิตามินซี ซึ่งจะช่วยในเรื่องดังกล่าวได้
          </div>
        ),
      },
      {
        question: 'ใครบ้างที่ควรหลีกเลี่ยง',
        answer: (
          <div>
            ในพรีนคอลลาเจนมีส่วนผสมของคอลลาเจนไดเปปไทด์จากปลา
            ดังนั้นถ้าคุณแพ้ปลาหรืออาหารทะเลควรหลีกเลี่ยงการทาน
            นอกจากนี้เด็กอายุต่ำกว่า 12
            ปีและสตรีมีครรภ์ควรหลีกเลี่ยงการรับประทาน
          </div>
        ),
      },
      {
        question: 'ทานกี่วันถึงจะเห็นผล',
        answer: (
          <div>
            โดยปกติเมื่อคุณทานพรีนคอลลาเจนจะสังเกตเห็นการเปลี่ยนแปลงได้ดังนี้
            <ul class="list-disc ml-6 my-2">
              <li>7 วัน ผิวจะชุ่มชื่น อิ่มน้ำ หน้าฉ่ำแต่งหน้าติด</li>
              <li>15 วัน ผิวเนียนเด้ง ดูโกล์ว สุขภาพดี เหมือนเพิ่งไปฉีดผิว</li>
              <li>30 วัน รอยแดง รอยสิว ริ้วรอยจางลง สีผิวสม่ำเสมอเห็นได้ชัด</li>
              <li>
                60 วัน สีผิวเรียบเนียนเสมอกัน หน้ากระจ่างใส มีออร่า
                จนคนทักว่าหน้าเด็กลง
              </li>
            </ul>
            ทั้งนี้ผลลัพธ์ที่ได้จะขึ้นอยู่กับแต่ละบุคคล
          </div>
        ),
      },
      {
        question: 'ปลอดภัยมีอย.หรือไม่',
        answer: (
          <div>
            พรีนคอลลาเจนผลิตโดยโรงงานที่ได้มาตรฐานตาม GMP และเลขที่ อย.
            13-1-00457-5-0088
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'พรีนคอลลาเจน',
      subtitle: 'อาหารเสริมบำรุงผิว บำรุงผิวเนียนใสจากภายใน',
      url: 'https://www.youtube.com/watch?v=V61E6H3xj7k',
    },
    disclaimer: (
      <div>
        1. เด็กและสตรีมีครรภ์ ไม่ควรรับประทาน
        <br />
        2. ไม่มีผลในการป้องกันหรือรักษาโรค
      </div>
    ),
    reviews: null,
    productsIncluded: null,
    relateProducts: [
      'RTCHY1',
      'RTCVC1',
      'RTCSB1',
      'RTCWF1',
      'PRECL1',
      'GLRSC1',
      'GLRBS1',
    ],
    upsellProducts: ['RTCHY1', 'RTCVC1', 'RTCSB1'],
  };
};
