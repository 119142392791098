export const RTCHY1 = () => {
  return {
    url: '/product/ratcha',
    title: 'เซรั่มไฮยารัชชา',
    brand: 'RATCHA',
    sku: 'RTCHY1',
    totalSale: 156082,
    price: 490,
    offerPrice: 540,
    images: ['/product/ratcha-hya-1.4.jpg'],
    product: 'เซรั่มไฮยารัชชา 1 ขวด',
    shortDescription: 'เซรั่มเนื้อบางเบา เติมความชุ่มชื่น คงความสมดุล ',
    description: (
      <div class="mb-2 text-baseColor">
        <p>เซรั่มเนื้อบางเบา คงความชุ่มชื่้น ยาวนานกว่า 3 วัน </p>
        <p class="mt-2">
          เซรั่มไฮยาเนื้อบางเบา ไร้สี ไร้กลิ่น
          ผสมผสานการทำงานคู่กันของไฮยาลูรอนิกเอซิด ด้วยโมเลกุล Oligo Hyaluronic
          Acid ซึ่งมีขนาดเล็กมาก สามารถซึมผ่านสู่ผิวได้อย่างรวดเร็ว
          ให้ความชุ่มชื่นกับผิวจากภายในสู่ภายนอก
        </p>
        <ul class="list-disc mt-4 ml-10 text-baseColor">
          <li>ช่วยเพิ่มความชุ่มชื่นให้แก่ผิวได้ยาวนาน</li>
          <li>
            ช่วยลดปริมาณเม็ดสีเมลานินในเซลล์ผิวหนัง ลดเลือนริ้วรอย
            และคงความยืดหยุ่นให้แก่ผิว
          </li>
          <li>ช่วยในการปกป้องผิวหนัง ป้องกันอาการแพ้ ทำให้ผิวสุขภาพดี</li>
          <li>
            ช่วยฟื้นฟูผิวได้อย่างครอบคลุม
            ช่วยลดริ้วรอยและรอยแผลเป็นที่เกิดจากสิว
          </li>
        </ul>
      </div>
    ),
    options: [
      {
        sku: 'RTCHY1',
        title: (
          <div>
            1 ขวด <del>฿540</del>
          </div>
        ),
        price: 490,
      },
      {
        sku: 'RTCHY2',
        title: (
          <div>
            2 ขวด <del>฿1,080</del>
          </div>
        ),
        price: 900,
      },
      {
        sku: 'RTCHY5',
        title: (
          <div>
            5 ขวด <del>฿2,700</del>
          </div>
        ),
        price: 2000,
      },
    ],
    guarantee: {
      title: 'คุณภาพจากรัชชา',
      description: (
        <div>
          ผลิตภัณฑ์คุณภาพจากรัชชา ได้มาตรฐานอย. พร้อมผ่านการรับรองคุณภาพ GMP
          ผลิตโดยโรงงานมาตรฐาน
        </div>
      ),
    },
    ingredients: [
      {
        image: '/ingredient/hhya.png',
        title: 'Hydrolyzed Hyualuronic Acid',
        description: (
          <div>
            มีคุณสมบัติเด่นในด้านการอุ้มน้ำได้ดี ช่วยเพิ่มความยืดหยุ่น
            รักษาความชุ่มชื้น ป้องกันริ้วรอยบนผิวหนัง เช่น
            ริ้วรอยที่ร่องแก้มและบริเวณรอบดวงตา
          </div>
        ),
      },
      {
        image: '/ingredient/whazel.png',
        title: 'Hamamelis Virginiana Extract (Witch Hazel)',
        description: (
          <div>
            ช่วยปกป้องผิวจากมลภาวะต่างๆ ช่วยกระชับรูขุมขน ช่วยสมานผิว
            ปรับให้รูขุมขนค่อยๆตื้นขึ้น ทำให้ผิวหนังเรียบเนียน
            ลดการสร้างน้ำมันใต้ผิวหนัง ขจัดคราบมันส่วนเกินให้กับผิว
            มีฤทธิ์ฆ่าเชื้อแบคทีเรียอ่อนๆ จึงช่วยลดการเกิดสิวได้
          </div>
        ),
      },
      {
        image: '/ingredient/aloe.png',
        title: 'Aloe Barbadensis Leaf Juice',
        description: (
          <div>
            สารสกัดทรงคุณค่าจากว่านหางจระเข้ มีประสิทธิภาพในการบำรุงผิวล้ำลึก
            ยับยั้งการอักเสบของผิวหนัง ลดอาการระคายเคืองจากมลภาวะที่มาทำร้ายผิว
            ปกป้องผิว บรรเทาอาการแสบร้อนของผิวเมื่อต้องออกแดดเป็นเวลานาน
            พร้อมเติมเต็มความชุ่มชื้นให้ผิว
          </div>
        ),
      },
      {
        image: '/ingredient/allan.png',
        title: 'Allantoin',
        description: (
          <div>
            ลดการอักเสบ เพิ่มความชุ่มชื่นให้ผิว
            ช่วยผลัดเซลล์ผิวที่เสื่อมสภาพแล้วหลุดลอกออกไป ทำให้ผิวนุ่ม
            ละมุนยิ่งขึ้น
          </div>
        ),
      },
    ],
    ingredientSummary: (
      <div>
        Water, Nicotinamide, Glycerine, Ethoxydiglycol, Butylene Glycol,
        Polysorbate 20, Hamamelis Virginiana Leaf Extract, Propylene Glycol,
        Allantoin, Aloe Barbadensis Leaf Juice, Phenoxyethanol, Acetyl
        Glucosamine, Sodium Hyaluronate, Biosaccharide Gum-1, Dipotassium
        Glycyrrhizinate, Disodium Edta, Triethylene Glycol, Hydrolyzed
        Hyaluronic Acid, Hydroxypropyl Guar, Fragrance, Sodium Benzoate,
        Hydroxyethylcellulose, Caprylyl 2-Glyceryl Ascorbate, Phyllanthus
        Emblica Fruit Extract, Potassium Sorbate
      </div>
    ),
    fdaId: '10-1-6200030338',
    weight: '15 มล.',
    faqs: [
      {
        question: 'เซรั่มไฮยารัชชาใช้อย่างไร',
        answer: (
          <div>
            หยดเซรั่ม เช้า-ก่อนนอน หลังล้างหน้าเป็นประจำทุกวัน แนะนำให้ทาวิตซี
            ยูวี โพรเทคชั่น ในตอนกลางวันร่วมด้วย
          </div>
        ),
      },
      {
        question: 'ผิวแพ้ง่ายใช้ได้ไหม',
        answer: (
          <div>
            ผิวแพ้ง่ายสามารถใช้ได้ค่ะ เพราะผลิตภัณฑ์ของเรา ไม่แต่งสี
            ไม่แต่งกลิ่น ลดความระคายเคือง และยังมีส่วนผสมของ Allantoin
            ช่วยในการปกป้องผิวหนังป้องกันอาการแพ้ และช่วยให้ผิวมีสุขภาพดี
          </div>
        ),
      },
      {
        question: 'เซรั่มไฮยารัชชาช่วยเรื่องอะไรบ้าง',
        answer: (
          <div>
            ด้วยอนุพันธ์ Oilgo Hyaluronic Acid
            สามารถซึมเข้าสู่ผิวได้อย่างรวดเร็ว และเติมความชุ่มชื่นให้กับผิว
            ช่วยให้ผิวนุ่ม ฟู เด้ง ใช้ได้กับทุกสภาพผิว อ่อนโยนแม้ผิวแพ้ง่าย
          </div>
        ),
      },
      {
        question: 'เซรั่มไฮยารัชชา 1 ขวดใช้ได้นานแค่ไหน ',
        answer: (
          <div>
            ใช้ได้ประมาณ 3-4 สัปดาห์ หยดครั้งละ 1-2 ดรอปทั่วผิวหน้า
            เช้าและก่อนนอนค่ะ
          </div>
        ),
      },
      {
        question: 'ผลิตภัณฑ์รัชชาปลอดภัยไหม มีอย.หรือไม่',
        answer: (
          <div>
            <div>
              สินค้าทุกชิ้นของรัชชาปลอดภัย ได้มาตรฐานอย.พร้อมผ่านการรับรองคุณภาพ
              GMP ผลิตโดยโรงงานมาตรฐาน
            </div>
            <div class="mt-2">
              เลขที่ใบรับจดแจ้ง: 10-1-6200030338 (เซรั่มไฮยารัชชา)
              <br />
              เลขที่ใบรับจดแจ้ง: 10-1-6100024751 (เซรั่มวิตซี)
              <br />
              เลขที่ใบรับจดแจ้ง: 10-1-6010063568 (วิตซี ยูวี โพรเทคชั่น)
              <br />
              เลขที่ใบรับจดแจ้ง: 10-1-6100061313 (วิตซีวิปโฟม)
            </div>
          </div>
        ),
      },

      {
        question: 'ใช้เซรั่มไฮยารัชชา กี่ขวดถึงเห็นผล',
        answer: (
          <div>
            เซรั่มไฮยารัชชาเห็นการเปลี่ยนแปลงตั้งแต่ขวดแรก
            ขึ้นอยู่กับปัญหาและสภาพผิวของแต่ละคนด้วยค่ะ
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'ผิวนุ่ม ชุ่ม ฟู เด้ง',
      subtitle:
        'ด้วยอนุภาค Oilgo Hyaluronic Acid ซึมลึกถึงชั้นใต้ผิว คงความชุ่มชื้นได้ยาวนาน',
      url: 'https://www.youtube.com/watch?v=JWxO2nzSiZM',
    },
    disclaimer: (
      <div>
        1.
        การใช้เครื่องสำอางที่มีสารป้องกันแสงแดดเป็นเพียงวิธีหนึ่งที่จะช่วยลดความเสี่ยงจากอันตรายจากแสงแดด
        <br />
        2. อ่านวิธีใช้ให้ละเอียดและปฏิบัติตามอย่างเคร่งครัด
        <br />
        3. หากใช้แล้วมีความผิดปกติใดๆ เกิดขึ้นต้องหยุดใช้
        และปรึกษาแพทย์หรือเภสัชกร
      </div>
    ),
    reviews: [
      {
        image: '/ratcha/review-1.jpg',
        review: (
          <div>
            โดนใจมากเซรั่มที่เน้นพวกให้ความชุ่มชื้นกับผิว เพราะเป็นคนที่ผิวแห้ง
            ผิวขาดน้ำ พอได้ลองแล้วรู้สึกว้าว ตั้งแต่เนื้อสัมผัสที่เหมือนน้ำ
            ทาลงผิวแล้วให้ความชุ่มชื้น ทาแล้วไม่มัน ซึมไว ใช้ไปเรื่อยๆ หน้าฟู
            เครื่องสำอางติดทนขึ้นด้วย
          </div>
        ),
        name: 'คุณไข่',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-2.jpg',
        review: (
          <div>
            ใครผิวแห้งขาดน้ำ อยากแนะนำตัวนี้เลย เค้าเติมความชุ่มชื้นให้ผิวดีมาก
            ผิวอิ่มน้ำขึ้น เนื้อเซรั่มเค้าบางเบา ฟิลเหมือนน้ำเปล่า
            ไม่เหนอะหนะผิว ทางเราใช้ทั้งเช้าทั้งเย็นเลย รู้สีกว่าผิวใสขึ้นด้วย
          </div>
        ),
        name: 'คุณเฟิร์ส',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-3.jpg',
        review: (
          <div>
            ตัวนี้จะช่วยในเรื่องให้ความชุ่มชื้น ดูอิ่มน้ำ ผิวหน้าสว่างกระจ่างใส
            ดูมีออร่า ลดเลือนริ้วรอยให้จางลง
            และที่สำคัญเขายังสามารถกักเก็บน้ำบนใบหน้าของเราไม่ให้แห้งตึงอีกด้วย
            ใช้ทั้งเช้าและเย็นคือดีย์มากๆ แล้วยิ่งแช่เย็นคือสดชื่นอ่ะ
          </div>
        ),
        name: 'คุณส้ม',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-4.jpg',
        review: (
          <div>
            เป็นเซรั่มที่ยกให้เรื่องอิ่มฟู ฉ่ำน้ำ
            ตัวนี้นอกจากผิวจะชุุ่มชื้นแล้วผิวหน้าค่อยๆ ใสขึ้นอีกด้วย
            แล้วทาตอนเช้าสังเกตได้เลยว่า วันนั้นเมคอัพติดทนมาก หน้าเด้ง
            และมีสารสกัดจากว่านหางจระเข้ช่วยปลอบประโลมผิวแสบร้อนระคายเคืองได้ด้วยนะ
          </div>
        ),
        name: 'คุณอิ้งค์',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-5.jpg',
        review: (
          <div>
            มาป้ายยาค่ะ เป็นปลื้มมากๆๆๆ คือชอบฟีลตอนใช้มาก มันชุ่มชื้นหน้านุ่มๆ
            และเค้าไปดูสารสกัดคือสิบสิบ ให้ความชุ่มชื้นมากกว่า Collagen Peptide
            อีก และคือปอพึ่งบ่นเรื่องหน้าแก่ น้องเค้าช่วยเรื่องริ้วรอยค่ะแม่
            เธอมาได้ทันเวลาพอดี
          </div>
        ),
        name: 'คุณปอ',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-10.jpg',
        review: (
          <div>
            ทุกคน!!! เซรั่มไฮยาขวดฟ้าตัวนี้คือดีมากๆ เคยเห็นพี่ในมอรีวิวว่า
            ช่วยให้ผิวไม่แห้งเลยไปซื้อตาม เพราะช่วงนี้หน้าแห้ง
            แถมนอนดึกหน้าโทรมสุดๆ พอลองใช้คือดีจริงๆ หน้าฟู ชุ่มชื้นและฉ่ำมาก
            ไม่เหนียวแถมซึมไวด้วย ต้องไปลอง
          </div>
        ),
        name: 'คุณซินดี้',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-11.jpg',
        review: (
          <div>
            หมดไปครึ่งขวดแล้วกับ Ratcha Hya ประทับใจมากกกก ผิวละเอียดขึ้นเรื่อยๆ
            รูขุมขนกระชับมากขึ้น ตัวนี้แทบจะไม่มีกลิ่นเลย เป็นน้ำใสๆ
            อ่อนโยนและซึมผิวได้ดี ไม่เหนียวเหนอะหนะ ไม่รบกวนสกินแคร์ตัวอื่น
            ผิวดูอิ่มน้ำ + กระจ่างใส
          </div>
        ),
        name: 'คุณกุ้ง',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-8-1.jpg',
        review: (
          <div>
            สกินแคร์เพื่อผิวอิ่มน้ำ Ratcha Hya ตัวนี้ช่วยผิวแห้งกร้านได้ดีมาก
            ทำให้ผิวฟู ชุ่มชื้น ตัวเนื้อคล้ายน้ำเปล่าเลย
            มีความหนืดเล็กน้อยเท่านั้น ซึมไวมาก ไม่เหนอะผิว ไม่หนักหน้า
            แถมแพคเกจคือชนะเริ่ดไปเลย
          </div>
        ),
        name: 'คุณเบคอน',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-6.jpg',
        review: (
          <div>
            เป็นเซรั่มที่ใช้แล้วชอบเลย เนื้อเซรั่มใสๆ ซึมง่าย
            ช่วยเติมความชุ่มชื้น ตื่นมาผิวหน้าไบร์ท ฟูฉ่ำ เหมือนคนนอนเต็มตื่น
            คือชอบหน้าตัวเองมาก มันผ่อง ใสบอกไม่ถูก นี่ใช้ทั้งเช้าทั้งก่อนนอนเลย
            ชอบจริง!
          </div>
        ),
        name: 'คุณการ์ฟิว',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-9.jpg',
        review: (
          <div>
            มาช้าแต่มานะ ซุ่มใช้น้อง Ratcha Hya มาครึ่งขวดแล้ววว ดีงามอยู่เด้อ
            ว่าไม่ได้!! ตัวนี้เน้นความชุ่มชื้นนะ นี่ชอบใช้หลังลงน้ำตบ
            และก่อนลงพวกเซรั่มอื่นๆ ตาม หน้าโดยรวมคือเรียบเนียนขึ้น
            ดูสุขภาพผิวดีอะ ต้องลอง
          </div>
        ),
        name: 'คุณบูม',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-12.jpg',
        review: (
          <div>
            เซรั่ม Ratcha Hya เป็นเซรั่มที่ทำให้หน้าอิ่มฟูขึ้นมาก
            ให้ความชุ่มชื้น เหมาะกับคนผิวหน้าแห้ง ขาดน้ำ เนื้อใสๆ ไม่เหนียว
            ซึมเข้าผิวง่าย มีส่วนผสมของว่านหางจระเข้ด้วย และช่วยเรื่องให้คสอ.
            ติดทนขึ้นด้วยนะคะ
          </div>
        ),
        name: 'คุณหมวย',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-7.jpg',
        review: (
          <div>
            ผิวแพ้ง่ายมารวมกันทางนี้ ขอแนะนำ Ratcha Hya ตัวนี้หลักๆ
            เลยสกัดจากว่านหางจระเข้ ช่วยสมานผิว
            พร้อมเติมความชุ่มชื้นให้ผิวแล้วก็ยังสกัดจาก Allantoin
            ที่จะมาช่วยให้ผิวกระจ่างใสอีกด้วย เนื้อเซรั่มซึมไว
            ไม่เหนอะหนะอยู่บนผิว เหมาะกับอากาศบ้านเรา
          </div>
        ),
        name: 'คุณแพรว',
        product: 'RTCHY1',
      },
    ],
    relateProducts: [
      'RTCSET1',
      'RTCSET12',
      'RTCSET13',
      'RTCSET14',
      'RTCSET2',
      'RTCSET3',
      'RTCSET4',
      'RTCSET5',
      'RTCSET6',
      'RTCSET7',
      'RTCSET8',
      'RTCSET9',
      'RTCSET10',
      'RTCSET11',
    ],
    upsellProducts: ['RTCVC1', 'RTCWF1', 'RTCCR1'],
  };
};
