import { GLRBS1 } from './product/GLRBS1';
import { GLRCL1 } from './product/GLRCL1';
import { GLRSC1 } from './product/GLRSC1';
import { GLRVC1 } from './product/GLRVC1';
import { PRECL1 } from './product/PRECL1';
import { PRECL3 } from './product/PRECL3';
import { PRECL6 } from './product/PRECL6';
import { RNTFX1 } from './product/RNTFX1';
import { RNTFX3 } from './product/RNTFX3';
import { RTCCR1 } from './product/RTCCR1';
import { RTCCR1VC1HY1 } from './product/RTCCR1VC1HY1';
import { RTCCR1VC1 } from './product/RTCCR1VC1';
import { RTCCR3 } from './product/RTCCR3';
import { RTCCR3VC2 } from './product/RTCCR3VC2';
import { RTCCR5 } from './product/RTCCR5';
import { RTCCR10 } from './product/RTCCR10';
import { RTCHY1 } from './product/RTCHY1';
import { RTCHY2 } from './product/RTCHY2';
import { RTCHY5 } from './product/RTCHY5';
import { RTCSB1 } from './product/RTCSB1';
import { RTCSET1 } from './product/RTCSET1';
import { RTCSET2 } from './product/RTCSET2';
import { RTCSET3 } from './product/RTCSET3';
import { RTCSET4 } from './product/RTCSET4';
import { RTCSET5 } from './product/RTCSET5';
import { RTCSET6 } from './product/RTCSET6';
import { RTCSET7 } from './product/RTCSET7';
import { RTCSET8 } from './product/RTCSET8';
import { RTCSET9 } from './product/RTCSET9';
import { RTCSET10 } from './product/RTCSET10';
import { RTCSET11 } from './product/RTCSET11';
import { RTCSET12 } from './product/RTCSET12';
import { RTCSET13 } from './product/RTCSET13';
import { RTCSET14 } from './product/RTCSET14';
import { RTCVC1 } from './product/RTCVC1';
import { RTCVC2 } from './product/RTCVC2';
import { RTCVC5 } from './product/RTCVC5';
import { RTCWF1 } from './product/RTCWF1';
import { RTCVCP1 } from './product/RTCVCP1';
import { RTCVCP2 } from './product/RTCVCP2';
import { RTCVCP3 } from './product/RTCVCP3';
import { RTCVCP5 } from './product/RTCVCP5';

export const product = () => {
  const data = [
    GLRBS1(),
    GLRCL1(),
    GLRSC1(),
    GLRVC1(),
    PRECL1(),
    PRECL3(),
    PRECL6(),
    RNTFX1(),
    RNTFX3(),
    RTCCR1(),
    RTCCR1VC1HY1(),
    RTCCR1VC1(),
    RTCCR3(),
    RTCCR3VC2(),
    RTCCR5(),
    RTCCR10(),
    RTCHY1(),
    RTCHY2(),
    RTCHY5(),
    RTCSB1(),
    RTCSET1(),
    RTCSET2(),
    RTCSET3(),
    RTCSET4(),
    RTCSET5(),
    RTCSET6(),
    RTCSET7(),
    RTCSET8(),
    RTCSET9(),
    RTCSET10(),
    RTCSET11(),
    RTCSET12(),
    RTCSET13(),
    RTCSET14(),
    RTCVC1(),
    RTCVC2(),
    RTCVC5(),
    RTCWF1(),
    RTCVCP1(),
    RTCVCP2(),
    RTCVCP3(),
    RTCVCP5(),
  ];

  return data;
};
