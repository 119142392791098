export const RTCWF1 = () => ({
  url: '/product/whipfoam',
  title: 'วิตซีวิปโฟม',
  brand: 'RATCHA',
  sku: 'RTCWF1',
  totalSale: 81092,
  price: 380,
  offerPrice: 500,
  images: ['/product/ratcha-whip-1.2.jpg'],
  product: 'วิตซีวิปโฟม 1 หลอด',
  shortDescription: 'วิปโฟมกำจัดสิว ล้างปุ๊ป สิวยุบปั๊บ',
  description: (
    <div class="mb-2 text-baseColor">
      <p>วิปโฟมนุ่มนิ่ม กำจัดสิว สารสกัดน้ำเข้า GO-VC จากญี่ปุ่น</p>
      <p class="mt-2">
        วิปโฟมล้างหน้า มีส่วนผสมของวิตามินซี Amitose GOA
        นวัตกรรมใหม่จากประเทศญี่ปุ่น มีความเสถียรสูง
        ช่วยลดเลือนจุดด่างดำจากสิวให้ดูจางลง
        ฟื้นฟูสภาพผิวให้แลดูขาวกระจ่างใสขึ้น ฟองโฟมหนานุ่มไม่บาดผิวให้ระคายเคือง
        และยังไม่ทำให้ผิวแห้งตึงหลังล้างหน้า
      </p>
      <ul class="list-disc mt-4 ml-10 text-baseColor">
        <li>วิปโฟมวิตซี ฟองโฟมหนานุ่ม ไม่บาดผิวให้ระคายเคือง</li>
        <li>ลดอาการแพ้ ผดผื่นคัน ช่วยลดการเกิดสิวได้</li>
        <li>ฟื้นฟูสภาพผิวให้แลดูขาวกระจ่างใสยิ่งขึ้น </li>
        <li>ส่วนผสมจากว่านหางจระเข้ ไม่ทำให้ผิวแห้งตึง</li>
      </ul>
    </div>
  ),
  options: null,
  guarantee: {
    title: 'คุณภาพจากรัชชา',
    description: (
      <div>
        ผลิตภัณฑ์คุณภาพจากรัชชา ได้มาตรฐานอย. พร้อมผ่านการรับรองคุณภาพ GMP
        ผลิตโดยโรงงานมาตรฐาน
      </div>
    ),
  },
  ingredients: [
    {
      image: '/ingredient/gum-2.png',
      title: 'Biosaccharide Gum - 2 (Rhamnosoft)',
      description: (
        <div>
          สารสกัดน้ำตาลแรมโนส จากฝรั่งเศส
          เป็นที่ยอมรับอย่างเป็นทางการถึงประสิทธิภาพในการดูแลปัญหาเรื่องการแพ้
          สามารถช่วยบรรเทาอาการอาการอักเสบของผิว
          เพื่อเลี่ยงการที่ผิวจะติดสเตียรอยด์เหมาะสำหรับผู้ที่มีปัญหาแพ้ง่าย
        </div>
      ),
    },
    {
      image: '/ingredient/rcvc.png',
      title: 'Caprylyl 2-Glyceryl Ascorbate',
      description: (
        <div>
          เป็นวิตามินซีนวัตกรรมใหม่ โดยมีคุณสมบัติที่พิเศษกว่าวิตามินซีชนิดอื่นๆ
          มีความเสถียรสูง ไม่เปลี่ยนสีเมื่อสัมผัสอากาศหรือแสง
        </div>
      ),
    },
    {
      image: '/ingredient/vcaloe.png',
      title: 'Aloe Barbadensis Leaf Juice',
      description: (
        <div>
          สารสกัดทรงคุณค่าจากว่านหางจระเข้ ด้วยประสิทธิภาพในการบำรุงผิวล้ำลึก
          ยับยั้งการอักเสบของผิวหนัง ลดอาการระคายเคืองจากมลภาวะที่มาทำร้ายผิว
          ปกป้องผิว บรรเทาอาการแสบร้อนของผิวเมื่อต้องออกแดดเป็นเวลานาน
          เติมเต็มความชุ่มชื้นให้ผิว
        </div>
      ),
    },
  ],
  ingredientSummary: (
    <div>
      Water, Lauric, Acid, Glycerin, Polyethyene, Glycol 400, Sodium Laureth
      Sulfate, Propylene Glycol, Potassium Hydroxide, Cocamide Dea,
      Cocamidopropyl Betaine, Glyceryl Stearate Se, Cetryl Alcohol, Myrisric
      Acid, Stearic Acid, Fragrance, Phenoxyethanol, Silica, Tetrasodium Edta,
      Aloe Barbadensis Leaf Juice, 1, 2-Hexanediol, Biosaccharide Gum-2,
      Biosaccharide Gum-4, Caprylyl 2-Glyceryl Ascorbate
    </div>
  ),
  fdaId: '10-1-6100024751',
  weight: '10 มล.',
  faqs: [
    {
      question: 'เป็นสิวอยู่ใช้ได้ไหม',
      answer: (
        <div>
          เป็นสิวก็สามารถใช้ได้ค่ะ เพราะใน VC Whip Foam มีส่วนผสมของ Amitose GOA
          มีคุณสมบัติในการยับยั้งการเจริญเติบโตของเชื้อแบคทีเรียต่างๆ
          ที่เป็นสาเหตุของการเกิดสิว จะช่วยให้สิวค่อยๆ ยุบและแห้งไป
          และป้องกันไม่ให้สิวใหม่เกิดขึ้นด้วยค่ะ
        </div>
      ),
    },
    {
      question: 'ตั้งครรภ์ใช้ได้หรือไม่',
      answer: (
        <div>
          สำหรับคุณแม่ตั้งครรภ์สามารถใช้ได้นะคะ
          เพราะว่าในผลิตภัณฑ์ของเราไม่มีส่วนผสมของ Vitamin A, Salicylic Acid
          (BHA), Benzoyl Peroxide (BP) และสารอันตราย อันก่อให้เกิดผลข้างเคียงค่ะ
          ผลิตภัณฑ์ของเราจึงปลอดภัยต่อตัวคุณแม่และลูกในครรภ์อย่างแน่นอนค่ะ
        </div>
      ),
    },
    {
      question: 'ทำไมสิวถึงหายหลังล้างด้วยวิตซีวิปโฟม',
      answer: (
        <div>
          ด้วยประสิทธิภาพสารสกัดของ Biosaccharide Gum-2 ผสมผสานกับ GOA -Vit C
          ที่ช่วยลดการอักเสบบวมแดง ผื่นคัน และกำจัดสิว
        </div>
      ),
    },
    {
      question: 'ผิวหน้าไหม้แดด ทำยังไงดี',
      answer: (
        <div>
          ผลิตภัณฑ์ของเราช่วยได้ เพราะมีส่วนผสมของ Aloe Vera
          ช่วยสมานผิวพร้อมทั้งให้ความชุ่มชื่นแก่ผิว
          บรรเทาการระคายเคืองแสบแดงของผิวจากการโดนแสงแดดและความร้อน พร้อมทั้ง
          Niacinamide (Vitamin B3) ช่วยให้ผิวค่อยๆ กระจ่างใสขึ้น
        </div>
      ),
    },
    {
      question: 'วิธีใช้วิตซีวิปโฟมให้เห็นผล',
      answer: (
        <div>
          <div>ล้างทำความสะอาดผิวหน้าเป็นประจำ เช้า - เย็น</div>
          <div class="mt-2">
            เพื่อผลลัพธ์ที่ดีขึ้น ควรใช้คู่กับถุงตีวิปจาก VC
            ช่วยผลัดเซลล์ผิวเก่า และควบคู่กับผลิตภัณฑ์ เซรั่มไฮยารัชชา
            เซรั่มวิตซี และปกป้องผิวจากแสงแดดด้วยวิตซี ยูวี โพรเทคชั่น
          </div>
        </div>
      ),
    },
    {
      question: 'ผลิตภัณฑ์รัชชาปลอดภัยไหม มีอย.ไหม',
      answer: (
        <div>
          <div>
            สินค้าทุกชิ้นของรัชชาปลอดภัย ได้มาตรฐานอย.พร้อมผ่านการรับรองคุณภาพ
            GMP ผลิตโดยโรงงานมาตรฐาน
          </div>
          <div class="mt-2">
            เลขที่ใบรับจดแจ้ง: 10-1-6200030338 (เซรั่มไฮยารัชชา)
            <br />
            เลขที่ใบรับจดแจ้ง: 10-1-6100024751 (เซรั่มวิตซี)
            <br />
            เลขที่ใบรับจดแจ้ง: 10-1-6010063568 (วิตซี ยูวี โพรเทคชั่น)
            <br />
            เลขที่ใบรับจดแจ้ง: 10-1-6100061313 (วิตซีวิปโฟม)
          </div>
        </div>
      ),
    },
    {
      question: 'ผิวแพ้ง่าย ใช้ได้ไหม ',
      answer: (
        <div>
          ผิวแพ้ง่ายสามารถใช้ VC Whip Foam ได้เลยค่ะ เพราะผลิตภัณฑ์ของเรา
          ไม่แต่งสี ไม่แต่งกลิ่น ลดความระคายเคือง และยังมีส่วนผสมของ Allantoin
          ช่วยในการปกป้องผิวหนังป้องกันอาการแพ้ และช่วยให้ผิวมีสุขภาพดี
        </div>
      ),
    },
  ],
  videoContent: {
    title: 'กำจัดสิว ผิวกระจ่างใส',
    subtitle: 'วิปโฟมเนื้อนุ่ม ผิวสะอาด ไม่บาดผิว ไร้สิ่งอุดตัน',
    url: 'https://www.youtube.com/watch?v=IMdU_dOhcec',
  },
  disclaimer: (
    <div>
      1.
      การใช้เครื่องสำอางที่มีสารป้องกันแสงแดดเป็นเพียงวิธีหนึ่งที่จะช่วยลดความเสี่ยงจากอันตรายจากแสงแดด
      <br />
      2. อ่านวิธีใช้ให้ละเอียดและปฏิบัติตามอย่างเคร่งครัด
      <br />
      3. หากใช้แล้วมีความผิดปกติใดๆ เกิดขึ้นต้องหยุดใช้
      และปรึกษาแพทย์หรือเภสัชกร
    </div>
  ),
  reviews: [
    {
      image: '/ratcha/review-whip-1.1.jpg',
      review: (
        <div>
          เราใช้ไปประมาณอาทิตย์กว่า หน้าเริ่มใสขึ้น ขาวขึ้นจริงจัง
          ล้างแล้วหน้าไม่เอี๊ยด ล้างสะอาดมาก ตัวนี้ใช้นิดเดียวก็ตีโฟมได้เริ่ด
        </div>
      ),
      name: 'คุณชาลี่',
      product: 'RTCWF1',
    },
    {
      image: '/ratcha/review-whip-2.1.jpg',
      review: (
        <div>
          ผิวหน้าสะอาดได้ในขั้นตอนเดียว เพราะการทำความสะอาดหน้าเป็นสิ่งสำคัญ
          ยับยั้งต้นตอของปัญหาสิว ผิวมัน หน้าหมอง ไม่บาดผิว คืนความชุ่มชื่น
          บำรุงผิวด้วยนวัตกรรมจากญี่ปุ่น GO-VC ไร้สารตกค้าง
        </div>
      ),
      name: 'คุณเบลล์',
      product: 'RTCWF1',
    },
    {
      image: '/ratcha/review-whip-3.1.jpg',
      review: (
        <div>
          เครียดเฟสเล่นไม่ได้ ไอจีก้อเสือกเจ๊งอีก ล้างหน้าด้วย VC WhipFoam รอ
          หน้าสะอาดผิวนุ้มนุ่ม
        </div>
      ),
      name: 'คุณปุ้ย',
      product: 'RTCWF1',
    },
    {
      image: '/ratcha/review-whip-4.1.jpg',
      review: (
        <div>
          เนื้อวิปโฟมเค้าฟองละเอียด นุ่มมากก ละมุน เนื้อโฟมฟูๆ
          ช่วยทำสะอาดผิวหน้าได้อย่างอ่อนโยน เบาสบายผิว
          และช่วยลดการเกิดสิวด้วยค่ะ
        </div>
      ),
      name: 'Make Up Review',
      product: 'RTCWF1',
    },
    {
      image: '/ratcha/review-whip-5.1.jpg',
      review: (
        <div>
          เนื้อโฟมเป็นวิปสีขาว นุ่มมาก เวลาโดนน้ำจะเปลี่ยนเป็นเนื้อวิป
          ฟองโฟมนุ่มฟู มีกลิ่นหอมอ่อนๆ อารมณ์วิตามินซีเน้นๆ ล้างหน้าได้แบบฟินๆ
          ล้างออกง่าย ทำความสะอาดเครื่องสำอางได้อย่างหมดจด
        </div>
      ),
      name: 'Cosmemet',
      product: 'RTCWF1',
    },
    {
      image: '/ratcha/review-whip-6.1.jpg',
      review: (
        <div>
          โอ้ยยยย ถ้าจะเทียบกันขนาดนี้ หมดคำพูดละ Lamer กับ Vc WhipFoam
          คุณภาพไม่ต่างกันเลยจ้า
        </div>
      ),
      name: 'คุณเดือน',
      product: 'RTCWF1',
    },
    {
      image: '/ratcha/review-whip-8.1.jpg',
      review: (
        <div>วิปโฟมล้างหน้าวิตามินซี รอยสิวจางไว คนอยากผิวใสต้องไปตำ!</div>
      ),
      name: 'คุณเจน',
      product: 'RTCWF1',
    },
    {
      image: '/ratcha/review-whip-7.jpg',
      review: <div>สะอาดหมดจด อ่อนโยนแม้ผิวแพ้ง่าย</div>,
      name: 'Review',
      product: 'RTCWF1',
    },
  ],
  relateProducts: [
    'RTCSET1',
    'RTCSET12',
    'RTCSET13',
    'RTCSET14',
    'RTCSET2',
    'RTCSET3',
    'RTCSET4',
    'RTCSET5',
    'RTCSET6',
    'RTCSET7',
    'RTCSET8',
    'RTCSET9',
    'RTCSET10',
    'RTCSET11',
  ],
  upsellProducts: ['RTCHY1', 'RTCVC1', 'ARTMS1'],
});
