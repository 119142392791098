export const home = () => {
  return {
    meta: {
      title: 'รัชชา',
      description: 'เซรั่มไฮยารัชชา',
    },
    mainProduct: {
      title: 'เซรั่มไฮยารัชชา',
      description: 'ผิวเด้ง ฉ่ำน้ำ ปรับผิวให้กระจ่างใส',
      tag: 'คงความชุ่มชื้นยาวนานกว่า 3 วัน',
      image: '/product/hya-1.jpg',
      url: '/product/ratcha',
    },
    benefit: {
      title: 'ผิวอิ่มน้ำ นุ่มฟู มีออร่า',
      description: (
        <div>
          เรารู้ว่าจุดเริ่มต้นของผิวอิ่มน้ำ ต้องเริ่มจากภายใน ด้วยโมเลกุล Oligo
          Hyaluronic Acid สามารถซึมลึกสู่ชั้นใต้ผิว
          <ul class="mt-2 ml-2 list-disc"></ul>
          <p>แบรนด์รัชชาประกอบไปด้วย</p>
          <ul class="mt-4 ml-4 list-disc">
            <li>
              <span class="font-bold">เซรั่มไฮยารัชชา</span> เติมความชุ่มชื้น
              คงความสมดุลให้กับผิว
            </li>
            <li>
              <span class="font-bold">เซรั่มวิตซี</span> ลดสิว ผิวกระจ่างใส
            </li>
            <li>
              <span class="font-bold">วิตซี ยูวี โพรเทคชั่น</span>{' '}
              ปกป้องผิวจากแสงแดด
            </li>
            <li>
              <span class="font-bold">วิตซีวิปโฟม</span> ทำความสะอาดล้ำลึก
              ไม่ระคายเคืองผิว
            </li>
          </ul>
        </div>
      ),
      image: '/ratcha/home-1.2.jpg',
    },
    recommendProduct: {
      title: 'ชุดเคลียร์สิว ฝ้ากระ',
      description: (
        <div>
          เติมความชุ่มชื้น ลดฝ้ากระ รอยดำรอยแดงจากสิว ปกป้องคุณจากแสงแดด
        </div>
      ),
      image: '/product/ratcha-set-e-5.png',
      url: '/product/set-5',
    },
    reviewImageProduct: '/product/product_ratcha-hya-8afc.png',
    reviews: [
      {
        image: '/ratcha/review-1.jpg',
        review: (
          <div>
            โดนใจมากเซรั่มที่เน้นพวกให้ความชุ่มชื้นกับผิว เพราะเป็นคนที่ผิวแห้ง
            ผิวขาดน้ำ พอได้ลองแล้วรู้สึกว้าว ตั้งแต่เนื้อสัมผัสที่เหมือนน้ำ
            ทาลงผิวแล้วให้ความชุ่มชื้น ทาแล้วไม่มัน ซึมไว ใช้ไปเรื่อยๆ หน้าฟู
            เครื่องสำอางติดทนขึ้นด้วย
          </div>
        ),
        name: 'คุณไข่',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-2.jpg',
        review: (
          <div>
            ใครผิวแห้งขาดน้ำ อยากแนะนำตัวนี้เลย เค้าเติมความชุ่มชื้นให้ผิวดีมาก
            ผิวอิ่มน้ำขึ้น เนื้อเซรั่มเค้าบางเบา ฟิลเหมือนน้ำเปล่า
            ไม่เหนอะหนะผิว ทางเราใช้ทั้งเช้าทั้งเย็นเลย รู้สีกว่าผิวใสขึ้นด้วย
          </div>
        ),
        name: 'คุณเฟิร์ส',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-3.jpg',
        review: (
          <div>
            ตัวนี้จะช่วยในเรื่องให้ความชุ่มชื้น ดูอิ่มน้ำ ผิวหน้าสว่างกระจ่างใส
            ดูมีออร่า ลดเลือนริ้วรอยให้จางลง
            และที่สำคัญเขายังสามารถกักเก็บน้ำบนใบหน้าของเราไม่ให้แห้งตึงอีกด้วย
            ใช้ทั้งเช้าและเย็นคือดีย์มากๆ แล้วยิ่งแช่เย็นคือสดชื่นอ่ะ
          </div>
        ),
        name: 'คุณส้ม',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-4.jpg',
        review: (
          <div>
            เป็นเซรั่มที่ยกให้เรื่องอิ่มฟู ฉ่ำน้ำ
            ตัวนี้นอกจากผิวจะชุุ่มชื้นแล้วผิวหน้าค่อยๆ ใสขึ้นอีกด้วย
            แล้วทาตอนเช้าสังเกตได้เลยว่า วันนั้นเมคอัพติดทนมาก หน้าเด้ง
            และมีสารสกัดจากว่านหางจระเข้ช่วยปลอบประโลมผิวแสบร้อนระคายเคืองได้ด้วยนะ
          </div>
        ),
        name: 'คุณอิ้งค์',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-5.jpg',
        review: (
          <div>
            มาป้ายยาค่ะ เป็นปลื้มมากๆๆๆ คือชอบฟีลตอนใช้มาก มันชุ่มชื้นหน้านุ่มๆ
            และเค้าไปดูสารสกัดคือสิบสิบ ให้ความชุ่มชื้นมากกว่า Collagen Peptide
            อีก และคือปอพึ่งบ่นเรื่องหน้าแก่ น้องเค้าช่วยเรื่องริ้วรอยค่ะแม่
            เธอมาได้ทันเวลาพอดี
          </div>
        ),
        name: 'คุณปอ',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-10.jpg',
        review: (
          <div>
            ทุกคน!!! เซรั่มไฮยาขวดฟ้าตัวนี้คือดีมากๆ เคยเห็นพี่ในมอรีวิวว่า
            ช่วยให้ผิวไม่แห้งเลยไปซื้อตาม เพราะช่วงนี้หน้าแห้ง
            แถมนอนดึกหน้าโทรมสุดๆ พอลองใช้คือดีจริงๆ หน้าฟู ชุ่มชื้นและฉ่ำมาก
            ไม่เหนียวแถมซึมไวด้วย ต้องไปลอง
          </div>
        ),
        name: 'คุณซินดี้',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-11.jpg',
        review: (
          <div>
            หมดไปครึ่งขวดแล้วกับ Ratcha Hya ประทับใจมากกกก ผิวละเอียดขึ้นเรื่อยๆ
            รูขุมขนกระชับมากขึ้น ตัวนี้แทบจะไม่มีกลิ่นเลย เป็นน้ำใสๆ
            อ่อนโยนและซึมผิวได้ดี ไม่เหนียวเหนอะหนะ ไม่รบกวนสกินแคร์ตัวอื่น
            ผิวดูอิ่มน้ำ + กระจ่างใส
          </div>
        ),
        name: 'คุณกุ้ง',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-8-1.jpg',
        review: (
          <div>
            สกินแคร์เพื่อผิวอิ่มน้ำ Ratcha Hya ตัวนี้ช่วยผิวแห้งกร้านได้ดีมาก
            ทำให้ผิวฟู ชุ่มชื้น ตัวเนื้อคล้ายน้ำเปล่าเลย
            มีความหนืดเล็กน้อยเท่านั้น ซึมไวมาก ไม่เหนอะผิว ไม่หนักหน้า
            แถมแพคเกจคือชนะเริ่ดไปเลย
          </div>
        ),
        name: 'คุณเบคอน',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-6.jpg',
        review: (
          <div>
            เป็นเซรั่มที่ใช้แล้วชอบเลย เนื้อเซรั่มใสๆ ซึมง่าย
            ช่วยเติมความชุ่มชื้น ตื่นมาผิวหน้าไบร์ท ฟูฉ่ำ เหมือนคนนอนเต็มตื่น
            คือชอบหน้าตัวเองมาก มันผ่อง ใสบอกไม่ถูก นี่ใช้ทั้งเช้าทั้งก่อนนอนเลย
            ชอบจริง!
          </div>
        ),
        name: 'คุณการ์ฟิว',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-9.jpg',
        review: (
          <div>
            มาช้าแต่มานะ ซุ่มใช้น้อง Ratcha Hya มาครึ่งขวดแล้ววว ดีงามอยู่เด้อ
            ว่าไม่ได้!! ตัวนี้เน้นความชุ่มชื้นนะ นี่ชอบใช้หลังลงน้ำตบ
            และก่อนลงพวกเซรั่มอื่นๆ ตาม หน้าโดยรวมคือเรียบเนียนขึ้น
            ดูสุขภาพผิวดีอะ ต้องลอง
          </div>
        ),
        name: 'คุณบูม',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-12.jpg',
        review: (
          <div>
            เซรั่ม Ratcha Hya เป็นเซรั่มที่ทำให้หน้าอิ่มฟูขึ้นมาก
            ให้ความชุ่มชื้น เหมาะกับคนผิวหน้าแห้ง ขาดน้ำ เนื้อใสๆ ไม่เหนียว
            ซึมเข้าผิวง่าย มีส่วนผสมของว่านหางจระเข้ด้วย และช่วยเรื่องให้คสอ.
            ติดทนขึ้นด้วยนะคะ
          </div>
        ),
        name: 'คุณหมวย',
        product: 'RTCHY1',
      },
      {
        image: '/ratcha/review-7.jpg',
        review: (
          <div>
            ผิวแพ้ง่ายมารวมกันทางนี้ ขอแนะนำ Ratcha Hya ตัวนี้หลักๆ
            เลยสกัดจากว่านหางจระเข้ ช่วยสมานผิว
            พร้อมเติมความชุ่มชื้นให้ผิวแล้วก็ยังสกัดจาก Allantoin
            ที่จะมาช่วยให้ผิวกระจ่างใสอีกด้วย เนื้อเซรั่มซึมไว
            ไม่เหนอะหนะอยู่บนผิว เหมาะกับอากาศบ้านเรา
          </div>
        ),
        name: 'คุณแพรว',
        product: 'RTCHY1',
      },
    ],
    guarantee: {
      title: 'คุณภาพจากรัชชา',
      description: (
        <div>
          ผลิตภัณฑ์คุณภาพจากรัชชา ได้มาตรฐานอย. พร้อมผ่านการรับรองคุณภาพ GMP
          ผลิตโดยโรงงานมาตรฐาน
        </div>
      ),
      image: '/product/ratcha-vc-1.1-png.png',
      cta: 'เกี่ยวกับรัชชา',
      url: '/about',
    },
  };
};
