export const RTCSET7 = () => {
  return {
    url: '/product/set-7',
    title: 'ชุดผิวใส นุ่มลื่น',
    brand: 'RATCHA',
    sku: 'RTCSET7',
    totalSale: 75421,
    price: 1580,
    offerPrice: 2020,
    images: ['/product/rtcset7.jpg'],
    product:
      'ชุดผิวใส นุ่มลื่น: เซรั่มไฮยา 1 + เซรั่มวิตซี 1 + กลอรี่สครับ 1 + กลอรี่บอดี้เซรั่ม 1',
    shortDescription: null,
    description: (
      <div class="mb-2 text-baseColor">
        <p>ชุดผิวใส นุ่มลื่น ประกอบไปด้วย</p>
        <ul class="list-disc mt-4 ml-10 text-baseColor">
          <li>เซรั่มไฮยารัชชา 1 ขวด</li>
          <li>เซรั่มวิตซี 1 ขวด</li>
          <li>กลอรี่สครับ 1 หลอด</li>
          <li>กลอรี่บอดี้เซรั่ม 1 หลอด</li>
        </ul>
      </div>
    ),
    options: null,
    guarantee: {
      title: 'คุณภาพจากรัชชา',
      description: (
        <div>
          ผลิตภัณฑ์คุณภาพจากรัชชา ได้มาตรฐานอย. พร้อมผ่านการรับรองคุณภาพ GMP
          ผลิตโดยโรงงานมาตรฐาน
        </div>
      ),
    },
    ingredients: null,
    ingredientSummary: null,
    fdaId: null,
    weight: null,
    faqs: [
      {
        question: 'วิธีใช้ให้เห็นผลลัพธ์',
        answer: (
          <div class="grid md:grid-cols-2">
            <div class="mb-4">
              <span class="font-bold">ตอนเช้า</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>ล้างหน้าให้สะอาด</li>
                <li>หยดเซรั่มไฮยา 2-3 หยด แล้วทาให้ทั่วใบหน้า</li>
                <li>รอ 1-2 นาที เพื่อรอให้เนื้อเซรั่มซึมลงผิว</li>
                <li>จากนั้นจึงทาเซรั่มวิตซี</li>
                <li>ลูบไล้คอลลาเจนบอดี้เซรั่มทั่วร่างกาย</li>
              </ul>
            </div>
            <div>
              <span class="font-bold">ตอนกลางคืน</span>
              <ul class="list-decimal mt-2 ml-6">
                <li>สครับผิวด้วยกลอรี่สครับบริเวณแขน ขา ข้อศอก และข้อพับ</li>
                <li>ล้างตัวและล้างหน้าให้สะอาด</li>
                <li>หยดเซรั่มไฮยา 2-3 หยด แล้วทาให้ทั่วใบหน้า</li>
                <li>รอ 1-2 นาที เพื่อรอให้เนื้อเซรั่มซึมลงผิว</li>
                <li>จากนั้นจึงทาเซรั่มวิตซี</li>
                <li>ลูบไล้คอลลาเจนบอดี้เซรั่มทั่วร่างกาย</li>
              </ul>
            </div>
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'ผิวนุ่ม ชุ่ม ฟู เด้ง',
      subtitle:
        'ด้วยอนุภาค Oilgo Hyaluronic Acid ซึมลึกถึงชั้นใต้ผิว คงความชุ่มชื้นได้ยาวนาน',
      url: 'https://www.youtube.com/watch?v=JWxO2nzSiZM',
    },
    disclaimer: null,
    reviews: null,
    productsIncluded: ['RTCHY1', 'RTCVC1', 'GLRBS1', 'GLRSC1'],
    relateProducts: [
      'RTCSET5',
      'RTCSET6',
      'RTCSET8',
      'RTCSET9',
      'RTCSET10',
      'RTCSET11',
      'RTCSET3',
      'RTCSET4',
    ],
    upsellProducts: ['RTCWF1', 'RTCSB1', 'PRECL1'],
  };
};
