export const safety = () => {
  return {
    title: 'มาตรฐานและความปลอดภัย',
    fda: {
      title: 'รายละเอียดการจดแจ้ง',
      images: [
        '/ratcha/certificate-hya.jpg',
        '/ratcha/certificate-vitc-uv.jpg',
        '/ratcha/certificate-vitc-whip.jpg',
        '/ratcha/certificate-vitc.jpg',
      ],
    },
  };
};
