import { LazyImage, Video } from '../../components/lazy-image';

export const content001 = () => {
  return {
    url: '/blog/review-glory-yipso',
    title: 'เคล็ดลับผิวใส ไร้สิวด้วย Glory Collagen',
    author: 'คุณยิปโซ คุณปาย และคุณแน๊ตตี้',
    content: (
      <div>
        <Video url="https://www.youtube.com/watch?v=ZPLebbzNN0Y" />
        <div class="mt-4">รีวิว glory collagen</div>
        <div class="mt-2">ทานวันละ 2 ครั้ง</div>
      </div>
    ),
    relateProducts: ['ARTSET1', 'ARTSR1', 'ARTMS1'],
  };
};
