import { Link } from 'preact-router/match';

export const faq = () => {
  return {
    product: [
      {
        question: <div>สินค้ามีอย. หรือไม่</div>,
        answer: (
          <div>
            <p>เลขที่ใบรับจดแจ้ง อย.</p>
            <p class="mt-2">
              เซรั่มไฮยารัชชา : 10-1-6200030338
              <br />
              เซรั่มวิตซี : 10-1-6100024751
              <br />
              วิตซี ยูวี โพรเทคชั่น: 10-1-6010063568
              <br />
              วิตซีวิปโฟม : 10-1-6100061313
            </p>
          </div>
        ),
      },
      {
        question: <div>ใช้แล้วจะมีผลข้างเคียงหรือไม่</div>,
        answer: (
          <p>
            ผลิตภัณฑ์คุณภาพจากรัชชาผ่านการรับรองคุณภาพ GMP ผลิตโดยโรงงานมาตรฐาน
            และได้รับมาตรฐาน อย.
          </p>
        ),
      },
      {
        question: <div>ใช้เซรั่มรัชชา กี่ขวดถึงเห็นผล</div>,
        answer: (
          <p>
            สังเกตเห็นการเปลี่ยนแปลงตั้งแต่ขวดแรก
            ทั้งนี้ทั้งนั้นขึ้นอยู่กับปัญหาและสภาพผิวของแต่ละคนด้วยค่ะ
          </p>
        ),
      },
      {
        question: <div>เซรั่ม 1 ขวดใช้ได้นานเท่าไร</div>,
        answer: (
          <p>
            เซรั่มไฮยารัชชา 1 ขวด ใช้ได้ประมาณ 2-4
            สัปดาห์ขึ้นอยู่กับปริมาณและความถี่ที่ใช้
          </p>
        ),
      },
      {
        question: <div>ต้องเก็บเซรั่มไว้อย่างไร</div>,
        answer: (
          <p>
            ปิดฝาให้สนิทหลังการใช้งาน เก็บให้พ้นแสงแดด
            และหลีกเลี่ยงที่อุณหภูมิสูง
          </p>
        ),
      },
    ],
    shipping: [
      {
        question: <div>ส่งต่างประเทศได้ไหม</div>,
        answer: (
          <p>
            ส่งได้ หากคุณต้องการส่งต่างประเทศ กรุณาติดต่อแอดมินทางไลน์
            เพื่อเช็คค่าขนส่ง
          </p>
        ),
      },
      {
        question: <div>ใช้เวลาจัดส่งกี่วัน</div>,
        answer: (
          <p>
            ระยะเวลาจัดเตรียมสินค้าประมาณ 1-2 วันทำการ และระยะเวลาขนส่งประมาณ 2
            วันทำการ ดังนั้นโดยปกติคุณจะได้รับสินค้าภายในเวลา 2-5 วันทำการ
            เว้นแต่ช่วงวันหยุดยาว ที่อาจได้รับสินค้าช้าเกินกว่ากำหนด
          </p>
        ),
      },
    ],
    refund: [
      {
        question: <div>ถ้าไม่พึงพอใจสามารถคืนสินค้าได้ไหม</div>,
        answer: (
          <p>
            หากคุณไม่พอใจ สามารถขอคืนเงินได้ภายใน 30 วัน
            ทั้งนี้เป็นไปตามเงื่อนไข{' '}
            <Link
              href="/return-policy"
              class="font-bold text-primary hover:text-primaryLight"
            >
              การคืนเงินและการคืนสินค้า
            </Link>
          </p>
        ),
      },
    ],
    order: [
      {
        question: <div>ชำระเงินทางไหนได้บ้าง ?</div>,
        answer: (
          <p>
            คุณสามารถชำระเงินได้ทางโอนเงินผ่านธนาคาร เก็บเงินปลายทาง
            และชำระผ่านบัตรเครดิต
          </p>
        ),
      },
      {
        question: <div>สามารถสั่งซื้อทางโทรศัพท์หรือทางไลน์ได้ไหม ?</div>,
        answer: (
          <p>
            หากคุณต้องการสั่งซื้อทางโทรศัพท์ กรุณาติดต่อ 088-980-8497 หรือ
            ทางไลน์
          </p>
        ),
      },
      {
        question: <div>หากต้องการสั่งซื้อจำนวนมาก มีส่วนลดไหม ?</div>,
        answer: (
          <p>
            หากคุณต้องการสั่งซื้อเป็นจำนวนมาก หรือซื้อในเรทตัวแทนจำหน่าย
            กรุณาติดต่อแอดมินทางไลน์ เพื่อเปิดบิลตัวแทนจำหน่าย
          </p>
        ),
      },
    ],
  };
};
