export const shop = () => [
  'RTCHY1',
  'RTCVC1',
  'RTCSB1',
  'RTCWF1',
  'RTCCR1',
  'RTCSET1',
  'RTCSET2',
  'RTCSET3',
  'RTCSET4',
  'RTCCR1VC1',
  'RTCCR3VC2',
  'RTCCR1VC1HY1',
  'RTCSET12',
  'RTCSET13',
  'RTCSET14',
  'RTCSET5',
];

export const checkoutUpsell = () => [
  'RTCCR1',
  'RTCSB1',
  'RTCWF1',
  'RTCHY1',
  'RTCVC1',
];

export const cartUpsell = () => ['RTCSB1', 'RTCWF1', 'RTCCR1'];

export const checkoutCrosssell = () => ['RTCCR1', 'RTCHY1', 'RTCVC1'];
