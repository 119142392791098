export const RTCVCP3 = () => ({
  url: '/product/vit-c-power-shot-3',
  title: 'VC วิตามินซี 200% เพียวเพาเวอร์ช็อต 3 กล่อง',
  brand: 'RATCHA',
  sku: 'RTCVCP3',
  totalSale: 32019,
  price: 999,
  offerPrice: 1500,
  images: ['/product/ratcha-vc-2.jpg'],
  product: 'VC วิตามินซี 200% 3 กล่อง',
  shortDescription: 'เซรั่มวิตซีเข้มข้น ดูแลทุกสภาพผิว ',
  description: (
    <div class="mb-2 text-baseColor">
      <p>เซรั่มอนุพันธ์วิตามินซีเข้มข้น ไม่มีสี ไม่มีกลิ่น บางเบา และซึมเร็ว</p>
      <p class="mt-2">
        เซรั่มวิตซีเนื้อบางเบา ไร้สีและไร้กลิ่น ผสานอนุพันธ์วิตามินซีเข้นข้น
        ด้วยนวัตกรรมใหม่ล่าสุดที่ช่วยให้วิตามินซีคงทนอยู่ได้นาน
        เนื้อเซรั่มที่เบาบางสบายผิว ซึมสู่ผิวได้ง่ายและบำรุงได้อย่างล้ำลึก
        ช่วยปรับสภาพผิวให้แข็งแรง เติมความชุ่มชื้นให้ผิว ช่วยให้ผิวแลดูกระจ่างใส
        พร้อมลดรอยดำ รอยแดงจากสิว เหมาะกับทุกสภาพผิว
      </p>
      <ul class="list-disc mt-4 ml-10 text-baseColor">
        <li>ดูแลปัญหาสิว สิวอักเสบ สิวอุดตัน</li>
        <li>
          ผิวหน้าหมองคล้ำแลดูกระจ่างใสขึ้น ลดรอยดำรอยแดงจากสิว ฝ้ากระดูจางลง
        </li>
        <li>เพิ่มความชุ่มชื้นให้ผิว รูขุมขนกว้างแลดูกระชับ</li>
        <li>
          ใช้ได้แม้ผิวบอบบาง แพ้ง่าย
          หรือคุณแม่กำลังตั้งครรภ์ที่กำลังมองหาสกินแคร์ที่ปลอดภัย
        </li>
      </ul>
    </div>
  ),
  options: [
    {
      sku: 'RTCVCP1',
      title: (
        <div>
          1 กล่อง <del>฿500</del>
        </div>
      ),
      price: 380,
    },
    {
      sku: 'RTCVCP2',
      title: (
        <div>
          2 กล่อง <del>฿1,000</del>
        </div>
      ),
      price: 700,
    },
    {
      sku: 'RTCVCP3',
      title: (
        <div>
          3 กล่อง <del>฿1,500</del>
        </div>
      ),
      price: 999,
    },
    {
      sku: 'RTCVCP5',
      title: (
        <div>
          5 กล่อง <del>฿2,500</del>
        </div>
      ),
      price: 1500,
    },
  ],
  guarantee: {
    title: 'คุณภาพจากรัชชา',
    description: (
      <div>
        ผลิตภัณฑ์คุณภาพจากรัชชา ได้มาตรฐานอย. พร้อมผ่านการรับรองคุณภาพ GMP
        ผลิตโดยโรงงานมาตรฐาน
      </div>
    ),
  },
  ingredients: [
    {
      image: '/ingredient/rcvc.png',
      title: 'Caprylyl 2-Glyceryl Ascorbate',
      description: (
        <div>
          เป็นวิตามินซีนวัตกรรมใหม่ โดยมีคุณสมบัติที่พิเศษกว่าวิตามินซีชนิดอื่นๆ
          มีความเสถียรสูง ไม่เปลี่ยนสีเมื่อสัมผัสอากาศหรือแสง
        </div>
      ),
    },
    {
      image: '/ingredient/vctannin.png',
      title: 'Hamamelis Virginiana Extract (Witch Hazel) ',
      description: (
        <div>
          มีคุณสมบัติช่วยกระชับผิว ปกป้องผิวจากมลภาวะต่าง ๆ กระตุ้นการสร้าง
          Elastin และ Collagen ใต้ผิวหนัง ทำให้ผิวกระชับ เต่งตึงไม่แห้งกร้าน
          เพิ่มความแข็งแรงให้กับผิว ช่วยกระชับรูขุมขน
          นอกจากนี้ยังมีคุณสมบัติในการลดการสร้างน้ำมันใต้ชั้นผิวรวมถึงมีฤทธิ์ฆ่าเชื้อแบคทีเรียอ่อนๆ
          จึงช่วยลดการเกิดสิวได้ในระดับนึง
        </div>
      ),
    },
    {
      image: '/ingredient/vcaloe.png',
      title: 'Aloe Barbadensis Leaf Juice',
      description: (
        <div>
          สารสกัดทรงคุณค่าจากว่านหางจระเข้ มีประสิทธิภาพในการบำรุงผิวล้ำลึก
          ยับยั้งการอักเสบของผิวหนัง ลดอาการระคายเคืองจากมลภาวะที่มาทำร้ายผิว
          ปกป้องผิว บรรเทาอาการแสบร้อนของผิวเมื่อต้องออกแดดเป็นเวลานาน
          พร้อมเติมเต็มความชุ่มชื้นให้ผิว
        </div>
      ),
    },
    {
      image: '/ingredient/vanag.png',
      title: 'N-Acetyl Glucosamine',
      description: (
        <div>
          สามารถรักษาผิวหนังที่ถูกทำร้ายด้วยแสงแดดและลดเลือนรอยดำ
          สามารถลดปริมาณเม็ดสีเมลานินในเซลล์ผิวหนัง ลดเลือนริ้วรอย
        </div>
      ),
    },
    {
      image: '/ingredient/vcembica.png',
      title: 'Emblica Extract (สารสกัดมะขามป้อม)',
      description: (
        <div>
          อุดมไปด้วย วิตามินซี จากธรรมชาติ ต้านอนุมูลอิสระ และ
          ชะลอการเกิดริ้วรอยก่อนวัย จึงช่วยปกป้องผิวจากการเสื่อมสลาย
          ช่วยชะลอการเกิดริ้วรอยก่อนวัย และ บำรุงผิวพรรณให้ขาว สว่าง
          กระจ่างใสขึ้น
        </div>
      ),
    },
    {
      image: '/ingredient/vcallan.png',
      title: 'Allantoin',
      description: (
        <div>
          ลดการอักเสบ เพิ่มความชุ่มชื่นให้ผิว
          ช่วยผลัดเซลล์ผิวที่เสื่อมสภาพแล้วหลุดลอกออกไป ทำให้ผิวนุ่ม
          ละมุนยิ่งขึ้น
        </div>
      ),
    },
  ],
  ingredientSummary: (
    <div>
      Water, Nicotinamide, Butylene Glycol, Ethoxydiglycol, Glycerin, Hamamelis
      Virginiana Leaf Extract, Propylene Glycol, Phenoxyethanol, Allantoin, Aloe
      Barbadensis Leaf Juice, Hydroxyethylcellulose, Xanthan Gum, Acetyl
      Glucosamine, Disodium Edta, Dipotassium Glycyrrhizinate, Triethylene,
      Glycol, Biosaccharide Gum-1, Sodium Benzoate, Caprylyl 2-Glyceryl
      Ascorbate, Phyllanthus Emblica Fruit Extract, Potassium Sorbate
    </div>
  ),
  fdaId: '10-1-6100024751',
  weight: '10 มล.',
  faqs: [
    {
      question: 'ผิวแพ้ง่ายใช้ได้ไหม',
      answer: (
        <div>
          ผิวแพ้ง่ายสามารถใช้ Vit C Bio Face Serum ได้เลยค่ะ
          เพราะผลิตภัณฑ์ของเรา ไม่แต่งสี ไม่แต่งกลิ่น ลดความระคายเคือง
          และยังมีส่วนผสมของ Allantoin ช่วยในการปกป้องผิวหนังป้องกันอาการแพ้
          และช่วยให้ผิวมีสุขภาพดี
        </div>
      ),
    },
    {
      question: 'เซรั่มวิตซีใช้อย่างไร',
      answer: (
        <div>
          หยดเซรั่มบนฝ่ามือ 2-3 หยด
          และวอร์มเนื้อเซรั่มเพื่อการซึมซาบสู่ผิวที่ดีขึ้น
          ทาให้ทั่วบริเวณใบหน้าและลำคอใช้เป็นประจำทั้งเช้าและเย็น
          ผิวหน้าชุ่มชื้น แข็งแรง แลดูกระจ่างใส มีออร่า ทาวันละ 1-2
          ครั้งเพื่อผลลัพธ์ที่ดีขึ้น
          <div class="mt-2">
            เพื่อผลลัพธ์ที่ดีขึ้น ควรใช้ควบคู่กับผลิตภัณฑ์วิตซีวิปโฟม
            ล้างทำความสะอาดผิวหน้า เพื่อขจัดเซลล์ผิวเก่า
            และปกป้องผิวจากแสงแดดด้วยวิตซี ยูวี โพรเทคชั่น
          </div>
        </div>
      ),
    },
    {
      question: 'ปัญหาฝ้า กระ ทำอย่างไร ',
      answer: (
        <div>
          ใน Vit C นั้นมีส่วนผสม ของ Niacinamide (Vitamin B3)
          ช่วยให้ผิวกระจ่างใส ลดรอยหมองคล้ำจากสิว และยังดูแลรักษา ฝ้า กระ
          ให้แลดูจางลงได้ดี แต่อาจจะไม่จางไวเท่าการทำเลเซอร์ แต่จะค่อยๆ
          ปรับผิวให้จางลง กระจ่างใสขึ้น ที่สำคัญไม่ทำให้ผิวบาง
          หรือทำให้ผิวอ่อนแอลงแน่นอน
        </div>
      ),
    },
    {
      question: 'รูขุมขนกว้างรักษายังไงดี',
      answer: (
        <div>
          ใน Vit C มีส่วนผสมของ Witch Hazel Extract มีคุณสมบัติเป็น Antioxidant
          ช่วยปกป้องผิวจากมลภาวะต่างๆ และ Astringent ช่วยกระชับรูขุมขน
          ทั้งยังช่วยสมานผิว ปรับให้รูขุนขนค่อยๆ ตื้นขึ้น
          เติมเต็มผิวให้อิ่มฟูทำให้ผิวเรียบเนียน
        </div>
      ),
    },
    {
      question: 'ลงเซรั่มก่อนแต่งหน้ามีผลอย่างไร',
      answer: (
        <div>
          ลงเซรั่มก่อนแต่งหน้าช่วยให้เครื่องสำอางค์ติดทนขึ้น เพราะใน Vit C มี
          Amitose GOA คุณสมบัติให้ความชุ่มชื้นแก่ผิว
          จึงทำให้ผิวชุ่มชื้นแบบพอดีไม่มากจนเกินไป ช่วยให้ผิวหน้าไม่มัน
          ทำให้เครื่องสำอางค์ติดทนนานมากยิ่งขึ้น เพราะถ้าผิวเราแห้งมากๆ
          หรือมันมาก ไม่ว่าจะลงรองพื้นซ้ำๆ มากแค่ไหน ก็จะไม่เกาะผิวหน้า
          แถมยังเป็นคราบไม่เรียบเนียนอีกด้วย
        </div>
      ),
    },
    {
      question: 'ช่วยเรื่องสิวอุดตันไหม',
      answer: (
        <div>
          ช่วยได้ค่ะ เพียงล้างหน้าด้วย Vit C Whip Foam ก่อนเป็นลำดับแรก
          เพื่อเป็นการเคลียร์ผิว ขจัดสิ่งสกปรกที่ติดค้างอยู่บนผิวหน้า เช่น
          เครื่องสำอาง น้ำมันเหงื่อไคลที่ปิดบังรูขุมขน
          ด้วยการชำระล้างให้สะอาดหมดจด แล้วบำรุงผิวด้วย Vit C Bio Face Serum
          ซึ่งมีสารสกัด Witch Hazel มีคุณสมบัติเป็น Antioxidant
          ช่วยปกป้องผิวจากมลภาวะต่างๆ และ Astringent ช่วยกระชับรูขุมขน
          ช่วยสมาผิว ปรับให้รูขุมขนค่อยๆ ตื้นขึ้น ทำให้ผิวเรียบเนียน
          บอกลาสิวอุดตันแน่นอนค่ะ
        </div>
      ),
    },
    {
      question: 'เป็นสิวอยู่ใช้ได้ไหม ',
      answer: (
        <div>
          เป็นสิวก็สามารถใช้ได้ค่ะ เพราะในเซรั่มวิตซีมีส่วนผสมของ Amitose GOA
          มีคุณสมบัติในการยับยั้งการเจริญเติบโตของเชื้อแบคทีเรียต่างๆ
          ที่เป็นสาเหตุของการเกิดสิว จะช่วยให้สิวค่อยๆ ยุบและแห้งไป
          และป้องกันไม่ให้สิวใหม่เกิดขึ้นด้วยค่ะ
        </div>
      ),
    },
    {
      question: 'ผลิตภัณฑ์รัชชาปลอดภัยไหม มีอย.หรือไม่',
      answer: (
        <div>
          <div>
            สินค้าทุกชิ้นของรัชชาปลอดภัย ได้มาตรฐานอย.พร้อมผ่านการรับรองคุณภาพ
            GMP ผลิตโดยโรงงานมาตรฐาน
          </div>
          <div class="mt-2">
            เลขที่ใบรับจดแจ้ง: 10-1-6200030338 (เซรั่มไฮยารัชชา)
            <br />
            เลขที่ใบรับจดแจ้ง: 10-1-6100024751 (เซรั่มวิตซี)
            <br />
            เลขที่ใบรับจดแจ้ง: 10-1-6010063568 (วิตซี ยูวี โพรเทคชั่น)
            <br />
            เลขที่ใบรับจดแจ้ง: 10-1-6100061313 (วิตซีวิปโฟม)
          </div>
        </div>
      ),
    },
  ],
  videoContent: {
    title: 'ลดสิว ผิวกระจ่างใส',
    subtitle: 'หมดปัญหาสิว เปลี่ยนผิวให้ปัง ด้วยวิตซีน้องฉัตร',
    url: 'https://www.youtube.com/watch?v=DQP-gvN6DSE&t=200s',
  },
  disclaimer: (
    <div>
      1.
      การใช้เครื่องสำอางที่มีสารป้องกันแสงแดดเป็นเพียงวิธีหนึ่งที่จะช่วยลดความเสี่ยงจากอันตรายจากแสงแดด
      <br />
      2. อ่านวิธีใช้ให้ละเอียดและปฏิบัติตามอย่างเคร่งครัด
      <br />
      3. หากใช้แล้วมีความผิดปกติใดๆ เกิดขึ้นต้องหยุดใช้
      และปรึกษาแพทย์หรือเภสัชกร
    </div>
  ),
  reviews: [
    {
      image: '/ratcha/review-vc-4.1.jpg',
      review: (
        <div>
          ใช้จนหมดขวดล่ะ กำลังจะเริ่มขวด 2 สิวอักเสบแห้งเร็ว ลดรอยดำรอยแดงได้ดี
          โดยเฉพาะรอยใหม่ๆ นี่หายเร็วเว่อร์ เนื้อเหลวใส ไม่มีสี ไม่มีกลิ่น ซึมไว
          รู้สึกว่าใช้แล้วแต่งหน้าติดทนขึ้นด้วยค่ะ ดีงามม
        </div>
      ),
      name: 'คุณวี',
      product: 'RTCVC1',
    },
    {
      image: '/ratcha/review-vc-3.1.jpg',
      review: (
        <div>
          เซรั่มวิตซีตัวดัง ลองแล้วชอบมากก บางเบามากๆ ไม่เหนียวเลย
          เหมือนทาน้ำเปล่าที่ผสมสารบำรุง ใช้แล้วไม่แสบหน้าเหมือนวิตซีบางตัว
          หน้าใสขึ้น รอยยังไม่เห็นผลมาก แต่ซื้อต่อแน่นอน!
        </div>
      ),
      name: 'คุณเอิน',
      product: 'RTCVC1',
    },
    {
      image: '/ratcha/review-vc-2.1.jpg',
      review: (
        <div>
          ใครมีรอยสิว รอยแดง รอยดำต่างๆ ช่วงนี้ แนะนำเลยๆ ลดรอยดีมาก
          หน้าใสขึ้นด้วย ละเนื้อสัมผัสของเซรั่ม ซึมง่ายมาก
          แต่ยังให้ความรู้สึกผิวชุ่มชื้น ชอบๆ
        </div>
      ),
      name: 'คุณป่าน',
      product: 'RTCVC1',
    },
    {
      image: '/ratcha/review-vc-1.1.jpg',
      review: (
        <div>
          เซรั่มกู้หน้าของจริง เป็นสิว เป็นรอยแดงรอยดำเอาอยู่หมด
          ใช้มาพักนึงละสิวแห้งไวมาก แถมรอยสิวก็ค่อยๆ จางลง ทาติดต่อกันทุกวัน
          หน้าใสไม่โทรมเลย เนื้อซึมง่ายไม่เหนอะหน้า
          แต่งหน้าทับก็ไม่เป็นเป็นคราบด้วย ดีจริงไปตำ
        </div>
      ),
      name: 'คุณมา',
      product: 'RTCVC1',
    },
    {
      image: '/ratcha/review-vc-8.1.jpg',
      review: (
        <div>
          ช่วยให้ผิว Bright ขึ้น รอยดำรอยแดงลดลงเร็วกว่าปกติ หัวเป็น Dropper
          กะปริมาณเวลาใช้ง่าย ไม่เลอะเทอะ ไม่เปลือง เนื้อเซรั่มเหลวๆ ไม่หนักหน้า
          ลงก่อนแต่งหน้าได้ หนึบหน่อยๆ ใช้เป็นไพร์เมอร์ได้เลย เมคอัพติดหน้า
        </div>
      ),
      name: 'คุณผิง',
      product: 'RTCVC1',
    },
    {
      image: '/ratcha/review-vc-6.1.jpg',
      review: (
        <div>
          ตัวนี้ดีน้า ใช้มาหมดไปขวดแล้วกำลังใช้ขวดที่สอง เราเป็นรอยดำแดงจากสิว
          พอใช้มาสักพักรอยจางลงจริงๆ รอยใหม่จะจางไวกว่านะ
          ส่วนรอยเก่าคงต้องใช้เวลาหน่อย เนื้อไม่หนาไม่ทำให้หน้ามัน
          ทาก่อนแต่งหน้าก็แต่งง่ายขึ้นด้วย ราคาไม่แพง แนะนำๆ
        </div>
      ),
      name: 'คุณหยก',
      product: 'RTCVC1',
    },
    {
      image: '/ratcha/review-vc-7.1.jpg',
      review: (
        <div>
          รีวิวไปแล้วหลายรอบมาก เพราะใช้ตลอดจริงๆ
          ตอนนี้เขามีแพ็คเกจกล่องใหม่ด้วยนะ นี่ก็่ขวดที่ 5-6 แล้ว ลดรอยสิว
          ผิวใสได้ดีมาก รูขุมขนกระชับ ผิวฟูเด้งมาก
        </div>
      ),
      name: 'คุณเฟิน',
      product: 'RTCVC1',
    },
    {
      image: '/ratcha/review-vc-5.1.jpg',
      review: (
        <div>
          นอนน้อยหน้าพังเป็นสิว สิวอุดตัน รูขุมขนไม่กระชับ ฝ้ากระ อยากหน้าใสขึ้น
          ขวดเดียวนี่เอาอยู่จริงๆ ใช้จนสมัครเป็นตัวแทนขาย
          พอขายก็ดันใช้จนหมดไม่ได้ขาย55555
        </div>
      ),
      name: 'คุณเฟิน',
      product: 'RTCVC1',
    },
    {
      image: '/ratcha/review-vc-15.jpg',
      name: 'คุณเฟรม',
    },
    {
      image: '/ratcha/review-vc-16.jpg',
      name: 'คุณจ๊ะจ๋า',
    },
    {
      image: '/ratcha/review-vc-11.jpg',
      name: 'คุณจอย',
    },
    {
      image: '/ratcha/review-vc-12.jpg',
      name: 'คุณโบนัส',
    },
    {
      image: '/ratcha/review-vc-13.jpg',
      name: 'คุณปุ๋ย',
    },
    {
      image: '/ratcha/review-vc-14.jpg',
      name: 'คุณยุ',
    },
    {
      image: '/ratcha/review-vc-10.jpg',
      name: 'คุณเนม',
    },
    {
      image: '/ratcha/review-vc-9.jpg',
      name: 'ใช้ดีจนเป็นตัวแทน',
    },
  ],
  relateProducts: [
    'RTCSET1',
    'RTCSET12',
    'RTCSET13',
    'RTCSET14',
    'RTCSET2',
    'RTCSET3',
    'RTCSET4',
    'RTCSET5',
    'RTCSET6',
    'RTCSET7',
    'RTCSET8',
    'RTCSET9',
    'RTCSET10',
    'RTCSET11',
  ],
  upsellProducts: ['RTCSB1', 'RTCWF1', 'GLRCL1'],
});
