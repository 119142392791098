export const RTCCR1 = () => {
  return {
    url: '/product/cream-vitc',
    title: 'VC Whitening Cream',
    brand: 'RATCHA',
    sku: 'RTCCR1',
    totalSale: 9875,
    price: 99,
    offerPrice: 129,
    images: ['/product/RTCCR1-2.jpg'],
    product: 'VC Whitening Cream 1 ซอง',
    shortDescription: 'ผิวกระจ่างใส รอยสิวจางลง ',
    description: (
      <div class="mb-2 text-baseColor">
        <p>
          VC Whitening Cream ครีมบำรุงผิวหน้า อ่อนโยนต่อผิว เกลี่ยง่าย
          ด้วยประสิทธิภาพของ Amitose GOA วิตามินซีที่มีความเสถียรสูง
          มีคุณสมบัติในการยับยั้งการเจริญเติบโตของเชื้อแบคทีเรียต่างๆ
          ที่เป็นสาเหตุของการเกิดสิว ยับยั้งกระบวนการสร้างเม็ดสีเมลานิน
          ทำให้ผิวมีความยืดหยุ่นและตึงกระชับ
        </p>
        <ul class="list-disc mt-4 ml-10 text-baseColor">
          <li>ผิวหน้าขาว สว่าง กระจ่างใส อย่างเป็นธรรมชาติ</li>
          <li>ผสานคุณค่าวิตามินซี และอัลฟ่า อาร์บูติน ช่วยลดรอยสิว</li>
          <li>ใช้ได้แม้ผิวบอบบาง แพ้ง่าย</li>
          <li>ใช้ทาบำรุงผิวหน้าเป็นประจำ เช้า-เย็น</li>
        </ul>
      </div>
    ),
    options: [
      {
        sku: 'RTCCR1',
        title: (
          <div>
            1 ซอง <del>฿129</del>
          </div>
        ),
        price: 99,
      },
      {
        sku: 'RTCCR3',
        title: (
          <div>
            3 ซอง <del>฿387</del>
          </div>
        ),
        price: 297,
      },
      {
        sku: 'RTCCR5',
        title: (
          <div>
            5 ซอง <del>฿645</del>
          </div>
        ),
        price: 495,
      },
      {
        sku: 'RTCCR10',
        title: (
          <div>
            10 ซอง <del>฿1,290</del>
          </div>
        ),
        price: 850,
      },
    ],
    guarantee: {
      title: 'คุณภาพจากรัชชา',
      description: (
        <div>
          ผลิตภัณฑ์คุณภาพจากรัชชา ได้มาตรฐานอย. พร้อมผ่านการรับรองคุณภาพ GMP
          ผลิตโดยโรงงานมาตรฐาน
        </div>
      ),
    },
    ingredients: [
      {
        image: '/ingredient/rcvc.png',
        title: 'Caprylyl 2-Glyceryl Ascorbate',
        description: (
          <div>
            เป็นวิตามินซีนวัตกรรมใหม่
            โดยมีคุณสมบัติที่พิเศษกว่าวิตามินซีชนิดอื่นๆ มีความเสถียรสูง
            ไม่เปลี่ยนสีเมื่อสัมผัสอากาศหรือแสง
          </div>
        ),
      },
      {
        image: '/ingredient/vctannin.png',
        title: 'Hamamelis Virginiana Extract (Witch Hazel) ',
        description: (
          <div>
            มีคุณสมบัติช่วยกระชับผิว ปกป้องผิวจากมลภาวะต่าง ๆ กระตุ้นการสร้าง
            Elastin และ Collagen ใต้ผิวหนัง ทำให้ผิวกระชับ เต่งตึงไม่แห้งกร้าน
            เพิ่มความแข็งแรงให้กับผิว ช่วยกระชับรูขุมขน
            นอกจากนี้ยังมีคุณสมบัติในการลดการสร้างน้ำมันใต้ชั้นผิวรวมถึงมีฤทธิ์ฆ่าเชื้อแบคทีเรียอ่อนๆ
            จึงช่วยลดการเกิดสิวได้ในระดับนึง
          </div>
        ),
      },
      {
        image: '/ingredient/vcaloe.png',
        title: 'Aloe Barbadensis Leaf Juice',
        description: (
          <div>
            สารสกัดทรงคุณค่าจากว่านหางจระเข้ มีประสิทธิภาพในการบำรุงผิวล้ำลึก
            ยับยั้งการอักเสบของผิวหนัง ลดอาการระคายเคืองจากมลภาวะที่มาทำร้ายผิว
            ปกป้องผิว บรรเทาอาการแสบร้อนของผิวเมื่อต้องออกแดดเป็นเวลานาน
            พร้อมเติมเต็มความชุ่มชื้นให้ผิว
          </div>
        ),
      },
      {
        image: '/ingredient/vanag.png',
        title: 'N-Acetyl Glucosamine',
        description: (
          <div>
            สามารถรักษาผิวหนังที่ถูกทำร้ายด้วยแสงแดดและลดเลือนรอยดำ
            สามารถลดปริมาณเม็ดสีเมลานินในเซลล์ผิวหนัง ลดเลือนริ้วรอย
          </div>
        ),
      },
      {
        image: '/ingredient/vcembica.png',
        title: 'Emblica Extract (สารสกัดมะขามป้อม)',
        description: (
          <div>
            อุดมไปด้วย วิตามินซี จากธรรมชาติ ต้านอนุมูลอิสระ และ
            ชะลอการเกิดริ้วรอยก่อนวัย จึงช่วยปกป้องผิวจากการเสื่อมสลาย
            ช่วยชะลอการเกิดริ้วรอยก่อนวัย และ บำรุงผิวพรรณให้ขาว สว่าง
            กระจ่างใสขึ้น
          </div>
        ),
      },
      {
        image: '/ingredient/vcallan.png',
        title: 'Allantoin',
        description: (
          <div>
            ลดการอักเสบ เพิ่มความชุ่มชื่นให้ผิว
            ช่วยผลัดเซลล์ผิวที่เสื่อมสภาพแล้วหลุดลอกออกไป ทำให้ผิวนุ่ม
            ละมุนยิ่งขึ้น
          </div>
        ),
      },
    ],
    ingredientSummary: (
      <div>
        Water, Nicotinamide, Butylene Glycol, Ethoxydiglycol, Glycerin,
        Hamamelis Virginiana Leaf Extract, Propylene Glycol, Phenoxyethanol,
        Allantoin, Aloe Barbadensis Leaf Juice, Hydroxyethylcellulose, Xanthan
        Gum, Acetyl Glucosamine, Disodium Edta, Dipotassium Glycyrrhizinate,
        Triethylene, Glycol, Biosaccharide Gum-1, Sodium Benzoate, Caprylyl
        2-Glyceryl Ascorbate, Phyllanthus Emblica Fruit Extract, Potassium
        Sorbate
      </div>
    ),
    fdaId: '11-1-63000-5-3959',
    weight: '7 g',
    faqs: [
      {
        question: 'ใครใช้ได้บ้าง',
        answer: (
          <div>
            สามารถใช้ได้ทุกเพศ ทุกวัย ตั้งแต่อายุ 6 ขวบขึ้นไปเลยค่ะ
            เพราะไม่มีส่วนผสมที่เป็นอันตรายต่อผิว
          </div>
        ),
      },
      {
        question: 'ผิวแพ้ง่ายใช้ได้ไหม',
        answer: (
          <div>
            สามารถใช้ได้ค่ะ เนื่องจากสารสกัดทุกตัวที่ใช้มีความอ่อนโยนต่อผิว
            และไม่มีส่วนผสมที่เป็นอันตรายต่อผิว
          </div>
        ),
      },
      {
        question: 'ช่วยเรื่องอะไรบ้าง',
        answer: (
          <div>
            ช่วยให้ผิวหน้าสว่างกระจ่างใสขึ้นทันที 1 ระดับหลังใช้
            ช่วยควบคุมความมันบนใบหน้า และช่วยยับยั้งเชื้อสิว
          </div>
        ),
      },
      {
        question: 'เป็นสิวสามารถใช้ได้ไหม',
        answer: (
          <div>
            สามารถใช้ได้ค่ะ เพราะมีส่วนผสมของ Amitose GOA Vit C
            ที่ช่วยฆ่าเชื้อสิว และช่วยลดรอยดำรอยแดงจากสิว
          </div>
        ),
      },
      {
        question: 'วิธีใช้ VC Whitening Cream',
        answer: (
          <div>
            บีบครีมแล้วแต้มไปที่ 5 จุดบนใบหน้า หน้าผาก แก้ม 2 ข้าง จมูก และคาง
            เกลี่ยให้ทั่ว สามารถทาทั้งได้ทั้งเช้าและเย็น
          </div>
        ),
      },

      {
        question: 'ผลิตภัณฑ์รัชชาปลอดภัยไหม มีอย.หรือไม่',
        answer: (
          <div>
            <div>
              สินค้าทุกชิ้นของรัชชาปลอดภัย ได้มาตรฐานอย.พร้อมผ่านการรับรองคุณภาพ
              GMP ผลิตโดยโรงงานมาตรฐาน
            </div>
            <div class="mt-2">
              เลขที่ใบรับจดแจ้ง: 10-1-6200030338 (เซรั่มไฮยารัชชา)
              <br />
              เลขที่ใบรับจดแจ้ง: 10-1-6100024751 (เซรั่มวิตซี)
              <br />
              เลขที่ใบรับจดแจ้ง: 10-1-6010063568 (วิตซี ยูวี โพรเทคชั่น)
              <br />
              เลขที่ใบรับจดแจ้ง: 10-1-6100061313 (วิตซีวิปโฟม)
              <br />
              เลขที่ใบรับจดแจ้ง: 11-1-63000-5-3959 (VC Whitening Cream)
            </div>
          </div>
        ),
      },
    ],
    videoContent: {
      title: 'ลดสิว ผิวกระจ่างใส',
      subtitle: 'หมดปัญหาสิว เปลี่ยนผิวให้ปัง ด้วย VC Whitening Cream',
      url: 'https://www.youtube.com/watch?v=5XiUWFjCshQ',
    },
    disclaimer: (
      <div>
        1. ใช้สำหรับภายนอกเท่านั้น
        <br />
        2. หากเกิดการระคายเคือง ควรหยุดใช้และปรึกษาแพทย์หรือเภสัชกร
      </div>
    ),
    reviews: [
      {
        image: '/ratcha/review-vc-4.1.jpg',
        review: (
          <div>
            ใช้จนหมดขวดล่ะ กำลังจะเริ่มขวด 2 สิวอักเสบแห้งเร็ว
            ลดรอยดำรอยแดงได้ดี โดยเฉพาะรอยใหม่ๆ นี่หายเร็วเว่อร์ เนื้อเหลวใส
            ไม่มีสี ไม่มีกลิ่น ซึมไว รู้สึกว่าใช้แล้วแต่งหน้าติดทนขึ้นด้วยค่ะ
            ดีงามม
          </div>
        ),
        name: 'คุณวี',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-3.1.jpg',
        review: (
          <div>
            เซรั่มวิตซีตัวดัง ลองแล้วชอบมากก บางเบามากๆ ไม่เหนียวเลย
            เหมือนทาน้ำเปล่าที่ผสมสารบำรุง ใช้แล้วไม่แสบหน้าเหมือนวิตซีบางตัว
            หน้าใสขึ้น รอยยังไม่เห็นผลมาก แต่ซื้อต่อแน่นอน!
          </div>
        ),
        name: 'คุณเอิน',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-2.1.jpg',
        review: (
          <div>
            ใครมีรอยสิว รอยแดง รอยดำต่างๆ ช่วงนี้ แนะนำเลยๆ ลดรอยดีมาก
            หน้าใสขึ้นด้วย ละเนื้อสัมผัสของเซรั่ม ซึมง่ายมาก
            แต่ยังให้ความรู้สึกผิวชุ่มชื้น ชอบๆ
          </div>
        ),
        name: 'คุณป่าน',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-1.1.jpg',
        review: (
          <div>
            เซรั่มกู้หน้าของจริง เป็นสิว เป็นรอยแดงรอยดำเอาอยู่หมด
            ใช้มาพักนึงละสิวแห้งไวมาก แถมรอยสิวก็ค่อยๆ จางลง ทาติดต่อกันทุกวัน
            หน้าใสไม่โทรมเลย เนื้อซึมง่ายไม่เหนอะหน้า
            แต่งหน้าทับก็ไม่เป็นเป็นคราบด้วย ดีจริงไปตำ
          </div>
        ),
        name: 'คุณมา',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-8.1.jpg',
        review: (
          <div>
            ช่วยให้ผิว Bright ขึ้น รอยดำรอยแดงลดลงเร็วกว่าปกติ หัวเป็น Dropper
            กะปริมาณเวลาใช้ง่าย ไม่เลอะเทอะ ไม่เปลือง เนื้อเซรั่มเหลวๆ
            ไม่หนักหน้า ลงก่อนแต่งหน้าได้ หนึบหน่อยๆ ใช้เป็นไพร์เมอร์ได้เลย
            เมคอัพติดหน้า
          </div>
        ),
        name: 'คุณผิง',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-6.1.jpg',
        review: (
          <div>
            ตัวนี้ดีน้า ใช้มาหมดไปขวดแล้วกำลังใช้ขวดที่สอง เราเป็นรอยดำแดงจากสิว
            พอใช้มาสักพักรอยจางลงจริงๆ รอยใหม่จะจางไวกว่านะ
            ส่วนรอยเก่าคงต้องใช้เวลาหน่อย เนื้อไม่หนาไม่ทำให้หน้ามัน
            ทาก่อนแต่งหน้าก็แต่งง่ายขึ้นด้วย ราคาไม่แพง แนะนำๆ
          </div>
        ),
        name: 'คุณหยก',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-7.1.jpg',
        review: (
          <div>
            รีวิวไปแล้วหลายรอบมาก เพราะใช้ตลอดจริงๆ
            ตอนนี้เขามีแพ็คเกจกล่องใหม่ด้วยนะ นี่ก็่ขวดที่ 5-6 แล้ว ลดรอยสิว
            ผิวใสได้ดีมาก รูขุมขนกระชับ ผิวฟูเด้งมาก
          </div>
        ),
        name: 'คุณเฟิน',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-5.1.jpg',
        review: (
          <div>
            นอนน้อยหน้าพังเป็นสิว สิวอุดตัน รูขุมขนไม่กระชับ ฝ้ากระ
            อยากหน้าใสขึ้น ขวดเดียวนี่เอาอยู่จริงๆ ใช้จนสมัครเป็นตัวแทนขาย
            พอขายก็ดันใช้จนหมดไม่ได้ขาย55555
          </div>
        ),
        name: 'คุณเฟิน',
        product: 'RTCVC1',
      },
      {
        image: '/ratcha/review-vc-15.jpg',
        name: 'คุณเฟรม',
      },
      {
        image: '/ratcha/review-vc-16.jpg',
        name: 'คุณจ๊ะจ๋า',
      },
      {
        image: '/ratcha/review-vc-11.jpg',
        name: 'คุณจอย',
      },
      {
        image: '/ratcha/review-vc-12.jpg',
        name: 'คุณโบนัส',
      },
      {
        image: '/ratcha/review-vc-13.jpg',
        name: 'คุณปุ๋ย',
      },
      {
        image: '/ratcha/review-vc-14.jpg',
        name: 'คุณยุ',
      },
      {
        image: '/ratcha/review-vc-10.jpg',
        name: 'คุณเนม',
      },
      {
        image: '/ratcha/review-vc-9.jpg',
        name: 'ใช้ดีจนเป็นตัวแทน',
      },
    ],
    relateProducts: [
      'RTCSET1',
      'RTCSET12',
      'RTCSET13',
      'RTCSET14',
      'RTCSET2',
      'RTCSET3',
      'RTCSET4',
      'RTCSET5',
      'RTCSET6',
      'RTCSET7',
      'RTCSET8',
      'RTCSET9',
      'RTCSET10',
      'RTCSET11',
    ],
    upsellProducts: ['RTCSB1', 'RTCWF1', 'GLRCL1'],
  };
};
